import "./TabGroup.scss";

export interface TabGroupProps {
    children: JSX.Element[] | JSX.Element;
}

const TabGroup = ({ children }: TabGroupProps) => {
    return (
        <div className="tab-group">
            {children}
        </div>
    );
};

export default TabGroup;