
import embergenLogoSrc from '../assets/media/icons/embergen.svg';
import esSuiteLogoSrc from "../assets/media/icons/elemental_suite.svg";
import vectoraygenLogoSrc from "../assets/media/icons/vectoraygen.svg";
import iconGeogen from "../assets/media/icons/geogen.svg";
import iconLiquigen from "../assets/media/icons/liquigen.svg";
import iconGtv from "../assets/media/icons/gtv.svg";

export interface ProductData {
    logoSrc: string;
    header: JSX.Element;
    periodicity: "year" | "month";
}

export function getProductData(productCode: string): ProductData {
    const periodicity = productCode.includes("annually") ? "year" : "month";

    if (productCode.includes("embergen")) {
        return {
            logoSrc: embergenLogoSrc,
            header: <h3>Ember<span style={{ color: "#FBA200" }}>Gen</span></h3>,
            periodicity: periodicity,
        };
    } else if (productCode.includes("vectoraygen")) {
        return {
            logoSrc: vectoraygenLogoSrc,
            header: <h3>Vectoray<span style={{ color: "#CF3CE6" }}>Gen</span></h3>,
            periodicity: periodicity,
        };
    } else if (productCode.includes("geogen")) {
        return {
            logoSrc: iconGeogen,
            header: <h3>Geo<span style={{ color: "#61D23E" }}>Gen</span></h3>,
            periodicity: periodicity,
        };
    } else if (productCode.includes("liquigen")) {
        return {
            logoSrc: iconLiquigen,
            header: <h3>Liqui<span style={{ color: "#2CD4FA" }}>Gen</span></h3>,
            periodicity: periodicity,
        };
    } else if (productCode.includes("jangafx-suite")) {
        return {
            logoSrc: esSuiteLogoSrc,
            header: <h3>Elemental <span style={{ color: "#3FAEFF" }}>Suite</span></h3>,
            periodicity: periodicity,
        };
    } else if (productCode.includes("gtv")) {
        return {
            logoSrc: iconGtv,
            header: <h3>Game<span style={{ color: "#FBA200" }}>TextureViewer</span></h3>,
            periodicity: periodicity,
        };
    }

    throw new Error(`Unknown product code '${productCode}'`);
}