import "./Button.scss";

type ButtonType = "primary" | "secondary" | "tertiary" | "danger" | "success";

interface ButtonProps {
    children?: any;
    color: ButtonType;
}

const Button = ({ color = "primary", children, ...props }: ButtonProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
    return (
        <button {...props} className={`btn ${color}`}>
            {children}
        </button>
    )
};

export default Button;