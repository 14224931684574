/* eslint-disable @typescript-eslint/no-misused-promises */
import "./EducationalPage.scss";
import EducationSubmissionForm from "../../forms/EducationSubmissionForm";

export default function () {
    return (
        <div id="educationalPage">
            <h3>Educational Licensing Inquiry Form</h3>
            <p>As a student or faculty member you can apply for an educational discount on our JangaFX Suite, with licenses which are valid for one year (renewable). This license should be used for personal learning purposes only. For school/teaching usage, please select "university" from the "application type" dropdown below when filling out the form if you are a faculty member.</p>
            <EducationSubmissionForm  />
        </div>
    )
}