import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionCard from "../../license/SubscriptionCard";
import { subsSelector } from "../../../features/user/subscriptionsSlice";
import "./SubscriptionsPage.scss";
import { licensesStateSelector, orphanLicensesSelector } from "../../../features/user/licensesSlice";
import LicenseCard from "../../license/LicenseCard";
import { getErrorsCompiled } from "../../../utils/uiHelpers";
import { selfScanInsertStatusSelector, selfScanInsertThunk, userInfoSelector } from "../../../features/user/userSlice";

const SubscriptionsPage = () => {
    const user = useSelector(userInfoSelector);
    const subs = useSelector(subsSelector);
    const lics = useSelector(licensesStateSelector);
    const selfScan = useSelector(selfScanInsertStatusSelector);
    const oprhanLicensesIds = useSelector(orphanLicensesSelector)
    const dispatch = useDispatch();

    const subsCards = useMemo(() => {
        if (subs.status.value !== "success") return [];

        return subs.keys.map(id => <SubscriptionCard key={id} sub={subs.entities[id]} />);
    }, [subs.entities, subs.status.value]);

    const licsCards = useMemo(() => {
        const cards = oprhanLicensesIds.map(licId => <LicenseCard license={lics.licenses.entities[licId]} />);
        return cards;
    }, [oprhanLicensesIds]);

    const errCmp = useMemo(() => {
        if (subs.status.value !== "failure") return <></>;
        if (subs.status.error?.errors["NoSubsFound"]) {
            if (user?.customData.canReloadSubscriptions) {
                return (
                    <div>
                        <p>We have found no subscriptions on this email address, if you think this is an error please click the button below to reload your subscriptions then refresh the page.</p>
                        <button onClick={() => dispatch(selfScanInsertThunk())} className="btn primary" disabled={selfScan.value === "pending"}>Reload Subscriptions</button>
                    </div>
                )
            } else {
                return <p>You have no subscriptions on this email address. If you think this is an error please contact us at <a href="mailto:support@jangafx.com">support@jangafx.com</a>.</p>
            }
        } else {
            return getErrorsCompiled(subs.status.error).map(e => e);
        }
    }, [subs.status.value, subs.status.error?.errors, user?.customData.canReloadSubscriptions]);

    return (
        <div id="subscriptionsPage">
            <div className="header">
                <h2>Subscriptions</h2>
                <p className="muted">You can manage and modify your subscriptions below.</p>
            </div>
            <hr className="big" />
            <div id="subscriptionCardsContainer">
                {subs.status.value === "success" && subsCards}
                {subs.status.value === "pending" && "Looking for your subscriptions... 🔍"}
                {errCmp}
            </div>
            {oprhanLicensesIds.length > 0 &&
                <>
                    <div className="header">
                        <h2>Licenses</h2>
                        <p className="muted">Below you have licenses without a subscription plan or a subscription whom you don't have access to.</p>
                    </div>
                    <div>
                        {licsCards}
                    </div>
                </>}
        </div>
    )
};

export default SubscriptionsPage;