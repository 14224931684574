import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from "..";
import ApplicationAlert, { ApplicationAlertType } from "../../model/ApplicationAlert";

export interface GlobalUiState {
    sidebarOpened: boolean;
    alerts: ApplicationAlert[];
}

const initialState: GlobalUiState = {
    sidebarOpened: false,
    alerts: [],
};

//#region Selectors
export const uiGlobalStateSelector = (state: ApplicationState) => state.ui.global;
export const alertsSelector = (state: ApplicationState) => state.ui.global.alerts;
//#endregion

//#region Thunks
export class PushAlertArguments {
    /**
     * Arguments to create an alert.
     * @param title The title of the alert.
     * @param body The body of the alert, can be a string or a JSX element.
     * @param cooldown The cooldown after how long the alert should disappear, expressed in seconds.
     */
    constructor(
        public title: string,
        public type: ApplicationAlertType,
        public cooldown: number = 4000,
        public body?: string | JSX.Element) {
    }
}

export const pushAlert = createAsyncThunk<void, PushAlertArguments>("ui/pushAlert",
    ({ cooldown, type, title, body }, thunkApi) => {
        if (!cooldown) cooldown = 4000;

        const appAlert: ApplicationAlert = {
            id: Math.random().toString(36).substring(2, 9),
            title: title,
            content: body,
            type: type,
        }

        thunkApi.dispatch(addAlert(appAlert));

        setTimeout(() => {
            thunkApi.dispatch(removeAlert(appAlert.id));
        }, cooldown);
    });
//#endregion

const uiSlice = createSlice({
    name: "ui/global",
    initialState: initialState,
    reducers: {
        addAlert(state: GlobalUiState, action: PayloadAction<ApplicationAlert>) {
            state.alerts.push(action.payload);
        },
        removeAlert(state: GlobalUiState, action: PayloadAction<string>) {
            state.alerts = state.alerts.filter(a => a.id !== action.payload);
        },
        showSidebar(state: GlobalUiState) {
            state.sidebarOpened = true;
        },
        hideSidebar(state: GlobalUiState) {
            state.sidebarOpened = false;
        },
        toggleSidebar(state: GlobalUiState) {
            state.sidebarOpened = !state.sidebarOpened;
        }
    },
    extraReducers: b => b
});

export const { hideSidebar, showSidebar, toggleSidebar, addAlert, removeAlert } = uiSlice.actions;

export default uiSlice.reducer;