import { useEffect, useRef, useState } from "react";
import { ValidationProblemDetails } from "../features";
import { SoftwareCode } from "../features/softwareReleasesSlice";
import embergenLogo from "../assets/media/logos/embergen.svg";
import vectoraygenLogo from "../assets/media/logos/vectoraygen.png";
import geogenLogo from "../assets/media/logos/geogen.svg";
import liquigenLogo from "../assets/media/logos/liquigen.svg";

/**
 * 
 * @param filter Trigger state change only for the keys inside this filter.
 * @returns 
 */
export const useVoidKeyCapture = (keyCaptured: (key: string) => void, clickCaptured: (ev: MouseEvent) => void, filter?: string[]) => {
    const ref = useRef<HTMLDivElement>(null);

    const onKeydown = (event: KeyboardEvent) => {
        if (filter !== undefined) {
            if (filter.includes(event.key)) {
                keyCaptured(event.key);
            }
        } else {
            keyCaptured(event.key);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            // keyCaptured("Escape");
            clickCaptured(event);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onKeydown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', onKeydown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref };
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const onWindowsResized = (event: UIEvent) => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener("resize", onWindowsResized);

        return () => window.removeEventListener("resize", onWindowsResized);
    }, []);

    return windowSize;
};

export const getErrorsCompiled = (validationProblem?: ValidationProblemDetails): string[] => {
    if (!validationProblem) return [];

    return Object.keys(validationProblem.errors ?? {})
        .map((key) => validationProblem.errors[key])
        .filter(err => err !== undefined);
}

export const getSoftwareLogoSrc = (code: SoftwareCode) => {
    if (code === "embergen") return embergenLogo;
    if (code === "geogen") return geogenLogo;
    if (code === "liquigen") return liquigenLogo;
    if (code === "vectoraygen") return vectoraygenLogo;
};

export const sleepAsync = (time: number) => new Promise((resolve) => setTimeout(resolve, time));