import "./SoftwareTimeline.scss";

import iconTimeline from "../assets/media/icons/timeline.svg";
import iconArrowLeft from "../assets/media/icons/arrow_left.svg";
import iconArrowRight from "../assets/media/icons/arrow_right.svg";
import embergenLogoSrc from '../assets/media/icons/embergen.svg';
import iconGeogenSrc from "../assets/media/icons/geogen.svg";
import iconLiquigenSrc from "../assets/media/icons/liquigen.svg";

import { useDispatch, useSelector } from "react-redux";
import { getLicenseTimeline, License, Timeline, timelineSelector, timelineStatuSelector } from "../features/user/licensesSlice";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../hooks/uiHooks";
import Button from "./Button";

export interface TimelineDrawContext {
    deltaTime: number;
    width: number;
    height: number;
    mouse: { x: number; y: number };
}

export interface SoftwareTimelineProps {
    license: License;
}

type SoftwareCode = "embergen" | "geogen" | "liquigen" | "vectoraygen";

const SoftwareTimeline = ({ license }: SoftwareTimelineProps) => {
    const dispatch = useDispatch();
    const [swCode, setSwCode] = useState<SoftwareCode>("embergen");
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const fetchStatus = useSelector(timelineStatuSelector(license.id));
    const timeline = useSelector(timelineSelector(license.id));

    useEffect(() => {
        dispatch(getLicenseTimeline({ licenseId: license.id, startAt: new Date(year, 0, 1), endAt: new Date(year + 1, 0, 1) }));
    }, [license.id, year]);

    return (
        <div className="timeline">
            <div className="header">
                <div>
                    <img src={iconTimeline} />
                    <p className="muted">Version Access Timeline</p>
                </div>
                <div id="swSelectorContainer">
                    {(license.productCode.includes("suite") || license.productCode.includes("embergen")) &&
                        <div className={`selector ${swCode === "embergen" ? "selected" : ""}`} onClick={() => setSwCode("embergen")}>
                            <img src={embergenLogoSrc} />
                            <p>Ember<span>Gen</span></p>
                        </div>}

                    {(license.productCode.includes("suite") || license.productCode.includes("geogen")) &&
                        <div className={`selector ${swCode === "geogen" ? "selected" : ""}`} onClick={() => setSwCode("geogen")}>
                            <img src={iconGeogenSrc} />
                            <p>Geo<span>Gen</span></p>
                        </div>}

                    {(license.productCode.includes("suite") || license.productCode.includes("liquigen")) &&
                        <div className={`selector ${swCode === "liquigen" ? "selected" : ""}`} onClick={() => setSwCode("liquigen")}>
                            <img src={iconLiquigenSrc} />
                            <p>Liqui<span>Gen</span></p>
                        </div>}
                </div>
                <div className="year-picker">
                    <Button color={"tertiary"} disabled={fetchStatus.value === "pending"} onClick={() => setYear(year - 1)}><img src={iconArrowLeft} /></Button>
                    <div>{year ?? "???"}</div>
                    <Button color={"tertiary"} disabled={fetchStatus.value === "pending"} onClick={() => setYear(year + 1)}><img src={iconArrowRight} /></Button>
                </div>
            </div>
            <div className="content">
                {fetchStatus.value === "pending" && <div style={{ width: "100%", height: "250px" }}><p className="text-center">Generating the timeline... 💹</p></div>}
                {fetchStatus.value === "failure" && <p>An error occured while generating your timeline, please contact the support or <a onClick={() => dispatch(getLicenseTimeline({ licenseId: license.id, startAt: new Date(year, 0, 1), endAt: new Date(year + 1, 0, 1) }))}>try again</a>.</p>}
                {fetchStatus.value === "success" && <SoftwareTimelineContent timeline={timeline} swCode={swCode} />}
            </div>
        </div>
    );
};

interface SoftwareTimelineContentProps {
    timeline: Timeline;
    swCode: SoftwareCode;
}

const dateNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const SoftwareTimelineContent = ({ timeline, swCode }: SoftwareTimelineContentProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const cvsRef = useRef<HTMLCanvasElement>(null);
    const [canvasSize, setCanvasSize] = useState<{ width: number, height: number }>({ height: 250, width: 500 });
    const { width: wWidth, height: wHeight } = useWindowSize();
    const [months, setMonths] = useState<number>(12);

    const draw = (ctx: CanvasRenderingContext2D, { mouse, width, height, deltaTime }: TimelineDrawContext) => {
        ctx.clearRect(0, 0, width, width);

        const fontSize = 16; // px
        const verFontSize = 14; // px
        const wDate = width / months; // Width per date
        const startAt = new Date(timeline.startAt).getTime();
        const endAt = new Date(timeline.endAt).getTime();

        /**
         * Computes the relative time position (or "progress").
         * @param time The time of the element to position.
         * @returns The relative progress between [0; 1] of the time.
         */
        const getTimeRelPosition = (time: number) => {
            if (time >= startAt && time <= endAt) {
                return (time - startAt) / (endAt - startAt);
            }

            return 0;
        }

        const roundedRectangled = (ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) => {
            ctx.moveTo(x, y);
            ctx.arc(x + radius, y + radius, radius, Math.PI, - Math.PI / 2);
            ctx.arc(x + width - radius, y + radius, radius, - Math.PI / 2, 0);
            ctx.arc(x + width - radius, y + height - radius, radius, 0, Math.PI / 2);
            ctx.arc(x + radius, y + height - radius, radius, Math.PI / 2, Math.PI);
        };

        ctx.font = `${fontSize}px Hando`;

        // draw background rect for dates
        const datesBarHeight = 38;
        ctx.fillStyle = "#12141680";
        ctx.fillRect(0, 0, width, datesBarHeight);

        // draw background rect for versions
        ctx.fillStyle = "#0000004D";
        ctx.fillRect(0, datesBarHeight, width, height - datesBarHeight);

        // draw grid lines & date names
        ctx.fillStyle = "#1A1D20";
        ctx.fillRect(0, datesBarHeight, width, 1);
        for (let m = 0; m < months; m++) {
            // Do not draw last date's bar
            if (m < months - 1) {
                ctx.fillStyle = "#1A1D20";
                ctx.fillRect(wDate * (m + 1), 0, 1, height);
            }

            ctx.fillStyle = "#FFFFFF66";
            ctx.textAlign = "center";
            ctx.fillText(dateNames[m], wDate * m + wDate / 2, fontSize + 11);
        }

        // draw maintenance bar
        const deactAt = timeline.subscriptionDeactivatedAt ? new Date(timeline.subscriptionDeactivatedAt).getTime() : endAt;
        const mtnWidth = getTimeRelPosition(deactAt) * width;

        if (timeline.subscriptionDeactivatedAt) { // maintenance expires at some time
            ctx.fillStyle = "#543570";
            ctx.fillRect(0, 135, mtnWidth, 32);

            ctx.fillStyle = "#FFFFFF";
            ctx.fillText("Maintenance", mtnWidth / 2, 135 + fontSize + 7);
        } else { // active maintenance
            ctx.fillStyle = "#54357066";
            ctx.fillRect(0, 135, mtnWidth, 32);

            ctx.fillStyle = "#FFFFFF66";
            ctx.fillText("Active Maintenance", mtnWidth / 2, 135 + fontSize + 7);
        }

        // draw current day bar
        const today = new Date().getTime();
        if (today >= startAt && today < endAt) {
            const todayX = width * getTimeRelPosition(today);

            ctx.fillStyle = "#FF7078";
            ctx.fillRect(todayX, datesBarHeight, 1, height - datesBarHeight);
        }

        type Rect = { x: number, y: number, width: number, height: number };
        const intersect = (r1: Rect, r2: Rect) => {
            return r1.x <= (r2.x + r2.width) && (r1.x + r1.width) >= r2.x &&
                r1.y <= (r2.y + r2.height) && (r1.y + r1.height) >= r2.y;
        }
        const isIn = (pos: { x: number, y: number }, r: Rect) => {
            return pos.x >= r.x && pos.x <= (r.x + r.width) && pos.y >= r.y && pos.y <= (r.y + r.height);
        }

        const prevRects: Rect[] = [];

        // draw version bubbles
        const versions = timeline.releases.entities?.[swCode].versions ?? [];
        for (let vi = 0; vi < versions.length; vi++) {
            const v = versions[vi];
            const verDate = new Date(v.releasedAt).getTime();

            ctx.font = `${verFontSize}px Hando`;
            const padding = { l: 8, t: 6, r: 8, b: 8 };
            const xProg = getTimeRelPosition(verDate);
            const txtWidth = ctx.measureText(v.version);
            const rHeight = padding.t + verFontSize + padding.b;
            const distanceFromDates = 15;

            const rect: Rect = {
                x: xProg * width - txtWidth.width / 2 - padding.l,
                y: datesBarHeight + distanceFromDates,
                width: padding.l + txtWidth.width + padding.r,
                height: rHeight,
            };

            for (let r = 0; r < prevRects.length; r++) {
                if (intersect(rect, prevRects[r])) {
                    const padding = 10; // px
                    const offset = rHeight + padding;
                    rect.y += offset;

                    r = 0; // push back and reiterate over the whole loop, risk of overflowing
                }
            }

            prevRects.push(rect);

            // draw bubble
            const disabledColor = "#383a3b";
            const hasAccessColor = isIn(mouse, rect) ? "#3fbf76" : "#219653";

            ctx.fillStyle = v.hasAccess ? hasAccessColor : disabledColor;
            ctx.strokeStyle = v.hasAccess ? hasAccessColor : disabledColor;

            ctx.beginPath();
            roundedRectangled(ctx, rect.x, rect.y, rect.width, rect.height, 8);
            ctx.fill();

            // draw trail
            ctx.beginPath();
            ctx.setLineDash([5, 5]);
            ctx.moveTo(rect.x + rect.width / 2, rect.y + rect.height);
            ctx.lineTo(rect.x + rect.width / 2, height);
            ctx.stroke();

            // draw trail start arrow
            const ptrSize = 7;
            ctx.beginPath();
            ctx.moveTo(rect.x + rect.width / 2 - ptrSize / 2, rect.y + rect.height);
            ctx.lineTo(rect.x + rect.width / 2 + ptrSize / 2, rect.y + rect.height);
            ctx.lineTo(rect.x + rect.width / 2, rect.y + rect.height + ptrSize);
            ctx.fill();

            // draw trail end arrow
            ctx.beginPath();
            ctx.moveTo(rect.x + rect.width / 2 - ptrSize / 2, height);
            ctx.lineTo(rect.x + rect.width / 2 + ptrSize / 2, height);
            ctx.lineTo(rect.x + rect.width / 2, height - ptrSize);
            ctx.fill();

            // draw bubble text
            ctx.textAlign = "left";
            ctx.fillStyle = "#FFFFFF";
            ctx.fillText(v.version, rect.x + padding.l, rect.y + padding.t + verFontSize);
        }
    };

    useEffect(() => {
        if (cvsRef.current === undefined || containerRef.current === undefined) return;
        const ctx = cvsRef.current?.getContext("2d");
        let handle: number | undefined;

        const mousePos: { x: number, y: number } = { x: 0, y: 0 };
        const onMouseMove = (ev: MouseEvent) => { mousePos.x = ev.offsetX; mousePos.y = ev.offsetY; };
        if (ctx) {
            containerRef.current?.addEventListener("mousemove", onMouseMove);

            let pts = new Date().getTime();
            const callback = (ts: number) => {
                draw(ctx, {
                    width: canvasSize.width,
                    height: canvasSize.height,
                    mouse: mousePos,
                    deltaTime: (ts - pts) / 1000,
                });
                pts = ts;

                handle = window.requestAnimationFrame(callback);
            };

            handle = window.requestAnimationFrame(callback);
        }

        return () => {
            if (handle) {
                window.cancelAnimationFrame(handle);
                containerRef?.current?.removeEventListener("mousemove", onMouseMove);
            }
        }
    }, [containerRef, cvsRef, canvasSize, swCode]);

    useEffect(() => {
        const ctrRect = containerRef.current?.getBoundingClientRect();

        setCanvasSize({ width: ctrRect?.width ?? 500, height: 250 });
    }, [wWidth, wHeight]);

    return (
        <div ref={containerRef} >
            <canvas ref={cvsRef} width={canvasSize.width} height={canvasSize.height} />
        </div>
    )
}

export default SoftwareTimeline;