/* eslint-disable @typescript-eslint/no-misused-promises */
import "./LicensesDetailedList.scss";
import iconKey from "../../../assets/media/icons/key.svg";
import iconClipboard from "../../../assets/media/icons/clipboard.svg";
import iconRevokeKey from "../../../assets/media/icons/revoke_key.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { pushAlert } from "../../../features/ui/globalUiSlice";
import { assignLicenseToUser, assignmentStatusSelector, License, licensesStateSelector, revokeLicenseFromUser, revokeStatusSelector } from "../../../features/user/licensesSlice";
import Button from "../../Button";
import Checkbox from "../../Checkbox";
import Input from "../../Input";
import Modal, { ModalProps } from "../../Modal";
import { subSelector } from "../../../features/user/subscriptionsSlice";
import { getErrorsCompiled } from "../../../utils/uiHelpers";
import { inviteUser, inviteUserStatusSelector } from "../../../features/user/userSlice";

interface LicenseFilter {
    keyRegex: string;
    showAssigned: boolean;
    showUnassigned: boolean;
}

interface AssignemntModalState {
    isOpen: boolean;
    license?: License;
}

const LicensesDetailedList = () => {
    const { subId, licenseId } = useParams();
    const licensesState = useSelector(licensesStateSelector);
    const sub = useSelector(subSelector(subId));
    const [assignmentModal, updateAssignmentModal] = useState<AssignemntModalState>({
        isOpen: false,
    });
    const [filter, setFilter] = useState<LicenseFilter>({
        keyRegex: "",
        showAssigned: true,
        showUnassigned: true,
    });

    const licenses = useMemo(() => {
        if (sub === undefined) return [];

        let lics = Object.values(licensesState.licenses.entities).filter(l => sub.licensesIds.includes(l.id));

        if (filter.keyRegex && filter.keyRegex !== "") {
            const regex = new RegExp(filter.keyRegex, "i");
            lics = lics.filter(l => regex.test(l.key) || regex.test(l.sharedTo?.[0]?.name));
        }

        if (!filter.showAssigned) {
            lics = lics.filter(l => l.sharedTo.length === 0);
        }

        if (!filter.showUnassigned) {
            lics = lics.filter(l => l.sharedTo.length > 0);
        }

        return lics;
    }, [subId, filter, licensesState.licenses.getStatus.value, licensesState.licenses.entities]);

    return (
        <div id="licensesDetailedListContainer">
            {assignmentModal.license &&
                <AssignmentModal title="Assign a license key to a user" size="tiny" onCloseClicked={() => updateAssignmentModal({ isOpen: false })} isOpen={assignmentModal.isOpen} license={assignmentModal.license} content={<></>} />}
            <Input style={{ gridArea: "searchbar" }} placeholder="Search by key or user" onChange={ev => setFilter({ ...filter, keyRegex: ev.target.value })} />
            <div id="filtersContainer" style={{ gridArea: "filters" }}>
                <div>
                    <Checkbox name="showAssigned" checked={filter.showAssigned} onChange={_ => setFilter({ ...filter, showAssigned: !filter.showAssigned })} />
                    <label htmlFor="showAssigned">Assigned</label>
                </div>
                <div>
                    <Checkbox name="showUnassigned" checked={filter.showUnassigned} onChange={_ => setFilter({ ...filter, showUnassigned: !filter.showUnassigned })} />
                    <label htmlFor="showUnassigned">Unassigned</label>
                </div>
            </div>
            <div id="licensesList" style={{ gridArea: "licensesList" }}>
                <table>
                    <thead>
                        <tr>
                            <td>License Key</td>
                            <td>Shared to user</td>
                        </tr>
                    </thead>
                    <tbody>
                        {licenses && licenses.map(l => <LicenseItem key={l.id} license={l} onAssignClicked={(l) => updateAssignmentModal({ isOpen: true, license: l })} />)}
                        {licenses?.length === 0 && <tr><td className="text-center" colSpan={2}>No licenses found</td></tr>}
                    </tbody>
                </table>
            </div>
            <div id="licenseDetailsContainer" style={{ gridArea: "licenseDetails" }} className={licenseId === undefined ? "missing-license" : ""}>
                {!licenseId &&
                    <div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", backgroundColor: "#0000000F" }}>
                        <p className="text-center muted">Select a license key</p>
                    </div>}
                <Outlet />
            </div>
        </div>
    )
};

interface LicenseItemProps {
    license: License;

    onAssignClicked: (l: License) => void;
}

const LicenseItem = ({ license, onAssignClicked }: LicenseItemProps) => {
    const { licenseId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const revokeStatus = useSelector(revokeStatusSelector(license.id));

    const sharedWith = useMemo(() => {
        return license.sharedTo?.[0];
    }, [license.sharedTo]);

    return (
        <tr className={licenseId === license.id ? "item selected" : "item"}>
            <td onClick={() => navigate(license.id)}>
                <img src={iconKey} />
                <p className={`key ${revokeStatus?.value === "pending" ? "muted" : ""}`}>{license.key}</p>
                <img src={iconClipboard} onClick={() => {
                    dispatch(pushAlert({
                        title: "Key copied to clipboard!",
                        type: "positive",
                        cooldown: 2000,
                    }));

                    void navigator.clipboard.writeText(license.key);
                }} />
            </td>
            <td>
                {!sharedWith && <label style={{ color: "#0387E8", cursor: "pointer" }} onClick={_ => onAssignClicked(license)}>Assign to user</label>}
                {sharedWith &&
                    <>
                        <label className={revokeStatus?.value === "pending" ? "muted" : ""} >{license.sharedTo[0].name}</label>
                        <img src={iconRevokeKey} style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() => {
                                if (revokeStatus?.value !== "pending") {
                                    dispatch(revokeLicenseFromUser({
                                        licenseId: license.id,
                                        subId: license.subscriptionId,
                                        userId: sharedWith.userId
                                    }));
                                }
                            }} />
                    </>}
            </td>
        </tr>
    )
}

interface AssignmentModalProps extends ModalProps {
    license: License;
}

const AssignmentModal = (props: AssignmentModalProps) => {
    type FormInputs = { email: string };
    const { control, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm<FormInputs>({
        defaultValues: {
            email: "",
        }
    });

    const assignStatus = useSelector(assignmentStatusSelector(props.license.id));
    const inviteUserStatus = useSelector(inviteUserStatusSelector);

    const onSubmit = useCallback(({ email }: FormInputs) => {
        dispatch(assignLicenseToUser({
            licenseId: props.license.id,
            subId: props.license.subscriptionId,
            userEmail: email,
        }));
    }, []);

    const status = useSelector(assignmentStatusSelector(props.license.id));
    const dispatch = useDispatch();

    useEffect(() => {
        if (status.value === "success" && props.isOpen) {
            props.onCloseClicked?.();
        }
    }, [status]);

    const inviteUserClicked = useCallback(() => {
        const email = getValues("email");
        dispatch(inviteUser({ email: email }));
    }, []);

    return <Modal {...props} content={(
        <>
            <p><span className="danger bold">ATTENTION:</span> Once a license key is shared to a user they can keep record of it even after unassigment. Make sure the person is trusted.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`input-group ${assignStatus.value === "failure" ? "error" : ""}`}>
                    {/* <label>Assign to<span className="danger">*</span></label> */}
                    <Controller
                        name="email"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <Input {...field} type={"text"} placeholder="Type the user's e-mail..." error={errors.email !== undefined} disabled={status.value === "pending"} />} />
                    {errors.email?.type === "required" &&
                        <p className="danger">The e-mail is required.</p>}
                </div>

                <Button disabled={status.value === "pending"} color="primary" type="submit">
                    Assign License
                </Button>

                {assignStatus.value === "failure" && !assignStatus.error?.errors["NotFound"] &&
                    getErrorsCompiled(assignStatus.error).map(err => <p className="danger">{err}</p>)}
                {assignStatus.value === "failure" && assignStatus.error?.errors["NotFound"] &&
                    <>
                        <p className="danger">User has not been found on our platform.</p> <p>Invite the person by clicking the button below, once joined the platform you can assign the key to them.</p>
                        <Button color={"primary"} onClick={_ => inviteUserClicked()} disabled={inviteUserStatus.value === "pending"}>
                            {inviteUserStatus.value !== "success" && <>Invite {getValues("email")}</>}
                            {inviteUserStatus.value === "success" && <>Invite sent!</>}
                        </Button>
                        {inviteUserStatus.error && getErrorsCompiled(inviteUserStatus.error).map(err => <p className="danger">{err}</p>)}
                    </>}
            </form>
        </>
    )} />
};

export default LicensesDetailedList;