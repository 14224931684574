import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FetchStatus from "../../data/FetchStatus";
import { ApplicationState, ValidationProblemDetails } from "..";
import { Auth } from "aws-amplify";
import { EducationSubmissionType } from "../admin/adminEducationSlice";

export interface EducationState {
    postStatus: FetchStatus;
}

export interface EducationSubmissionInput {
    fullName: string;
    email: string;
    universityName: string;
    universityCity: string;
    universityCountry: string;
    universityCourse: string;
    reason: string;
    message?: string;
    submissionType: EducationSubmissionType;
    idFile: FileList;
}

// Thunks
export const postSubmission = createAsyncThunk<void, EducationSubmissionInput, { state: ApplicationState, rejectValue: ValidationProblemDetails | undefined }>("education/postSubmission",
    async (arg, thunkApi) => {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();

        const fd = new FormData();
        fd.append("FullName", arg.fullName);
        fd.append("Email", arg.email);
        fd.append("UniversityName", arg.universityName);
        fd.append("UniversityCourse", arg.universityCourse);
        fd.append("UniversityCountry", arg.universityCountry);
        fd.append("UniversityCity", arg.universityCity);
        fd.append("SubmissionType", arg.submissionType.toString());
        fd.append("Reason", arg.reason);
        if (arg.message) {
            fd.append("Message", arg.message);
        }
        fd.append("IdentificationFile", arg.idFile[0]);

        const httpResp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/education/submissions`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Accept": "application/json, text/plain",
            },
            body: fd,
        });

        if (httpResp.ok) {
            return;
        } else {
            if (httpResp.status === 400) {
                const valProblems = await httpResp.json() as ValidationProblemDetails;
                return thunkApi.rejectWithValue(valProblems);
            } else {
                return thunkApi.rejectWithValue({
                    errors: {
                        "Unknown": "An unknown error occured, please contact support."
                    }
                });
            }
        }
    });

// Initial state
const initialState: EducationState = {
    postStatus: { value: "idle" },
}

// Selectors
export const educationPostStatusSelector = (state: ApplicationState) => state.education.postStatus;

// Slice
const educationSlice = createSlice({
    initialState: initialState,
    name: "education",
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(postSubmission.rejected, (state, { payload }) => {
                state.postStatus = { value: "failure", error: payload };
            })
            .addCase(postSubmission.pending, (state) => {
                state.postStatus = { value: "pending" };
            })
            .addCase(postSubmission.fulfilled, (state) => {
                state.postStatus = { value: "success" };
            })

    }
});

export default educationSlice.reducer;