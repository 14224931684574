import { useDispatch, useSelector } from "react-redux";
import "./AdminEducationPage.scss";
import { useEffect, useState } from "react";
import { adminFetchStatistics, adminFetchSubmissions, EducationSubmissionStatus, EducationSubmissionType, statisticsFetchStatusSelector, statisticsSelector, submissionsFetchStatusSelector, submissionsSelector } from "../../../features/admin/adminEducationSlice";
import AdminEducationSubmission from "../../admin/AdminEducationSubmission";

export default function () {
    const [page, setPage] = useState<number>(1);
    const dispatch = useDispatch();
    const submissions = useSelector(submissionsSelector);
    const fetchStatus = useSelector(submissionsFetchStatusSelector);
    const stats = useSelector(statisticsSelector);
    const statsFetchStatus = useSelector(statisticsFetchStatusSelector);
    const [status, setStatus] = useState<EducationSubmissionStatus | string>(EducationSubmissionStatus.Pending);
    const [type, setType] = useState<EducationSubmissionType | string>(EducationSubmissionType.Student);

    useEffect(() => { dispatch(adminFetchStatistics()); }, []);
    useEffect(() => {
        dispatch(adminFetchSubmissions({ page: page, count: 10, status: status as EducationSubmissionStatus, type: type as EducationSubmissionType }));
    }, [status, type, page]);

    return (
        <div id="adminEducationPage">
            <div className="d-flex flex-direction-row flex-gap-large text-center justify-content-center" style={{ marginBottom: "2rem" }}>
                <div>
                    <h3 className="m-0">Submissions</h3>
                    {statsFetchStatus.value !== "pending" && <h2 className="m-0">{stats.totalSubmissions}</h2>}
                    {statsFetchStatus.value === "pending" && <h2 className="m-0">...</h2>}
                </div>
                <div>
                    <h3 className="m-0">Pending submissions</h3>
                    {statsFetchStatus.value !== "pending" && <h2 className="m-0">{stats.pendingSubmissions}</h2>}
                    {statsFetchStatus.value === "pending" && <h2 className="m-0">...</h2>}
                </div>
                <div>
                    <h3 className="m-0">Submitted last 7 days</h3>
                    {statsFetchStatus.value !== "pending" && <h2 className="m-0">{stats.last7DaysSubmissions}</h2>}
                    {statsFetchStatus.value === "pending" && <h2 className="m-0">...</h2>}
                </div>
                <div>
                    <h3 className="m-0">Submitted last 30 days</h3>
                    {statsFetchStatus.value !== "pending" && <h2 className="m-0">{stats.last30DaysSubmissions}</h2>}
                    {statsFetchStatus.value === "pending" && <h2 className="m-0">...</h2>}
                </div>
                <div>
                    <h3 className="m-0">Approval ratio</h3>
                    {statsFetchStatus.value !== "pending" && <h2 className="m-0">{stats.approvedRatio.toLocaleString("en", { style: "percent" })}</h2>}
                    {statsFetchStatus.value === "pending" && <h2 className="m-0">...</h2>}
                </div>
            </div>
            <div id="filterBar" className="d-flex flex-direction-row mb-1">
                <div className="d-flex flex-direction-row">
                    <p className="mt-auto mb-auto mr-1">Applicant type:</p>
                    <select className="mr-1" defaultValue={EducationSubmissionType.Student} onChange={ev => { setType(ev.currentTarget.value); setPage(1); }}>
                        <option value={EducationSubmissionType.Student}>Student</option>
                        <option value={EducationSubmissionType.FacultyMembers}>Faculty Members</option>
                        <option value={EducationSubmissionType.University}>University</option>
                    </select>
                    <p className="mt-auto mb-auto mr-1">Submission type:</p>
                    <select defaultValue={EducationSubmissionStatus.Pending} onChange={ev => { setStatus(ev.currentTarget.value); setPage(1); }}>
                        <option value={EducationSubmissionStatus.Pending}>Pending</option>
                        <option value={EducationSubmissionStatus.Approved}>Approved</option>
                        <option value={EducationSubmissionStatus.Denied}>Denied</option>
                    </select>
                </div>
                <div className="d-flex flex-direction-row">
                    <button className="btn secondary mr-1"
                        disabled={submissions.pageInfo.previousPage === null}
                        onClick={() => setPage(submissions.pageInfo.previousPage as number)}>Previous</button>
                    <label className="note">{submissions.pageInfo.page}/{submissions.pageInfo.totalPages}</label>
                    <button className="btn secondary ml-1"
                        disabled={submissions.pageInfo.nextPage === null}
                        onClick={() => setPage(submissions.pageInfo.nextPage as number)}>Next</button>
                </div>
            </div>
            <table className="small">
                <thead>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Major</th>
                    <th>University</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    {submissions?.data.map(s => <AdminEducationSubmission submission={s} disabled={fetchStatus.value === "pending"} />)}
                </tbody>
            </table>
        </div>
    );
}