import "./DiscordSocialLinkButton.scss";
import discordLogoSrc from "../../assets/media/images/discord-logo-blue.png";
import { useDispatch, useSelector } from "react-redux";
import { unlinkDiscordAccount, userAccountLinksSelector, userAccountLinksStatusSelector, userLinkingStatusSelector, userUnlinkingStatusSelector } from "../../features/user/userSlice";
import { getErrorsCompiled } from "../../utils/uiHelpers";
import { useCallback, useEffect } from "react";
import { pushAlert } from "../../features/ui/globalUiSlice";

const oauthUrl = process.env.REACT_APP_DISCORD_OAUTH2_REDIRECT_URI;

export const DiscordSocialLinkButton = () => {
    const linkState = useSelector(userAccountLinksSelector);

    return (
        <>
            {linkState?.discord ? <LinkedAccountContent /> : <UnlinkedAccountContent />}
        </>
    );
};

const UnlinkedAccountContent = () => {
    const dispatch = useDispatch();
    const userAccountLinksStatus = useSelector(userAccountLinksStatusSelector);
    const linkingStatus = useSelector(userLinkingStatusSelector);

    useEffect(() => {
        if (linkingStatus.value === "failure") {
            dispatch(pushAlert({
                cooldown: 5000,
                title: "Could not link account",
                type: "negative",
                body: <div>{getErrorsCompiled(linkingStatus.error).map(e => <p>{e}</p>)}</div>
            }));
        }
    }, [linkingStatus.value]);

    const onClicked = useCallback(() => {
        if (userAccountLinksStatus.value === "success" && linkingStatus.value === "idle") {
            window.open(oauthUrl, "_self");
        }
    }, [userAccountLinksStatus.value]);

    return (
        <>
            <div className={`discord-social-container ${userAccountLinksStatus.value === "success" ? "" : "disabled"}`} onClick={onClicked}>
                <img src={discordLogoSrc} />
                {linkingStatus.value !== "pending" &&
                    <h3 className="header"><span className="muted">+</span> Link Account</h3>}
                {linkingStatus.value === "pending" &&
                    <h3 className="header">Linking...</h3>}
            </div>
        </>
    );
}

const LinkedAccountContent = () => {
    const dispatch = useDispatch();
    const userAccountLinksStatus = useSelector(userAccountLinksStatusSelector);
    const unlinkStatus = useSelector(userUnlinkingStatusSelector);

    useEffect(() => {
        if (unlinkStatus.value === "failure") {
            dispatch(pushAlert({
                cooldown: 5000,
                title: "Could not unlink account",
                type: "negative",
                body: <div>{getErrorsCompiled(unlinkStatus.error).map(e => <p>{e}</p>)}</div>
            }));
        }
    }, [unlinkStatus.value]);

    const onClicked = useCallback(() => {
        if (userAccountLinksStatus.value === "success") {
            dispatch(unlinkDiscordAccount());
        }
    }, [userAccountLinksStatus.value]);

    return (
        <>
            <div className={`discord-social-container ${userAccountLinksStatus.value === "success" ? "" : "disabled"}`} onClick={onClicked}>
                <img src={discordLogoSrc} />
                {unlinkStatus.value !== "pending" &&
                    <h3 className="header">Unlink Account</h3>}
                {unlinkStatus.value === "pending" &&
                    <h3 className="header">Unlinking...</h3>}
            </div>
        </>
    );
};