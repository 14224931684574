import "./SideBarLink.scss";

import { NavLink } from "react-router-dom";

interface SideBarLinkProps {
    icon: JSX.Element | Element;
    children: JSX.Element | Element | string;

    to: string;
}

const SideBarLink = ({ icon, children, to }: SideBarLinkProps) => {
    return (
        <NavLink to={to} className={({ isActive }) => isActive ? "link active" : "link"}>
            <div className="icon">{icon}</div>
            <div className="content">
                {children}
            </div>
        </NavLink>
    );
};

export default SideBarLink;