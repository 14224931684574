import iconSeat from "../../assets/media/icons/computer.svg";
import iconUsers from "../../assets/media/icons/users.svg";
import iconBilling from "../../assets/media/icons/billing.svg";
import iconAlertNegative from "../../assets/media/icons/alert_negative.svg";

import { useMemo } from "react";
import { Subscription, SubscriptionState } from "../../features/user/subscriptionsSlice";
import "./SubscriptionCard.scss";
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import { getProductData, ProductData } from "../../utils/productHelper";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { UpgradeToSuiteButton } from "../subscription/UpgradeToSuiteCard";

interface SubscriptionCardHeaderProps {
    subscription: Subscription;
    productCode: string;
    subText?: string;
}

const Header = ({ productCode, subText, subscription: sub }: SubscriptionCardHeaderProps) => {

    const data = useMemo<ProductData>(() => getProductData(productCode), [productCode]);

    const subHeaderText = useMemo(() => {
        if (productCode.includes("indie")) return "Revenue under $1M";
        if (productCode.includes("studio")) return "Revenue under $1M";
        if (productCode.includes("educational")) return "Educational only";
        if (productCode.includes("enterprise")) return "Enterprise";

        return "";
    }, [productCode]);

    return (
        <div className="header">
            <img className="logo" src={data.logoSrc} />
            <div className="title">{data.header}</div>
            <div className="subtitle muted">{subHeaderText}</div>
            {sub.state === SubscriptionState.Deactivated &&
                <Tooltip content={<p className="pad">Your maintenance has expired!</p>}>
                    <Icon iconSrc={iconAlertNegative} />
                </Tooltip>}
        </div>
    )
};

interface SubscriptionCardProps {
    sub: Subscription;
}

const SubscriptionCard = ({ sub }: SubscriptionCardProps) => {
    const navigate = useNavigate();
    const periodicityText = useMemo(() => {
        if (sub.productCode.includes("monthly")) return "month";
        if (sub.productCode.includes("annual")) return "for maintenance";

        throw new Error(`Could not find periodicity for product code ${sub.productCode}`);
    }, [sub.productCode]);

    const priceText = useMemo(() => {
        if (sub.productCode.includes("monthly")) return new Intl.NumberFormat("en-US", { style: "currency", currency: sub.currency ?? "USD" }).format(sub.subtotal);
        if (sub.productCode.includes("annual")) return new Intl.NumberFormat("en-US", { style: "currency", currency: sub.currency ?? "USD" }).format(sub.nextChargeInfo.total) ?? "-";
    }, [sub.currency, sub.subtotal]);

    const sharedToText = useMemo(() => {
        if (sub.sharedTo.length === 0) return "no one";
        if (sub.sharedTo.length === 1) return "1 user";

        return `${sub.sharedTo.length} users`;
    }, [sub.sharedTo]);

    return (
        <>
            <div className="subscription-card">
                <Header productCode={sub.productCode} subscription={sub} />
                <p className="price">{priceText}<span> / {periodicityText}</span></p>
                <hr className="big" />
                <div className="list">
                    <div className="list-item"><img src={iconSeat} alt="Checkmark" /><span>{sub.isFloating ? "Seats" : "Keys"}: {sub.quantity}</span></div>
                    <div className="list-item"><img src={iconUsers} alt="Checkmark" /><span>Shared with: {sharedToText}</span></div>
                    {sub.nextChargeInfo &&
                        <div className="list-item"><img src={iconBilling} alt="Checkmark" /><span>Next billing: {sub.state === SubscriptionState.Active ? new Date(sub.nextChargeInfo.chargeDate).toDateString() : "-"}</span></div>}
                </div>
                <div className="d-flex flex-direction-column flex-gap-small">
                    <Button color="secondary" onClick={() => navigate(`/purchases/subscriptions/${sub.id}/licenses`)} style={{ marginTop: "20px" }}>
                        Manage
                    </Button>
                    {sub.canUpgradeToSuite && <UpgradeToSuiteButton sub={sub} />}
                </div>
            </div>
        </>
    )
};

export default SubscriptionCard;