import "./MonthlyToAnnualUpgradeCard.scss";
import { Subscription, SubscriptionChangeType, cancelPendingChange, cancelStatusStateSelector, initMonthlyToAnnualUpgrade, monthlyToAnnualUpgradeStateSelector } from "../../features/user/subscriptionsSlice";
import iconBillPaper from "../../assets/media/icons/bill_paper.svg";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";

interface MonthlyToAnnualUpgradeCardProps {
    subscription: Subscription;
}

export const MonthlyToAnnualUpgradeCard = ({ subscription: sub }: MonthlyToAnnualUpgradeCardProps) => {
    const dispatch = useDispatch();
    const upgradeState = useSelector(monthlyToAnnualUpgradeStateSelector(sub.id));
    const cancelState = useSelector(cancelStatusStateSelector(sub.id));

    return (
        <div className="card">
            <div className="header">
                <img src={iconBillPaper} />
                <h4 className="muted">Convert to permanent</h4>
            </div>
            <div id="upgradeToSuiteCardContent">
                <p>You have paid {sub.consecutivePayments} month(s) out of {sub.consecutivePaymentsNeededForPermanent} needed to reach permanent status on your keys.</p>
                <ProgressIndicator sub={sub} />
                <hr />
                {sub.pendingChange?.changeType === SubscriptionChangeType.MonthlyToAnnualUpgrade &&
                    <>
                        <p className="mb-1">
                            <span className="danger bold">NOTE:</span> Once the quote is paid all your licenses will automatically convert to permanent.
                        </p>
                        <button className="btn success"
                            onClick={() => window.open(upgradeState?.data?.quoteUrl, "_blank")}>Pay quote now</button>
                        <button className="btn danger"
                            disabled={cancelState?.value === "pending"}
                            onClick={() => dispatch(cancelPendingChange({ subId: sub.id }))}>Cancel quote</button>
                    </>}
                {sub.pendingChange?.changeType !== SubscriptionChangeType.MonthlyToAnnualUpgrade &&
                    <>
                        <p className="mb-1">Convert your licenses to permanent by upgrading to an annual maintenance plan.</p>
                        <button className="btn primary"
                            disabled={upgradeState?.status.value === "pending" || sub.pendingChange !== null}
                            onClick={() => dispatch(initMonthlyToAnnualUpgrade({ subId: sub.id }))}>
                            {upgradeState?.status.value === "pending" && "Generating quote..."}
                            {upgradeState?.status.value !== "pending" && sub.upgradeMonthlyToAnnualPrice &&
                                <>Upgrade to permanent for ${sub.upgradeMonthlyToAnnualPrice}</>}
                            {upgradeState?.status.value !== "pending" && !sub.upgradeMonthlyToAnnualPrice && <>Upgrade to permanent</>}
                        </button>
                    </>}
            </div>
        </div>
    )
};

interface ProgressIndicatorProps {
    sub: Subscription;
}

const ProgressIndicator = ({ sub }: ProgressIndicatorProps) => {
    const elems = useMemo(() => {
        const e: JSX.Element[] = [];
        for (let i = 0; i < sub.consecutivePaymentsNeededForPermanent; i++) {
            e.push(<div className={i < sub.consecutivePayments ? "reached" : ""}></div>);
        }
        return e;
    }, [sub.consecutivePayments]);

    return (
        <div className="progressindicator">
            {elems}
        </div>
    )
}