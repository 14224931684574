import './Icon.scss';

interface IconProps {
    iconSrc: string;
    alt?: string;

    bubbleContent?: string;
}

interface StyleInfo {
    bubble?: {
        x: number;
        y: number;
    };

    paddingRight?: number;
}

const Icon = ({ iconSrc, alt, bubbleContent }: IconProps) => {
    return (
        <div className={`icon`}>
            <img src={iconSrc} alt={alt ?? "Icon"} />
            {
                bubbleContent &&
                <div className="bubble">
                    {bubbleContent}
                </div>
            }
        </div >
    )
};

export default Icon;