/* eslint-disable @typescript-eslint/no-misused-promises */
import "./PersonalPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile, userSelector } from "../../../features/user/userSlice";
import Input, { FileInput } from "../../Input";
import Button from "../../Button";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";

interface ProfileUpdateInputs {
    fullName?: string;
    email?: string;
    company?: string;
    profilePicture?: FileList;
}

const PersonalPage = () => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);

    const { resetField, register, trigger, getValues, control, handleSubmit, formState: { errors }, setValue } = useForm<ProfileUpdateInputs>({
        defaultValues: {
            email: "",
            company: "",
            fullName: "",
        }
    });

    useEffect(() => {
        if (user.updateProfileState.status === "success") {
            resetField("profilePicture");
        }
    }, [resetField, user.updateProfileState]);

    const areFieldsDisabled = useMemo(() => user.updateProfileState.status === "pending", [user.updateProfileState.status]);

    useEffect(() => {
        setValue("fullName", user.info?.name);
        setValue("email", user.info?.email);
        setValue("company", user.info?.company ?? "");
        setValue("email", user.info?.email);
    }, [setValue, user.info]);

    const onSubmit = useCallback<SubmitHandler<ProfileUpdateInputs>>((data) => {
        dispatch(updateProfile({
            company: data.company,
            fullName: data.fullName,
            profilePicture: data.profilePicture?.item(0) ?? undefined
        }));
    }, [dispatch]);

    return (
        <div id="personalPage">
            <h3>Personal Information</h3>
            <p className="muted">Update your photo and personal details here.</p>
            <hr />
            {user.info?.emailVerified !== true &&
                <>
                    <p className="danger">Please confirm your e-mail address to gain full access to your account's features.</p>
                    <hr />
                </>}
            <form id="accountInformationForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group inline">
                    <label>Full name <br /><small>Your full family name</small></label>
                    <Controller
                        name="fullName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Input {...field} type={"text"} placeholder="Your full name..." disabled={areFieldsDisabled} />} />
                </div>
                <hr />
                <div className="input-group inline">
                    <label>E-mail</label>
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Input {...field} type={"text"} placeholder="Your e-mail..." disabled={true} />} />
                </div>
                <hr />
                <div className="input-group inline">
                    <label>Company</label>
                    <Controller
                        name="company"
                        control={control}
                        rules={{ maxLength: 75 }}
                        render={({ field }) => <Input {...field} type={"text"} placeholder="Your company name..." disabled={areFieldsDisabled} />} />
                </div>
                <hr />
                <div className="input-group inline">
                    <div>
                        <label>Your photo</label><br />
                        <small className="muted">This will be displayed on your profile.</small>
                    </div>
                    <div className="input">
                        <FileInput
                            {...register("profilePicture", {
                                validate: (files) => {
                                    const file = files?.item(0);
                                    if (file === undefined || file === null) return true;

                                    if (file.type !== "image/png" && file.type !== "image/jpeg") {
                                        return "File must be a .jpg, .jpeg or .png";
                                    }

                                    return file.size <= 1 * 1000 * 1000 ? true : "File is too large! (Must be less than 1MB)";
                                },
                                onChange: async () => {
                                    await trigger("profilePicture");
                                }
                            })}
                            files={getValues().profilePicture}
                            onFilesDropped={files => {
                                if (files) {
                                    setValue("profilePicture", files, {
                                        shouldValidate: true
                                    });
                                }
                            }}
                            disabled={areFieldsDisabled}
                            error={errors.profilePicture?.type === "validate"} />
                        {errors.profilePicture?.message &&
                            <p className="danger">{errors.profilePicture.message}</p>}
                    </div>
                </div>
                <Button type="submit" color="primary" disabled={areFieldsDisabled}>
                    {user.updateProfileState.status === "pending"
                        && "Updating profile..."}
                    {user.updateProfileState.status !== "pending"
                        && "Update profile"}
                </Button>
            </form>
        </div>
    );
};

export default PersonalPage;