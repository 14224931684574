/* eslint-disable @typescript-eslint/no-unused-vars */
import "./AdminUserViewModelCard.scss";
import iconPlus from "../../assets/media/icons/plus.svg";
import iconMinus from "../../assets/media/icons/minus.svg";
import logoFastSpringWhite from "../../assets/media/logos/fastspring-white.svg";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { AdminUserSubscription, AdminUserViewModel, discardStatusSelector, discardSubscription, refreshDiscordLink, refreshDiscordLinkStatusSelector, scanInsertThunk, scaninsertStatusSelector, undiscardStatusSelector, undiscardSubscription } from "../../features/admin/adminUsersSlice";
import { useEffect, useMemo, useState } from "react";
import { pushAlert } from "../../features/ui/globalUiSlice";
import { getErrorsCompiled } from "../../utils/uiHelpers";
import { ProductData, getProductData } from "../../utils/productHelper";
import { clamp } from "../../utils/mathHelpers";
import { Subscription, SubscriptionChangeType, buyMaintenance, cancelPendingChange, cancelPendingChangeStateSelector, initMonthlyToAnnualUpgrade, initSeatsKeysAddition, initSuiteUpgrade, maintenancePurchaseStateSelector, monthlyToAnnualUpgradeStateSelector, seatsKeysAdditionStateSelector, suiteUpgradeStateSelector } from "../../features/user/subscriptionsSlice";
import { License } from "../../features/user/licensesSlice";

const MIN_SEATS = 0;
const MAX_SEATS = 50;

export interface AdminUserViewModelCardProps {
    user?: AdminUserViewModel;
}

export const AdminUserViewModelCard = ({ user }: AdminUserViewModelCardProps) => {
    const dispatch = useDispatch();
    const scaninsertStatus = useSelector(scaninsertStatusSelector);
    const refreshDiscordLinkStatus = useSelector(refreshDiscordLinkStatusSelector);

    useEffect(() => {
        if (refreshDiscordLinkStatus.value === "failure" && refreshDiscordLinkStatus.error) {
            dispatch(pushAlert({
                title: "Could not refresh user Discord link",
                cooldown: 5000,
                type: "negative",
                body: <div>{getErrorsCompiled(refreshDiscordLinkStatus.error)}</div>
            }));
        }
    }, [refreshDiscordLinkStatus]);

    if (!user) return <>Invalid user data to display card.</>;

    return (
        <div className="admin-user-card d-flex flex-direction-column flex-gap-small">
            <div className="d-flex flex-direction-row flex-gap-small">
                {user.profilePicUrl && <img className="avatar" src={user.profilePicUrl} width={75} height={75} />}
                {!user.profilePicUrl && <div className="no-avatar text-center">{user.fullName[0]}</div>}
                <div className="d-flex flex-direction-column justify-content-space-evenly">
                    <span>{user.fullName}</span>
                    <span>{user.email}</span>
                    <span className="muted">{user.id}</span>
                </div>
            </div>
            <div>
                <h3>Account links</h3>
                <Button color={"primary"} onClick={_ => dispatch(refreshDiscordLink({ userId: user.id }))}
                    disabled={refreshDiscordLinkStatus.value === "pending" || !user.accountLinks.isDiscordLinked}>
                    {user.accountLinks.isDiscordLinked && "Refresh Discord link"}
                    {!user.accountLinks.isDiscordLinked && "No Discord link"}
                </Button>
            </div>
            <div className="d-flex flex-direction-column flex-gap-small">
                <h3>Subscriptions</h3>
                <div className="row">
                    <button className="btn primary col-3" onClick={_ => dispatch(scanInsertThunk({ email: user.email }))}
                        disabled={scaninsertStatus.value === "pending"}>Reload subscriptions</button>
                </div>
                <div className="d-flex flex-direction-row justify-content-space-evenly">
                    <div className="text-center">
                        <h3 className="margin-0">Subscriptions</h3>
                        <h2 className="margin-0">{user.subscriptionsCount}</h2>
                    </div>
                    <div className="text-center">
                        <h3 className="margin-0">Paired subs.</h3>
                        <h2 className="margin-0">{user.pairedSubscriptionsCount}</h2>
                    </div>
                    <div className="text-center">
                        <h3 className="margin-0">Licenses</h3>
                        <h2 className="margin-0">{user.licensesCount}</h2>
                    </div>
                    <div className="text-center">
                        <h3 className="margin-0">Paired licenses</h3>
                        <h2 className="margin-0">{user.pairedLicensesCount}</h2>
                    </div>
                </div>
                <div className="d-flex flex-direction-column flex-gap-small">
                    {user.subscriptions.keys.map(subId => <SubscriptionDetail subscription={user.subscriptions.entities[subId]} />)}
                </div>
            </div>
        </div>
    );
};

interface SubscriptionDetailProps {
    subscription: AdminUserSubscription;
}

const SubscriptionDetail = ({ subscription: sub }: SubscriptionDetailProps) => {
    const dispatch = useDispatch();
    const discardStatus = useSelector(discardStatusSelector(sub.id));
    const undiscardStatus = useSelector(undiscardStatusSelector(sub.id));
    const data = useMemo<ProductData>(() => getProductData(sub.productCode), [sub.productCode]);
    const subType = useMemo(() => {
        if (sub.productCode.includes("indie")) return "Indie";
        if (sub.productCode.includes("studio")) return "Studio";
        if (sub.productCode.includes("enterprise")) return "Enterprise";
        if (sub.productCode.includes("education")) return "Education";
    }, [sub.productCode]);

    const actionCards = useMemo<JSX.Element[]>(() => {
        const cards: JSX.Element[] = [];
        if (!sub.isActive && !sub.pendingChange) {
            cards.push(<MaintenancePurchaseQuoteCard subscription={sub} />);
        }

        if (!sub.pendingChange && sub.canAddSeats) {
            cards.push(<SeatsPurchaseQuoteCard subscription={sub} />);
        }

        if (sub.canUpgradeMonthlyToAnnual && !sub.pendingChange) {
            cards.push(<UpgradeToPermanentQuoteCard subscription={sub} />);
        }

        if (sub.canUpgradeToSuite && !sub.pendingChange) {
            cards.push(<UpgradeToSuiteQuoteCard subscription={sub} />);
        }

        return cards;
    }, [sub, sub.canAddSeats, sub.canUpgradeMonthlyToAnnual, sub.canUpgradeToSuite, sub.pendingChange]);

    return (
        <div className="subscription-detail-card">
            <div className="title">
                <div className="product">
                    <img className="logo" src={data.logoSrc} />
                    <h3 className="title">{data.header}</h3>
                    <sup className="title mt-0 mb-auto">{subType}<span>/{data.periodicity === "year" ? "Permanent" : data.periodicity === "month" ? "Monthly" : "?"}</span></sup>
                </div>
            </div>
            <hr style={{ backgroundColor: "#0389e853" }} />
            <div className="content">
                <div>
                    <p>Licenses: <span>{sub.licensesIds.length}</span></p>
                    <p>{sub.isFloating ? "Seats" : "Keys"}: <span>{sub.quantity}</span></p>
                    <p>Can purchase {sub.isFloating ? "seats" : "keys"}: {sub.canAddSeats ? <span className="success">Yes</span> : <span className="danger">No</span>}</p>
                    <p>Can upgrade to permanent: {sub.canUpgradeMonthlyToAnnual ? <span className="success">Yes</span> : <span className="danger">No</span>}</p>
                    <p>Maintenance status: {sub.isActive ? <span className="success">Active</span> : <span className="danger">Inactive</span>}</p>
                </div>
                <div>
                    <div className="d-flex flex-direction-column flex-gap-small">
                        <p>
                            Discard status<sup data-tooltip="Discarding allows you to completely hide a subscription (including licenses) from a user's account. You might want to do this when a subscription is not needed anymore or broken/invalid.">?</sup><span className="mr-1">:</span>
                            {!sub.isDiscarded && <button className="btn primary" onClick={_ => dispatch(discardSubscription({ subscriptionId: sub.id }))} disabled={discardStatus?.value === "pending"}>Hide</button>}
                            {sub.isDiscarded && <button className="btn primary" onClick={_ => dispatch(undiscardSubscription({ subscriptionId: sub.id }))} disabled={undiscardStatus?.value === "pending"}>Make visible</button>}
                        </p>
                    </div>
                </div>
                <div className="d-flex flex-direction-column flex-gap-small" style={{ alignItems: "center" }}>
                    <p className="text-center">Internal ID:<br />{sub.id}</p>
                    <a href={sub.fastSpringSubscriptionUrl} target="_blank" className="btn primary">View on <img height={15} src={logoFastSpringWhite} /></a>
                </div>
            </div>
            {!sub.isDiscarded &&
                <>
                    <hr style={{ backgroundColor: "#0389e853" }} />
                    <h3 className="text-center">Actions</h3>
                    <div className="d-flex flex-direction-column flex-gap-small">
                        {actionCards}
                        {actionCards.length === 0 && !sub.pendingChange &&
                            <p>There are no actions available on this subscription instance.</p>}
                        <PendingChangeCard subscription={sub} />
                    </div>
                </>}
            <div>
                <hr style={{ backgroundColor: "#0389e853" }} />
                <h3 className="text-center">Licenses</h3>
                <div className="d-flex flex-direction-column flex-gap-small">
                    {sub.licensesIds.map(licId => <LicenseCard license={sub.licenses[licId]} />)}
                </div>
            </div>
        </div>
    );
}

const LicenseCard = ({ license }: { license: License }) => {
    return (
        <div className="licenseCard w-100 d-flex flex-direction-row justify-content-space-between">
            <a href={`https://wyday.com/limelm/pkey/${license.id}/`} target="_blank">ID: {license.id}</a>
            <div>Key: {license.key}</div>
            <div>Activations: {license.activationsIds.length}</div>
        </div>
    )
};

const PendingChangeCard = ({ subscription: sub }: { subscription: Subscription }) => {
    const dispatch = useDispatch();
    const cancelPendingChangeStatus = useSelector(cancelPendingChangeStateSelector(sub.id));

    const pendingChangeTxt = useMemo(() => {
        if (sub.pendingChange?.changeType === SubscriptionChangeType.SuiteUpgrade) {
            return (
                <p>User has a pending <b>suite upgrade</b>, <a href={sub.pendingChange.quoteUrl} target="_blank">click here to see the quote</a>.</p>
            )
        } else if (sub.pendingChange?.changeType === SubscriptionChangeType.MaintenancePurchase) {
            return (
                <p>User has a pending <b>maintenance purchase</b>, <a href={sub.pendingChange.quoteUrl} target="_blank">click here to see the quote</a>.</p>
            )
        } else if (sub.pendingChange?.changeType === SubscriptionChangeType.SeatsAddition) {
            return (
                <p>User has a pending {sub.pendingChange.seatsAddition} <b>seats addition</b>, <a href={sub.pendingChange.quoteUrl} target="_blank">click here to see the quote</a>.</p>
            )
        }
        else if (sub.pendingChange?.changeType === SubscriptionChangeType.MonthlyToAnnualUpgrade) {
            return (
                <p>User has a pending <b>monthly to permanent upgraded</b>, <a href={sub.pendingChange.quoteUrl} target="_blank">click here to see the quote</a>.</p>
            )
        }

        return <></>;
    }, [sub.pendingChange]);

    if (!sub.pendingChange) { return <></>; }
    return (
        <div>
            {pendingChangeTxt}
            <button className="btn danger" disabled={cancelPendingChangeStatus?.value === "pending"}
                onClick={() => dispatch(cancelPendingChange({ subId: sub.id }))}>Cancel quote</button>
        </div>
    )
}

interface SeatsPurchaseQuoteCardProps {
    subscription: Subscription;
}
const SeatsPurchaseQuoteCard = ({ subscription: s }: SeatsPurchaseQuoteCardProps) => {
    const dispatch = useDispatch();
    const purchaseStatus = useSelector(seatsKeysAdditionStateSelector(s.id));
    const [newSeatsQtt, setNewSeatsQtt] = useState(0);

    return (
        <div className="action">
            <h4 className="underline">{s.isFloating ? "Seats" : "Keys"} purchase</h4>
            <p>Below you can create a quote for the user to buy a maintenance on this subscription.</p>
            <div className="d-flex flex-direction-row flex-gap-medium">
                <div className="d-flex flex-direction-row flex-gap-small">
                    <Button color="tertiary"
                        onClick={() => setNewSeatsQtt(clamp((isNaN(newSeatsQtt) ? 0 : newSeatsQtt) - 1, MIN_SEATS, MAX_SEATS))}>
                        <img src={iconMinus} />
                    </Button>
                    <button className="btn primary" onClick={() => dispatch(initSeatsKeysAddition({ subId: s.id, quantity: newSeatsQtt }))}
                        disabled={newSeatsQtt < 1 || purchaseStatus?.status.value === "pending"}>Create quote for {newSeatsQtt} {s.isFloating ? "seats" : "keys"}</button>
                    <Button color="tertiary"
                        onClick={() => {
                            setNewSeatsQtt(clamp((isNaN(newSeatsQtt) ? 0 : newSeatsQtt) + 1, MIN_SEATS, MAX_SEATS));
                        }}>
                        <img src={iconPlus} />
                    </Button>
                </div>
            </div>
            {purchaseStatus?.status.value === "failure" && getErrorsCompiled(purchaseStatus.status.error).map(e => <p className="danger">{e}</p>)}
        </div>
    );
}

const UpgradeToPermanentQuoteCard = ({ subscription: s }: { subscription: Subscription }) => {
    const dispatch = useDispatch();
    const upgradeStatus = useSelector(monthlyToAnnualUpgradeStateSelector(s.id));

    return (
        <div className="action">
            <h4 className="underline">Upgrade to permanent</h4>
            <p>Create a quote for the user in order to upgrade from his monthly subscription to permanent.</p>
            <button className="btn primary" onClick={() => dispatch(initMonthlyToAnnualUpgrade({ subId: s.id }))}
                disabled={upgradeStatus?.status.value === "pending"}>Create to-permanent upgrade quote</button>
            {upgradeStatus?.status.value === "failure" && getErrorsCompiled(upgradeStatus.status.error).map(e => <p className="danger">{e}</p>)}
        </div>
    );
}

const UpgradeToSuiteQuoteCard = ({ subscription: s }: { subscription: Subscription }) => {
    const dispatch = useDispatch();
    const upgradeStatus = useSelector(suiteUpgradeStateSelector(s.id));

    return (
        <div className="action">
            <h4 className="underline">Upgrade to Suite</h4>
            <p>Create a quote for the user in order to upgrade from his basic subscription to a Suite.</p>
            <button className="btn primary" onClick={() => dispatch(initSuiteUpgrade({ subId: s.id }))}
                disabled={upgradeStatus?.status.value === "pending"}>Create upgrade to Suite  quote</button>
            {upgradeStatus?.status.value === "failure" && getErrorsCompiled(upgradeStatus.status.error).map(e => <p className="danger">{e}</p>)}
        </div>
    );
}

const MaintenancePurchaseQuoteCard = ({ subscription: s }: { subscription: Subscription }) => {
    const dispatch = useDispatch();
    const purchaseStatus = useSelector(maintenancePurchaseStateSelector(s.id));

    return (
        <div className="action">
            <h4 className="underline">Maintenance purchase</h4>
            <p>Below you can create a quote for the user to buy a maintenance on this subscription.</p>
            <button className="btn primary" onClick={() => dispatch(buyMaintenance({ subId: s.id }))}
                disabled={purchaseStatus?.status.value === "pending"}>Create maintenance purchase quote</button>
            {purchaseStatus?.status.value === "failure" && getErrorsCompiled(purchaseStatus.status.error).map(e => <p className="danger">{e}</p>)}
        </div>
    );
};

export default AdminUserViewModelCard;