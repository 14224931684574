import "./ForgotPasswordTab.scss";
import { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button";
import Input from "../components/Input";
import { confirmPasswordForget, forgotPasswordStateSelector, initPasswordForget } from "../features/user/authenticationSlice";
import { Link, useNavigate } from "react-router-dom";

interface ConfirmPasswordForgetInputs {
    username: string;

    code: string;

    password: string;
    passwordConfirmation: string;
}

const ConfirmPasswordForgotTab = () => {
    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<ConfirmPasswordForgetInputs>({
        defaultValues: {
            username: "",
            code: "",
            password: "",
            passwordConfirmation: "",
        }
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { status: fpStatus, error: fpError, username, deliveryDetails } = useSelector(forgotPasswordStateSelector);

    const onSubmit = useCallback((inputs: ConfirmPasswordForgetInputs) => {
        dispatch(confirmPasswordForget({
            code: inputs.code,
            password: inputs.password,
        }))
    }, [dispatch]);

    if (fpStatus === "success") {
        return (
            <div style={{ textAlign: "center" }}>
                <p>Password reset successfully.</p>
                <Button color="primary" onClick={_ => navigate("/signin")}>Sign In Now</Button>
            </div>
        )
    }

    return (
        <form // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group">
                <label>Email<span className="danger">*</span></label>
                <Input type={"text"} placeholder="Type your email code..." disabled value={username} />
            </div>

            {deliveryDetails?.Destination && deliveryDetails.DeliveryMedium === "EMAIL" &&
                <div className="input-group">
                    <p>Confirmation code email sent to {deliveryDetails.Destination}</p>
                </div>}

            <div className="input-group">
                <label>Confirmation Code<span className="danger">*</span></label>
                <Controller
                    name="code"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Type your confirmation code..." error={errors.code !== undefined || fpError?.code === "CodeMismatchException"} disabled={fpStatus === "pending"} />} />
                {errors.code?.type === "required" &&
                    <p className="danger">The confirmation code is required.</p>}
                {fpStatus === "error" && fpError?.code === "CodeMismatchException" &&
                    <p className="danger">{fpError?.message}</p>}
            </div>

            <div className="input-group">
                <label>Password<span className="danger">*</span></label>
                <Controller
                    name="password"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"password"} placeholder="Type your password..." error={errors.password !== undefined || fpError?.code === "InvalidPasswordException"} disabled={fpStatus === "pending"} />} />
                {errors.password?.type === "required" &&
                    <p className="danger">The password is required.</p>}
            </div>

            <div className="input-group">
                <label>Password Confirmation<span className="danger">*</span></label>
                <Controller
                    name="passwordConfirmation"
                    rules={{
                        required: true,
                        validate: (confirmedPassword) => {
                            if (getValues().password === confirmedPassword) return true;

                            return "Passwords do not match.";
                        }
                    }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"password"} placeholder="Type your password again..." error={errors.passwordConfirmation !== undefined || fpError?.code === "InvalidPasswordException"} disabled={fpStatus === "pending"} />} />
                {errors.passwordConfirmation?.type === "required" &&
                    <p className="danger">The password confirmation is required.</p>}
                {errors.passwordConfirmation?.type === "validate" &&
                    <p className="danger">{errors.passwordConfirmation?.message}</p>}
            </div>

            <div className="input-group">
                {fpStatus === "error" && fpError?.code !== "CodeMismatchException" &&
                    <p className="danger">{fpError?.message}</p>}
                <br />
                <Button type="submit" color={"primary"} disabled={fpStatus === "pending"}>
                    {fpStatus === "pending" && <span>Confirming...</span>}
                    {fpStatus !== "pending" && <span>Confirm Password Reset</span>}
                </Button>
            </div>
        </form>
    )
}

interface InitiatePasswordForgotInputs {
    username: string;
}

const InitiatePasswordForgotTab = () => {
    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<InitiatePasswordForgotInputs>({
        defaultValues: {
            username: ""
        }
    });

    const dispatch = useDispatch();
    const { status: fpStatus, error: fpError } = useSelector(forgotPasswordStateSelector);

    const onSubmit = useCallback((inputs: InitiatePasswordForgotInputs) => {
        dispatch(initPasswordForget({ username: inputs.username }))
    }, [dispatch]);

    return (
        <form // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group">
                <label>Email<span className="danger">*</span></label>
                <Controller
                    name="username"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Type your email..." error={errors.username !== undefined || fpError?.code === "UsernameExistsException"} disabled={fpStatus === "pending"} />} />
                {errors.username?.type === "required" &&
                    <p className="danger">The username is required.</p>}
                {fpStatus === "error" && fpError?.code === "UsernameExistsException" &&
                    <p className="danger">{fpError?.message}</p>}
            </div>

            <div className="input-group">
                {fpStatus === "error" && fpError?.code === "UserNotFoundException" &&
                    <p className="danger">
                        No account is associated with this email, if this is the email you purchased with, please <Link style={{ color: "#e26077" }} to={"/signup"}>create an account here</Link> as this is our new account platform.
                    </p>}
                {fpStatus === "error" && fpError?.code !== "UserNotFoundException" &&
                    <p className="danger">{fpError?.message}</p>}
                <Button type="submit" color={"primary"} disabled={fpStatus === "pending"}>
                    {fpStatus === "pending" && <span>Requesting Password reset...</span>}
                    {fpStatus !== "pending" && <span>Request Password Reset</span>}
                </Button>
            </div>
        </form>
    )
}

export const ForgotPasswordTabLayout = () => {
    const fpState = useSelector(forgotPasswordStateSelector);

    return (
        <>
            {fpState.step === "initial" && <InitiatePasswordForgotTab />}
            {fpState.step === "confirmPassword" && <ConfirmPasswordForgotTab />}
        </>
    )
};