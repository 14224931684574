import "./Alert.scss";
import React, { useState } from "react";
import iconAlertPositive from "../assets/media/icons/alert_positive.svg";
import iconAlertNegative from "../assets/media/icons/alert_negative.svg";
import iconAlertNeutral from "../assets/media/icons/alert_neutral.svg";
import iconAlertWarning from "../assets/media/icons/alert_warning.svg";
import iconClose from "../assets/media/icons/close.svg";
import ApplicationAlert, { ApplicationAlertType } from "../model/ApplicationAlert";
import { useDispatch, useSelector } from "react-redux";
import { alertsSelector, removeAlert } from "../features/ui/globalUiSlice";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Alert {
    interface ProviderProps {
        children: JSX.Element;
    }

    export const Provider = (props: ProviderProps): JSX.Element => {
        const alerts = useSelector(alertsSelector);

        return (
            <div id="alertProvider">
                <div id="alertsContainer">
                    {alerts.map(alert => <Toast alert={alert} />)}
                </div>
                {props.children}
            </div>
        )
    };

    interface ToastProps {
        alert: ApplicationAlert;
    }

    export const Toast = ({ alert }: ToastProps) => {
        const dispatch = useDispatch();
        const [isExpanded, setExpanded] = useState(false);

        return (
            <div className={`toast ${alert.type}`}
                onMouseLeave={_ => setExpanded(false)}>

                <img src={getAlertTypeIcon(alert.type)}
                    alt={alert.type}
                    onMouseEnter={_ => setExpanded(true)} />

                <span className="title"
                    onMouseEnter={_ => setExpanded(true)}>
                    {alert.title}
                </span>

                <img src={iconClose} alt="Close Notification"
                    className="close"
                    onClick={_ => dispatch(removeAlert(alert.id))} />

                {alert.content &&
                    <div className={`content ${isExpanded ? "show" : "hide"}`}>
                        {alert.content}
                    </div>}
            </div>
        )
    }

    const getAlertTypeIcon = (type: ApplicationAlertType) => {
        if (type === "positive") return iconAlertPositive;
        if (type === "negative") return iconAlertNegative;
        if (type === "neutral") return iconAlertNeutral;
        if (type === "warning") return iconAlertWarning;

        throw new Error("Could not find icon for alert type.");
    }
}

export default Alert;