import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { Provider } from 'react-redux';
import store from './store';
import { Authentication } from './features/user/authentication';
import Alert from './components/Alert';

Amplify.configure({
  Auth: {
    region: "us-east-1",
    identityPoolId: "us-east-1:d61ec460-a905-4688-845f-86a1f9c489fb",
    userPoolId: "us-east-1_zdEodQJQ1",
    userPoolWebClientId: "1nt6n6pklp9r21dq466mdv3k33",
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "LicenseService",
        endpoint: process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: "jangafx-accountplatform-userfiles",
      region: "us-east-1",
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Authentication.Provider>
          <Alert.Provider>
            <App />
          </Alert.Provider>
        </Authentication.Provider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
