import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { linkDiscordAccount } from "../../features/user/userSlice";
import { pushAlert } from "../../features/ui/globalUiSlice";

export const DiscordLinkWorkerPage = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const code = params.get("code");
        const error = params.get("error");
        const errorDesc = params.get("error_description");
        if (code) {
            dispatch(linkDiscordAccount({ code: code }));
            navigate("/account-linking");
        } else if (error) {
            dispatch(pushAlert({
                title: "Could not link Discord account",
                cooldown: 5000,
                type: "negative",
                body: <div>{errorDesc}</div>
            }))
            navigate("/account-linking");
        }
    }, []);

    return (
        <div className="p-2">
            Hold on, we're doing some magic here...
        </div>
    );
};