import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authenticationStatusSelector, LoginArguments, loginUser, provideExistingSession, signOutUser, SignUpArguments, signUpUser, verifyLoginTotp, VerifyLoginTotpArguments, verifyNewPassword, VerifyNewPasswordArguments } from "./authenticationSlice";
import { userInfoSelector } from "./userSlice";

const useAuthenticator = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(userInfoSelector);
    const status = useSelector(authenticationStatusSelector);

    const loginCallback = useCallback((args: LoginArguments) => {
        dispatch(loginUser(args));
    }, [dispatch]);

    const logoutCallback = useCallback(() => {
        dispatch(signOutUser());
    }, [dispatch]);

    const signUpCallback = useCallback((args: SignUpArguments) => {
        dispatch(signUpUser(args));
    }, [dispatch]);

    const verifyTotpCallback = useCallback((args: VerifyLoginTotpArguments) => {
        dispatch(verifyLoginTotp(args));
    }, [dispatch]);

    const verifyNewPasswordCallback = useCallback((args: VerifyNewPasswordArguments) => {
        dispatch(verifyNewPassword(args));
    }, [dispatch]);

    return {
        userInfo,
        status,
        logIn: loginCallback,
        logOut: logoutCallback,
        signUp: signUpCallback,
        verifyTotp: verifyTotpCallback,
        verifyNewPassword: verifyNewPasswordCallback,
    };
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Authentication {
    interface ProviderProps {
        children: JSX.Element;
    }

    export const Provider = ({ children }: ProviderProps): JSX.Element => {
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(provideExistingSession());
        }, [dispatch]);

        return children;
    };
}

export default useAuthenticator;