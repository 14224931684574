import { MouseEventHandler, useEffect, useState } from "react";
import "./Selector.scss";

export interface SelectorItem<TLabel> {
    content: JSX.Element;
    label: TLabel;
    disabled?: boolean;
}

export interface SelectorProps<TLabel> {
    items: SelectorItem<TLabel>[];
    disabled?: boolean;
    onItemSelected: (index: number, label: TLabel) => void;
    activeElement?: TLabel;
}

function Selector<TLabel>({ items, onItemSelected, disabled, activeElement }: SelectorProps<TLabel>) {
    const [activeItem, setActiveItem] = useState<TLabel | undefined>(activeElement ?? undefined);
    useEffect(() => {
        setActiveItem(activeElement);
    }, [activeElement]);

    return (
        <div className={`selector ${disabled ? "disabled" : ""}`}>
            {items.map((it, idx) => <SelectorElement item={it} active={it.label === activeItem} onClick={_ => {
                setActiveItem(it.label);
                onItemSelected(idx, it.label);
            }} />)}
        </div>
    )
}

export interface SelectorElementProps<TLabel> {
    item: SelectorItem<TLabel>;
    active?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

function SelectorElement<TLabel>({ item, active, onClick }: SelectorElementProps<TLabel>) {
    return (
        <button className={`selector-item ${active ? "active" : ""}`} disabled={item.disabled} onClick={onClick}>
            {item.content && item.content}
        </button>
    )
}

export default Selector;