import "./LicenseDetails.scss";
import iconActivationsSummary from "../assets/media/icons/activations_summary.svg"
import iconLicenseActivations from "../assets/media/icons/license_acts.svg";
import iconWindows from "../assets/media/icons/windows.svg";
import iconMacOS from "../assets/media/icons/macos.svg";
import iconLinux from "../assets/media/icons/linux.svg";
import { useParams } from "react-router-dom";
import { Activation, deactivateLicenseActivation, deletionStatusSelector, fetchLicenseActivations, licenseActsStatusSelector as licenseActsStatusSelector, License, licensesStateSelector, licenseActsSelector } from "../features/user/licensesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import Button from "./Button";
import { getHumanizedTimeSpan } from "../utils/dateHelpers";
import SoftwareTimeline from "./SoftwareTimeline";

const LicenseDetails = () => {
    const licState = useSelector(licensesStateSelector);
    const { licenseId } = useParams();

    const license = useMemo(() => {
        if (licenseId === undefined) return undefined;

        const lic = licState.licenses.entities?.[licenseId];
        return lic;
    }, [licState.licenses.entities, licenseId]);

    if (license === undefined) {
        return <></>;
    }

    return (
        <div id="licenseDetailsCard">
            <div className="header">
                <p>License Key Details <span className="muted">({license.id})</span></p>
            </div>
            <div className="content">
                <LicenseActivationsSummaryDetails license={license} />
                <LicenseActivationsList license={license} />
                <SoftwareTimeline license={license} />
            </div>
        </div>
    );
};

interface LicenseActivationsListProps {
    license: License;
}

const LicenseActivationsList = ({ license }: LicenseActivationsListProps) => {
    const dispatch = useDispatch();
    const actState = useSelector(licenseActsStatusSelector(license.id));
    const acts = useSelector(licenseActsSelector(license.id));

    useEffect(() => {
        if (actState.value !== "success") {
            dispatch(fetchLicenseActivations({ licenseId: license.id }));
        }
    }, [license]);

    return (
        <div id="licActivationsCard" className="card">
            <div className="header">
                <img src={iconLicenseActivations} />
                <p className="muted">Current License Activations</p>
            </div>
            <div id="licActivationsContent">
                {actState.value === "pending"
                    && <div className="text-center" style={{ padding: "24px" }}>Loading your activations... 🔍</div>}
                {actState.value === "success" && acts.length === 0
                    && <div className="text-center" style={{ padding: "24px" }}>This license key has no known activations</div>}
                {actState.value === "success" && acts.map(a => <ActivationRow key={a.id} activation={a} />)}
            </div>
        </div>
    )
}

interface ActivationRowProps {
    activation: Activation;
}

const ActivationRow = ({ activation }: ActivationRowProps) => {
    const deletionStatus = useSelector(deletionStatusSelector(activation.id));
    const dispatch = useDispatch();

    const osInfo = useMemo<JSX.Element>(() => {
        if (activation.type === "windows") {
            return (
                <>
                    <img src={iconWindows} />
                    <label>Windows</label>
                </>
            );
        } else if (activation.type === "macos") {
            return (
                <>
                    <img src={iconMacOS} />
                    <label>MacOS</label>
                </>
            );
        } else if (activation.type === "linux") {
            return (
                <>
                    <img src={iconLinux} />
                    <label>Linux</label>
                </>
            );
        } else {
            return (
                <>
                    <img src={iconWindows} />
                    <label>Unknown OS</label>
                </>
            );
        }
    }, [activation]);

    return (
        <div className={`activation-row ${deletionStatus.value === "pending" ? "muted" : ""}`}>
            <div>{osInfo}</div>
            <div>IP Address: {activation.machineIp}</div>
            <div>
                <Button color="danger" onClick={() =>
                    dispatch(deactivateLicenseActivation({ activationId: activation.id, licenseId: activation.licenseId }))}>
                    Deactivate
                </Button>
            </div>
        </div>
    )
}

interface LicenseActivationsSummaryDetailsProps {
    license: License;
}

const LicenseActivationsSummaryDetails = ({ license }: LicenseActivationsSummaryDetailsProps) => {
    return (
        <div id="licenseActivationSummaryCard" className="card">
            <div className="header">
                <img src={iconActivationsSummary} />
                <p className="muted">Activations Summary</p>
            </div>
            <div id="licActivationsSummaryContent">
                <div className="item">
                    <p className="muted">Activations</p>
                    <h2>{license.activationsIds.length}</h2>
                </div>
                <div className="item">
                    <p className="muted">Deactivations left</p>
                    <h2>{license.deactivationsLeft}</h2>
                </div>
                <div className="item">
                    <p className="muted">Total Deactivations</p>
                    <h2>{license.totalDeactivations}</h2>
                </div>
                {license.deactivationCooldownExpiresAt &&
                    <div className="item">
                        <p className="muted">Cooldown expires in</p>
                        <h2>{getHumanizedTimeSpan(new Date(license.deactivationCooldownExpiresAt).getTime() - new Date().getTime())}</h2>
                    </div>}
                {!license.deactivationCooldownExpiresAt &&
                    <div className="item">
                        <p className="muted">Deact. quota renews</p>
                        <h2>{new Date(license.deactivationQuotaResetsAt).toDateString()}</h2>
                    </div>}
            </div>
        </div>
    )
}

export default LicenseDetails;