import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API, Auth } from "aws-amplify";
import { ApplicationState, NormalizedEntities, ValidationProblemDetails } from "..";
import FetchStatus from "../../data/FetchStatus";

export interface LicensesState {
    licenses: {
        getStatus: FetchStatus;
        assignmentStatus: { [licenseId: string]: FetchStatus };
        revokeStatus: { [licenseId: string]: FetchStatus };
        keys: string[];
        entities: { [key: string]: License };
    };

    activations: {
        deletionStatus: { [activationId: string]: FetchStatus };
        getStatus: { [activationId: string]: FetchStatus };
        keys: string[];
        entities: { [key: string]: Activation };
    };

    timelines: {
        getStatus: { [licenseId: string]: FetchStatus };
        values: { [licenseId: string]: Timeline };
    };
}

export interface Timeline {
    startAt: string;
    endAt: string;
    subscriptionDeactivatedAt?: string;
    releases: NormalizedEntities<TimelineProduct>;
}

export interface TimelineProduct {
    title: string;
    softwareCode: string;
    versions: TimelineVersion[];
}

export interface TimelineVersion {
    id: string;
    version: string;
    softwareCode: string;
    releasedAt: string;
    hasAccess: string;
    localizedSummary: { [languageCode: string]: string },
    binariesUrls: { [osCode: string]: string },
}

export interface LicenseReceiverInfo {
    userId: string,
    name: string,
}

export enum LicensePermissions {
    None = 0b0000,

    Read = 0b0001,
    Activate = 0b0010,
    Deactivate = 0b0100,

    All = 0b1111,
}

export interface Activation {
    id: string;
    licenseId: string;
    activatedAt?: string;
    machineIp: string;
    type: string;
}

export interface License {
    id: string,
    subscriptionId: string,
    ownerId: string,
    key: string,
    productCode: string,
    purchasedAt?: string,
    expiresAt?: string,
    isFloating: boolean,
    deactivationsLeft: number,
    totalDeactivations: number,
    totalActivations: number,
    deactivationQuotaResetsAt: string, // date-time
    deactivationCooldownExpiresAt: string // date-time,
    sharedTo: LicenseReceiverInfo[],
    activationsIds: string[],
    permissions: LicensePermissions
}

const initialState: LicensesState = {
    licenses: {
        getStatus: { value: "idle" },
        assignmentStatus: {},
        revokeStatus: {},
        keys: [],
        entities: {}
    },
    activations: {
        deletionStatus: {},
        getStatus: {},
        keys: [],
        entities: {}
    },
    timelines: {
        getStatus: {},
        values: {},
    }
};

export const fetchAllUserLicenses = createAsyncThunk<NormalizedEntities<License>, void>("user/licenses/fetchAllUserLicenses",
    async (_, api) => {
        try {
            const licenses = await API.get("LicenseService", "/api/v1/licenses/me", {}) as NormalizedEntities<License>;
            return licenses;
        } catch (error) {
            return api.rejectWithValue(undefined);
        }
    });

export const fetchLicenseById = createAsyncThunk<License, { licenseId: string }>("user/licenses/fetchLicenseById",
    async ({ licenseId }, api) => {
        try {
            const licenses = await API.get("LicenseService", `/api/v1/licenses/${licenseId}`, {}) as License
            return licenses;
        } catch (error) {
            return api.rejectWithValue(undefined);
        }
    });

export const fetchLicenseActivations = createAsyncThunk<NormalizedEntities<Activation>, { licenseId: string }>("user/licenses/fetchLicenseActivations",
    async ({ licenseId }, api) => {
        try {
            const acts = await API.get("LicenseService", `/api/v1/licenses/${licenseId}/activations`, {}) as NormalizedEntities<Activation>;
            return acts;
        } catch (error) {
            return api.rejectWithValue(undefined);
        }
    });

export const deactivateLicenseActivation = createAsyncThunk<void, { licenseId: string, activationId: string }>("user/licenses/deactivateLicenseActivation",
    async ({ licenseId, activationId }, api) => {
        try {
            await API.del("LicenseService", `/api/v1/licenses/${licenseId}/activations/${activationId}`, {});
            return;
        } catch (error) {
            return api.rejectWithValue(undefined);
        }
    });

export const assignLicenseToUser = createAsyncThunk<void, { subId: string, licenseId: string, userEmail: string }, { rejectValue: ValidationProblemDetails | undefined }>("user/licenses/assignLicenseToUser",
    async ({ subId, userEmail, licenseId }, api) => {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();

        const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/${subId}/licenses/${licenseId}/assign/${userEmail}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Accept": "application/json, text/plain",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                permissions: LicensePermissions.All
            })
        });

        if (resp.ok) {
            await api.dispatch(fetchLicenseById({ licenseId: licenseId }));
        } else {
            if (resp.status === 400) {
                return api.rejectWithValue(await resp.json() as ValidationProblemDetails);
            }
            else if (resp.status === 404) {
                return api.rejectWithValue({ errors: { "NotFound": "User could not be found." } } as ValidationProblemDetails);
            } else {
                return api.rejectWithValue(undefined);
            }
        }
    });

export const revokeLicenseFromUser = createAsyncThunk<void, { subId: string, licenseId: string, userId: string }>("user/licenses/revokeLicenseFromUser",
    async ({ subId, userId, licenseId }, thunkApi) => {
        try {
            await API.del("LicenseService", `/api/v1/subscriptions/${subId}/licenses/${licenseId}/assign/${userId}`, {});
            await thunkApi.dispatch(fetchLicenseById({ licenseId: licenseId }));
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const getLicenseTimeline = createAsyncThunk<Timeline, { licenseId: string, startAt: Date, endAt: Date }>("user/licenses/getLicenseTimeline",
    async ({ licenseId, endAt, startAt }, thunkApi) => {
        try {
            const timeline = await API.get("LicenseService", `/api/v1/licenses/${licenseId}/timeline`, {
                queryStringParameters: {
                    startAt: startAt.toISOString(),
                    endAt: endAt.toISOString(),
                }
            }) as Timeline;
            return timeline;
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

// SELECTORS
export const licensesStateSelector = (state: ApplicationState) => state.licenses;
export const orphanLicensesSelector = (state: ApplicationState) => {
    let licIds = [...state.licenses.licenses.keys];
    Object.entries(state.subscriptions.entities).forEach((v) => {
        licIds = licIds.filter(li => !v[1].licensesIds.includes(li))
    });

    return licIds;
};
export const deletionStatusSelector = (activationId: string) => (state: ApplicationState) => state.licenses.activations?.deletionStatus?.[activationId] ?? { value: "idle" };
export const licenseActsStatusSelector = (licenseId: string) => (state: ApplicationState) => state.licenses.activations?.getStatus?.[licenseId] ?? { value: "idle" };
export const licenseActsSelector = (licenseId: string) => (state: ApplicationState) => Object.values(state.licenses.activations.entities).filter(a => a.licenseId === licenseId);
export const assignmentStatusSelector = (licenseId: string) => (state: ApplicationState) => state.licenses.licenses.assignmentStatus?.[licenseId] ?? { value: "idle" };
export const revokeStatusSelector = (licenseId: string) => (state: ApplicationState): FetchStatus | undefined => state.licenses.licenses.revokeStatus?.[licenseId];
export const timelineStatuSelector = (licenseId: string) => (state: ApplicationState) => state.licenses.timelines.getStatus?.[licenseId] ?? { value: "idle" };
export const timelineSelector = (licenseId: string) => (state: ApplicationState) => state.licenses.timelines.values?.[licenseId] ?? undefined;

const licensesSlice = createSlice({
    name: "user/licenses",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => builder
        .addCase(fetchAllUserLicenses.pending, (state) => { state.licenses.getStatus.value = "pending" })
        .addCase(fetchAllUserLicenses.rejected, (state) => { state.licenses.getStatus.value = "failure" })
        .addCase(fetchAllUserLicenses.fulfilled, (state, action) => {
            state.licenses.keys = action.payload.keys;
            for (const licId in action.payload.entities) {
                state.licenses.entities[licId] = action.payload.entities[licId];
            }
            state.licenses.getStatus.value = "success";
        })

        .addCase(getLicenseTimeline.pending, (state, { meta: { arg: { licenseId } } }) => { state.timelines.getStatus[licenseId] = { value: "pending" } })
        .addCase(getLicenseTimeline.rejected, (state, { meta: { arg: { licenseId } } }) => { state.timelines.getStatus[licenseId].value = "failure" })
        .addCase(getLicenseTimeline.fulfilled, (state, { payload, meta: { arg: { licenseId } } }) => {
            state.timelines.values[licenseId] = payload;
            state.timelines.getStatus[licenseId].value = "success";
        })

        .addCase(fetchLicenseActivations.pending, (state, { meta: { arg: { licenseId } } }) => { state.activations.getStatus[licenseId] = { value: "pending" } })
        .addCase(fetchLicenseActivations.rejected, (state, { meta: { arg: { licenseId } } }) => { state.activations.getStatus[licenseId].value = "failure" })
        .addCase(fetchLicenseActivations.fulfilled, (state, action) => {
            state.activations.keys = action.payload.keys;
            for (const actId in action.payload.entities) {
                state.activations.entities[actId] = action.payload.entities[actId];
            }
            state.activations.getStatus[action.meta.arg.licenseId].value = "success";
        })

        .addCase(deactivateLicenseActivation.pending, (state, { meta: { arg: { activationId } } }) => {
            state.activations.deletionStatus[activationId] = { value: "pending", }
        })
        .addCase(deactivateLicenseActivation.rejected, (state, { meta: { arg: { activationId } } }) => { state.activations.deletionStatus[activationId].value = "failure"; })
        .addCase(deactivateLicenseActivation.fulfilled, (state, { meta: { arg: { activationId } } }) => {
            state.activations.deletionStatus[activationId].value = "success";
            delete (state.activations.entities[activationId]);
        })

        .addCase(assignLicenseToUser.pending, (state, action) => { state.licenses.assignmentStatus[action.meta.arg.licenseId] = { value: "pending" } })
        .addCase(assignLicenseToUser.rejected, (state, action) => {
            state.licenses.assignmentStatus[action.meta.arg.licenseId] = {
                value: "failure", error: action.payload
            }
        })
        .addCase(assignLicenseToUser.fulfilled, (state, action) => { state.licenses.assignmentStatus[action.meta.arg.licenseId].value = "success" })

        .addCase(revokeLicenseFromUser.pending, (state, action) => { state.licenses.revokeStatus[action.meta.arg.licenseId] = { value: "pending" } })
        .addCase(revokeLicenseFromUser.rejected, (state, action) => { state.licenses.revokeStatus[action.meta.arg.licenseId].value = "failure" })
        .addCase(revokeLicenseFromUser.fulfilled, (state, action) => {
            state.licenses.revokeStatus[action.meta.arg.licenseId].value = "success";
            state.licenses.assignmentStatus[action.meta.arg.licenseId].value = "idle";
        })

        .addCase(fetchLicenseById.fulfilled, (state, { payload: license }) => {
            if (!state.licenses.keys.includes(license.id)) {
                state.licenses.keys.push(license.id);
            }

            state.licenses.entities[license.id] = license;
        })
});

export default licensesSlice.reducer;