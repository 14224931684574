import { Subscription } from "../features/user/subscriptionsSlice"

export const getProductName = (sub: Subscription): string => {
    if (sub.productCode.includes("jangafx-suite")) return "JangaFX Suite";
    if (sub.productCode.includes("embergen")) return "EmberGen";
    if (sub.productCode.includes("vectoraygen")) return "VectorayGen";
    if (sub.productCode.includes("geogen")) return "GeoGen";
    if (sub.productCode.includes("liquigen")) return "LiquiGen";
    

    throw new Error(`Unrecognized product for code '${sub.productCode}'.`);
}

export const getProductTarget = (sub: Subscription): string => {
    if (sub.productCode.includes("indie")) return "Indie";
    if (sub.productCode.includes("studio")) return "Studio";
    if (sub.productCode.includes("education")) return "Educational";
    if (sub.productCode.includes("enterprise")) return "Enterprise";

    return "";
}

export const getRequiredMonthsToPermanentCount = (sub: Subscription): number => {
    if (sub.productCode.includes("indie")) return 18;
    if (sub.productCode.includes("studio") || sub.productCode.includes("enterprise")) return 12;
    return 18;
}