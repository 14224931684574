import "./ConfirmUser.scss";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmUserAccount, confirmUserStateSelector, resedConfirmationStateSelector, resendConfirmationCode } from "../features/user/authenticationSlice";

export const ConfirmUser = () => {
    const [reqParams, setReqParams] = useState<{ username: string } | undefined>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector(confirmUserStateSelector);
    const resendState = useSelector(resedConfirmationStateSelector);
    const [params] = useSearchParams();

    useEffect(() => {
        const username = params.get("username");
        const confirmationCode = params.get("code");

        console.debug(username, confirmationCode);

        if (username && confirmationCode) {
            dispatch(confirmUserAccount({ username: username, code: confirmationCode }));
            setReqParams({ username: username })
        } else {
            setReqParams(undefined);
        }
    }, []);

    return (
        <div>
            {state.status === "idle" && !reqParams && <p>The URL is invalid, please contact us at <a href="mailto:support@jangafx.com">support@jangafx.com</a> in order to look further into your issue.</p>}
            {state.status === "pending" && <p>Please wait while we're confirming your account...</p>}
            {state.status === "error" && reqParams && <>
                {state.error?.message && <p>{state.error.message}</p>}
                {(state.error?.code === "ExpiredCodeException" || state.error?.code === "CodeMismatchException") &&
                    <Button color="primary" disabled={resendState.status === "pending" || resendState.status === "success"}
                        onClick={() => dispatch(resendConfirmationCode({ username: reqParams?.username }))}>
                        {resendState.status !== "success" && "Resend confirmation link"}
                        {resendState.status === "success" && "Confirmation link sent!"}
                    </Button>}
                <p>Please contact us at <a href="mailto:support@jangafx.com">support@jangafx.com</a> in order to look further into your issue</p>
            </>}
            {state.status === "success" && <>
                <p>Your account has been successfuly confirmed! You can now log into your account.</p>
                <Button color="primary" onClick={() => navigate("/signin")}>Sign in</Button>
            </>}
        </div>
    )
}