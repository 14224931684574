import { useCallback, useEffect, useMemo, useRef } from "react";
import { adminApproveSubmission, adminDeleteSubmission, adminDenySubmission, adminFetchEducationIdFile, approveSubmissionStatusSelector, deleteSubmissionStatusSelector, denySubmissionStatusSelector, eduIdFileFetchStatusSelector, eduIdFileSelector, Submission } from "../../features/admin/adminEducationSlice"
import { useDispatch, useSelector } from "react-redux";

export interface AdminEducationSubmissionProps {
    disabled?: boolean;
    submission: Submission;
}

export default function ({ submission: s, disabled }: AdminEducationSubmissionProps) {
    const deleteState = useSelector(deleteSubmissionStatusSelector(s.id));
    const approvalState = useSelector(approveSubmissionStatusSelector(s.id));
    const denialState = useSelector(denySubmissionStatusSelector(s.id));
    const approveSubmissionDialogRef = useRef<HTMLDialogElement | null>(null);
    const denySubmissionDialogRef = useRef<HTMLDialogElement | null>(null);
    const deleteSubmissionDialogRef = useRef<HTMLDialogElement | null>(null);
    const msgDialogRef = useRef<HTMLDialogElement | null>(null);
    const idFileDialogRef = useRef<HTMLDialogElement | null>(null);
    const dispatch = useDispatch();
    const idFileUrl = useSelector(eduIdFileSelector(s.id));
    const idFileFetchStatus = useSelector(eduIdFileFetchStatusSelector);
    const openIdFileModal = useCallback(() => {
        console.debug(`Fetching for ${s.name}`);
        idFileDialogRef.current?.showModal();
        dispatch(adminFetchEducationIdFile({ submissionId: s.id }));
    }, [s]);

    useEffect(() => { if (denialState?.value === "success") { denySubmissionDialogRef.current?.close(); } }, [denialState?.value]);
    useEffect(() => { if (approvalState?.value === "success") { approveSubmissionDialogRef.current?.close(); } }, [approvalState?.value]);
    useEffect(() => { if (deleteState?.value === "success") { deleteSubmissionDialogRef.current?.close(); } }, [deleteState?.value]);

    const isDisabled = useMemo(() =>
        denialState?.value === "success" || denialState?.value === "pending"
        || deleteState?.value === "success" || deleteState?.value === "pending"
        || approvalState?.value === "success" || approvalState?.value === "pending", [denialState?.value, deleteState?.value, approvalState?.value]);

    return (
        <tr className={isDisabled || disabled ? "disabled" : ""}>
            <td className="center">{s.name}</td>
            <td className="center">{s.email}</td>
            <td className="center">{s.universityCourse}</td>
            <td className="center">{s.universityName} ({s.universityCity}, {s.universityCountry})</td>
            <td className="d-flex center flex-gap-small">
                <button title="Message from applicant" className="btn primary" style={{ fontSize: "1rem", borderRadius: "8px", textAlign: "center" }} onClick={() => msgDialogRef.current?.showModal()}>?</button>
                <button title="Show ID" className="btn primary" style={{ fontSize: "1rem", borderRadius: "8px", textAlign: "center" }} onClick={openIdFileModal}>🪪</button>
                <button title="Approve submission" className="btn success" style={{ fontSize: "1rem", borderRadius: "8px", textAlign: "center" }} onClick={() => approveSubmissionDialogRef.current?.showModal()}>👍</button>
                <button title="Deny submission" className="btn danger" style={{ fontSize: "1rem", borderRadius: "8px", textAlign: "center" }} onClick={() => denySubmissionDialogRef.current?.showModal()}>🚫</button>
                <button title="Delete submission" className="btn danger" style={{ fontSize: "1rem", borderRadius: "8px", textAlign: "center" }} onClick={() => deleteSubmissionDialogRef.current?.showModal()}>🗑️</button>

                <dialog ref={approveSubmissionDialogRef} >
                    <div className="d-flex flex-direction-column">
                        <p>Are you sure you want to approve this submission?<br />A coupon will be sent to the user by email once approved.</p>
                        <div className="d-flex flex-direction-row mt-1 flex-gap-small">
                            <button className="btn success" disabled={approvalState?.value === "pending"} onClick={() => { dispatch(adminApproveSubmission({ submissionId: s.id })) }}>Approve</button>
                            <button className="btn secondary" disabled={approvalState?.value === "pending"} style={{ flexGrow: "1" }} onClick={() => approveSubmissionDialogRef.current?.close()}>Cancel</button>
                        </div>
                    </div>
                </dialog>
                <dialog ref={deleteSubmissionDialogRef} >
                    <div className="d-flex flex-direction-column">
                        <p>Are you sure you want to delete this submission?</p>
                        <div className="d-flex flex-direction-row mt-1 flex-gap-small">
                            <button className="btn danger" disabled={deleteState?.value === "pending"} onClick={() => { dispatch(adminDeleteSubmission({ submissionId: s.id })) }}>Delete</button>
                            <button className="btn secondary" disabled={deleteState?.value === "pending"} style={{ flexGrow: "1" }} onClick={() => deleteSubmissionDialogRef.current?.close()}>Cancel</button>
                        </div>
                    </div>
                </dialog>
                <dialog ref={denySubmissionDialogRef} >
                    <div className="d-flex flex-direction-column">
                        <p>Are you sure you want to deny this submission?</p>
                        <div className="d-flex flex-direction-row mt-1 flex-gap-small">
                            <button className="btn danger" disabled={denialState?.value === "pending"} onClick={() => { dispatch(adminDenySubmission({ submissionId: s.id })) }}>Deny</button>
                            <button className="btn secondary" disabled={denialState?.value === "pending"} style={{ flexGrow: "1" }} onClick={() => denySubmissionDialogRef.current?.close()}>Cancel</button>
                        </div>
                    </div>
                </dialog>
                <dialog ref={idFileDialogRef} >
                    <div className="d-flex flex-direction-column">
                        {idFileFetchStatus.value === "pending" && "Fetching ID file..."}
                        {idFileFetchStatus.value === "failure" && "Couldn't find the ID file for the applicant."}
                        {idFileFetchStatus.value === "success" && <img src={idFileUrl} style={{ maxHeight: "60vh" }} />}
                        <button className="btn secondary mt-1" onClick={_ => idFileDialogRef.current?.close()}>Close</button>
                    </div>
                </dialog>
                <dialog ref={msgDialogRef}>
                    <div className="header">
                        Reason for application
                    </div>
                    <div className="content">
                        {s.reason}
                    </div>
                    <hr />
                    {s.message &&
                        <>
                            <div className="header">
                                Message from applicant
                            </div>
                            <div className="content">
                                {s.message}
                            </div>
                        </>}
                    <button className="btn secondary" style={{ maxWidth: "100px" }} onClick={_ => msgDialogRef.current?.close()}>Close</button>
                </dialog>
            </td>
        </tr>
    )
}