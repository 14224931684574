import { Auth, CognitoUser } from "@aws-amplify/auth";
import { CodeDeliveryDetails } from "amazon-cognito-identity-js";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from "..";
import { mapUserToUserInfo } from "../../utils/userHelpers";
import { setUserInfo } from "./userSlice";
import FetchStatus from "../../data/FetchStatus";

//#region Types
type LoginStatusType = "idle" | "pending" | "error" | "challenged" | "success";
type StatusType = "idle" | "pending" | "error" | "success";
type AuthenticationStateType = "idle" | "providing" | "authenticated" | "unauthenticated";
type ForgotPasswordStepType = "initial" | "confirmPassword";
type LoginChallengeType = "TOTP" | "NEW_PASSWORD";

interface LoginChallengeState {
    status: StatusType;
    error?: AuthenticationError;
}

interface LoginState {
    status: LoginStatusType;
    error?: AuthenticationError;

    challenge?: LoginChallengeType;
    challengeState: LoginChallengeState;

    /**
     * Used internally in actions and thunks, do not use outside them.
     */
    _user?: CognitoUser;
}

interface RegistrationState {
    status: StatusType;
    error?: AuthenticationError;
}

interface ForgotPasswordState {
    username: string;

    step: ForgotPasswordStepType;
    status: StatusType;
    error?: AuthenticationError;
    deliveryDetails?: CodeDeliveryDetails;
}

interface ConfirmUserState {
    status: StatusType;
    error?: AuthenticationError;
}

interface ResendConfirmationCodeState {
    status: StatusType;
    error?: AuthenticationError;
}

export interface AuthenticationState {
    authState: AuthenticationStateType;
    loginState: LoginState;
    signUpState: RegistrationState;
    forgotPasswordState: ForgotPasswordState;
    confirmUserState: ConfirmUserState;
    resendConfirmationState: ResendConfirmationCodeState;
}

export interface LoginArguments {
    username: string;
    password: string;
    mfaCode?: string;
}

export interface SignUpArguments {
    recaptchaCode: string;

    email: string;
    fullName: string;
    company?: string;

    password: string;
}

interface ConfirmPasswordForgetArguments {
    code: string;
    password: string;
}

export type AutehnticationErrorType = "UserLambdaValidationException" | "UserNotFoundException" | "ExpiredCodeException" | "UsernameExistsException" | "InvalidSession" | "CodeMismatchException" | "InvalidPasswordException" | "NotAuthorizedException" | "Unknown";

export class AuthenticationError extends Error {
    constructor(public message: string, public code?: AutehnticationErrorType) {
        super(message);
    }
}
//#endregion

const initialState: AuthenticationState = {
    authState: "providing",
    loginState: {
        status: "idle",
        challengeState: {
            status: "idle"
        }
    },
    signUpState: {
        status: "idle",
    },
    forgotPasswordState: {
        username: "",
        status: "idle",
        step: "initial",
    },
    confirmUserState: {
        status: "idle",
    },
    resendConfirmationState: {
        status: "idle"
    },
};

//#region Selectors
export const authenticationStatusSelector = (state: ApplicationState) => state.authentication.authState;
export const loginStateSelector = (state: ApplicationState) => state.authentication.loginState;
export const signUpStateSelector = (state: ApplicationState) => state.authentication.signUpState;
export const forgotPasswordStateSelector = (state: ApplicationState) => state.authentication.forgotPasswordState;
export const confirmUserStateSelector = (state: ApplicationState) => state.authentication.confirmUserState;
export const resedConfirmationStateSelector = (state: ApplicationState) => state.authentication.resendConfirmationState;
//#endregion

//#region Helpers
//#endregion

//#region Thunks
type LoginUserPayload = {
    user?: CognitoUser;

    challenge?: LoginChallengeType;
};

export const loginUser = createAsyncThunk<LoginUserPayload, LoginArguments, { rejectValue: AuthenticationError }>("user/authentication/login",
    async ({ username, password, mfaCode }, thunkApi) => {
        try {
            const user = await Auth.signIn({
                username,
                password,
            }) as CognitoUser;

            if ((user as { challengeName?: string }).challengeName === "SOFTWARE_TOKEN_MFA") {
                // User challenged to confirm TOTP token.

                return {
                    user: user,
                    challenge: "TOTP"
                };
            } else if ((user as { challengeName?: string }).challengeName === "NEW_PASSWORD_REQUIRED") {
                // User needs to set his password before being able to log in.

                return {
                    user: user,
                    challenge: "NEW_PASSWORD",
                }
            } else if ((user as { challengeName?: string }).challengeName === undefined) {
                // User hasn't been challenged.

                const info = await mapUserToUserInfo(user);
                thunkApi.dispatch(setUserInfo(info));

                return {
                    user: user,
                };
            }
        } catch (err: unknown) {
            const authError = (err as AuthenticationError);

            if (authError.code !== undefined) {
                return thunkApi.rejectWithValue(authError);
            } else {
                return thunkApi.rejectWithValue(new AuthenticationError("An unknown error occured", "Unknown"));
            }
        }

        return {
        };
    });

export interface VerifyLoginTotpArguments {
    totpCode: string;
}

export const verifyLoginTotp = createAsyncThunk<void, VerifyLoginTotpArguments, { state: ApplicationState, rejectValue: AuthenticationError }>("user/authentication/verifyLoginTotp",
    async ({ totpCode }, thunkApi) => {
        const state = thunkApi.getState();

        try {
            const user = await Auth.confirmSignIn(state.authentication.loginState._user, totpCode, "SOFTWARE_TOKEN_MFA") as CognitoUser;

            const info = await mapUserToUserInfo(user);
            thunkApi.dispatch(setUserInfo(info));
        } catch (err: unknown) {
            const authError = (err as AuthenticationError);

            if (authError.code !== undefined) {
                return thunkApi.rejectWithValue(authError);
            } else {
                return thunkApi.rejectWithValue(
                    new AuthenticationError("An unknown error occured", "Unknown"));
            }
        }
    });

export interface VerifyNewPasswordArguments {
    newPassword: string;
}

export const verifyNewPassword = createAsyncThunk<void, VerifyNewPasswordArguments, { state: ApplicationState, rejectValue: AuthenticationError }>("user/authentication/verifyNewPassword",
    async ({ newPassword }, thunkApi) => {
        const state = thunkApi.getState();

        try {
            const user = await Auth.completeNewPassword(state.authentication.loginState._user, newPassword) as CognitoUser;

            const info = await mapUserToUserInfo(user);
            thunkApi.dispatch(setUserInfo(info));
        } catch (err: unknown) {
            const authError = (err as AuthenticationError);

            if (authError.code !== undefined) {
                return thunkApi.rejectWithValue(authError);
            } else {
                return thunkApi.rejectWithValue(
                    new AuthenticationError("An unknown error occured", "Unknown"));
            }
        }
    });

export const signUpUser = createAsyncThunk<void, SignUpArguments, { rejectValue: AuthenticationError }>("user/authentication/signup", async ({ password, email, fullName, company, recaptchaCode }, thunkApi) => {
    try {
        const result = await Auth.signUp({
            username: email,
            password: password,
            attributes: {
                "email": email,
                "name": fullName,
                "custom:company": company,
            },
            validationData: {
                "recaptchaCode": recaptchaCode
            },
        });

        /* result.user.getUserData((err, data) => {
            thunkApi.dispatch(setUserData(data));
        }); */
    } catch (err: unknown) {
        const authError = (err as AuthenticationError);

        if (authError.code === "UsernameExistsException") {
            return thunkApi.rejectWithValue(
                new AuthenticationError("The username is already taken.", "UsernameExistsException"));
        }
        else if (authError.code !== undefined) {
            return thunkApi.rejectWithValue(authError);
        } else {
            return thunkApi.rejectWithValue(
                new AuthenticationError("An unknown error occured", "Unknown"));
        }
    }
});

export const signOutUser = createAsyncThunk<void, void, { rejectValue: AuthenticationError }>("user/authentication/signOut", async (_, thunkApi) => {
    try {
        await Auth.signOut();
    } catch (error: unknown) {
        const authError = (error as AuthenticationError);

        if (authError.code !== undefined) {
            return thunkApi.rejectWithValue(authError);
        } else {
            return thunkApi.rejectWithValue(
                new AuthenticationError("An unknown error occured", "Unknown"));
        }
    }
});

export const provideExistingSession = createAsyncThunk<void, void, { rejectValue: AuthenticationError }>("user/authentication/provideExistingSession", async (_, thunkApi) => {
    try {
        const session = await Auth.currentSession();
        if (!session.isValid()) {
            return thunkApi.rejectWithValue(
                new AuthenticationError("Authentication session is not valid.", "InvalidSession"));
        }

        const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;

        const info = await mapUserToUserInfo(user);
        thunkApi.dispatch(setUserInfo(info));
    } catch (err: unknown) {
        const authError = (err as AuthenticationError);

        if (authError.code !== undefined) {
            return thunkApi.rejectWithValue(authError);
        } else {
            return thunkApi.rejectWithValue(
                new AuthenticationError("An unknown error occured", "Unknown"));
        }
    }
});

export const initPasswordForget = createAsyncThunk<{ username: string, details: CodeDeliveryDetails }, { username: string }, { rejectValue: AuthenticationError }>("user/authentication/initPasswordForget", async ({ username }, thunkApi) => {
    try {
        const details = await Auth.forgotPassword(username) as CodeDeliveryDetails;

        return {
            username,
            details,
        };
    } catch (err: unknown) {
        const authError = (err as AuthenticationError);
        
        if (authError.code !== undefined) {
            return thunkApi.rejectWithValue(authError);
        } else {
            return thunkApi.rejectWithValue(
                new AuthenticationError("An unknown error occured", "Unknown"));

        }
    }
});

export const confirmPasswordForget = createAsyncThunk<void, ConfirmPasswordForgetArguments, { rejectValue: AuthenticationError, state: ApplicationState }>("user/authentication/confirmPasswordForget", async ({ code, password }, thunkApi) => {
    try {
        const { authentication: { forgotPasswordState: { username } } } = thunkApi.getState();

        const result = await Auth.forgotPasswordSubmit(username, code, password);
    } catch (err: unknown) {
        const authError = (err as AuthenticationError);

        if (authError.code !== undefined) {
            return thunkApi.rejectWithValue(authError);
        } else {
            return thunkApi.rejectWithValue(
                new AuthenticationError("An unknown error occured", "Unknown"));

        }
    }
});

export const confirmUserAccount = createAsyncThunk<void, { username: string, code: string }, { rejectValue: AuthenticationError, state: ApplicationState }>("user/authentication/confirmUserAccount",
    async ({ code, username }, thunkApi) => {
        try {
            await Auth.confirmSignUp(username, code);
        } catch (err: unknown) {
            const authError = (err as AuthenticationError);

            if (authError.code !== undefined) {
                return thunkApi.rejectWithValue(authError);
            } else {
                return thunkApi.rejectWithValue(
                    new AuthenticationError("An unknown error occured", "Unknown"));

            }
        }
    });

export const resendConfirmationCode = createAsyncThunk<void, { username: string }, { rejectValue: AuthenticationError, state: ApplicationState }>("user/authentication/resendConfirmationCode",
    async ({ username }, thunkApi) => {
        try {
            await Auth.resendSignUp(username);
        } catch (err: unknown) {
            const authError = (err as AuthenticationError);

            if (authError.code !== undefined) {
                return thunkApi.rejectWithValue(authError);
            } else {
                return thunkApi.rejectWithValue(
                    new AuthenticationError("An unknown error occured", "Unknown"));

            }
        }
    });
//#endregion

const authenticationSlice = createSlice({
    name: "user/authentication",
    initialState: initialState,
    reducers: {
        setAuthStatus(state, action: PayloadAction<AuthenticationStateType>) {
            state.authState = action.payload;
        },
        resetForgotPasswordState(state) {
            state.forgotPasswordState = {
                status: "idle",
                step: "initial",
                username: "",
            };
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loginUser.pending, (state, action) => {
                state.loginState.status = "pending";
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loginState = {
                    ...state.loginState,
                    status: "error",
                    error: action.payload,
                };
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loginState.status = "success";
                state.loginState._user = action.payload.user;

                if (action.payload.challenge === "TOTP") {
                    state.loginState.challenge = "TOTP";
                    state.loginState.status = "challenged";
                } else if (action.payload.challenge === "NEW_PASSWORD") {
                    state.loginState.challenge = "NEW_PASSWORD";
                    state.loginState.status = "challenged";
                } else {
                    state.authState = "authenticated";
                }
            })

            .addCase(verifyLoginTotp.pending, (state, action) => {
                state.loginState.challengeState = {
                    status: "pending"
                };
            })
            .addCase(verifyLoginTotp.rejected, (state, action) => {
                state.loginState.challengeState = {
                    status: "error",
                    error: action.payload
                }
            })
            .addCase(verifyLoginTotp.fulfilled, (state, action) => {
                state.loginState.challengeState = {
                    status: "success"
                };

                state.loginState.status = "success";
                state.loginState.error = undefined;

                state.authState = "authenticated";
            })

            .addCase(verifyNewPassword.pending, (state, action) => {
                state.loginState.challengeState = {
                    status: "pending"
                };
            })
            .addCase(verifyNewPassword.rejected, (state, action) => {
                state.loginState.challengeState = {
                    status: "error",
                    error: action.payload
                }
            })
            .addCase(verifyNewPassword.fulfilled, (state, action) => {
                state.loginState.challengeState = {
                    status: "success"
                };

                state.loginState.status = "success";
                state.loginState.error = undefined;

                state.authState = "authenticated";
            })

            .addCase(signOutUser.pending, (state, action) => {
                // state.loginState.status = "pending";
            })
            .addCase(signOutUser.rejected, (state, action) => {
                // state.loginState.status = "error";
            })
            .addCase(signOutUser.fulfilled, (state, action) => {
                state.loginState = {
                    status: "idle",
                    challengeState: {
                        status: "idle"
                    }
                };

                state.signUpState.status = "idle";

                state.authState = "unauthenticated";
            })

            .addCase(provideExistingSession.pending, (state, action) => {
                state.authState = "providing";
            })
            .addCase(provideExistingSession.rejected, (state, action) => {
                state.authState = "unauthenticated";
            })
            .addCase(provideExistingSession.fulfilled, (state, action) => {
                state.authState = "authenticated";
            })

            .addCase(initPasswordForget.pending, (state, action) => {
                state.forgotPasswordState = {
                    ...state.forgotPasswordState,
                    status: "pending",
                    step: "initial",
                };
            })
            .addCase(initPasswordForget.rejected, (state, action) => {
                state.forgotPasswordState = {
                    ...state.forgotPasswordState,
                    status: "error",
                    error: action.payload,
                    step: "initial",
                };
            })
            .addCase(initPasswordForget.fulfilled, (state, action) => {
                state.forgotPasswordState = {
                    status: "idle",
                    step: "confirmPassword",
                    username: action.payload.username,
                    deliveryDetails: action.payload.details,
                };
            })

            .addCase(confirmPasswordForget.pending, (state, action) => {
                state.forgotPasswordState.status = "pending";
            })
            .addCase(confirmPasswordForget.rejected, (state, action) => {
                state.forgotPasswordState = {
                    ...state.forgotPasswordState,
                    status: "error",
                    error: action.payload,
                };
            })
            .addCase(confirmPasswordForget.fulfilled, (state, action) => {
                state.forgotPasswordState.status = "success";
            })

            .addCase(signUpUser.pending, (state, action) => {
                state.signUpState.status = "pending";
                state.signUpState.error = undefined;
            })
            .addCase(signUpUser.rejected, (state, action) => {
                state.signUpState.status = "error";
                state.signUpState.error = action.payload;
            })
            .addCase(signUpUser.fulfilled, (state, action) => {
                state.signUpState = {
                    status: "success",
                }

                state.authState = "unauthenticated";
            })

            .addCase(confirmUserAccount.pending, (state, action) => {
                state.confirmUserState.status = "pending";
                state.confirmUserState.error = undefined;
            })
            .addCase(confirmUserAccount.rejected, (state, action) => {
                state.confirmUserState.status = "error";
                state.confirmUserState.error = action.payload;
            })
            .addCase(confirmUserAccount.fulfilled, (state, action) => { state.confirmUserState = { status: "success" } })

            .addCase(resendConfirmationCode.pending, (state) => { state.resendConfirmationState = { status: "pending" } })
            .addCase(resendConfirmationCode.rejected, (state, { payload }) => { state.resendConfirmationState = { status: "error", error: payload } })
            .addCase(resendConfirmationCode.fulfilled, (state) => { state.resendConfirmationState = { status: "success" } })
    }
});

export const { setAuthStatus } = authenticationSlice.actions;

export default authenticationSlice.reducer;