import { License } from "../../features/user/licensesSlice";
import embergenLogo from "../../assets/media/logos/embergen.png";
import vectoraygenLogo from "../../assets/media/logos/vectoraygen.png";
import suiteLogo from "../../assets/media/logos/suite.png";
import iconHide from "../../assets/media/icons/hide.svg";
import iconShow from "../../assets/media/icons/show.svg";
import "./LicenseCard.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getHumanizedTimeSpan } from "../../utils/dateHelpers";

interface LicenseCardProps {
    license: License;
}

const getObfuscatedKey = (key: string) => {
    const parts = key.split("-");
    parts.slice(0, parts.length - 1).forEach((v, i) => parts[i] = v.replace(/./g, "*"));

    return parts.join("-");
};

const LicenseCard = ({ license }: LicenseCardProps) => {
    const [isKeyVisible, setKeyVisible] = useState(false);
    const [obfKey, setObfKey] = useState(getObfuscatedKey(license.key));

    const onVisibilityToggled = useCallback(() => {
        setKeyVisible(!isKeyVisible);
    }, [isKeyVisible]);

    useEffect(() => {
        if (isKeyVisible) {
            setObfKey(license.key);
        } else {
            setObfKey(getObfuscatedKey(license.key));
        }
    }, [isKeyVisible, license.key]);

    const expirationDaysLeft = useMemo<string>(() => {
        if (license.expiresAt === undefined) return "-";
        const edt = new Date(license.expiresAt).getTime();
        const ndt = new Date().getTime();
        const diff = edt - ndt;
        if (diff <= 0) return "Expired";

        return getHumanizedTimeSpan(diff);
    }, [license.expiresAt]);

    return (
        <div className="jfx-licenseCard">
            <img src={getSoftwareLogoSrc(license.productCode)} alt="Product Logo" />
            <div>
                <label>License Key</label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <code>{obfKey}</code>
                    <img src={isKeyVisible ? iconShow : iconHide} alt="Toggle Key Visibility" style={{ marginLeft: "10px", cursor: "pointer" }} onClick={onVisibilityToggled} />
                </div>
            </div>
            <div>
                <label>Purchase date</label><br />
                <span>{license.purchasedAt}</span>
            </div>
            <div>
                <label>Expires in</label><br />
                <span>{expirationDaysLeft}</span>
            </div>
        </div>
    );
};

const getSoftwareLogoSrc = (productCode: string) => {
    if (productCode.includes("embergen")) return embergenLogo;
    if (productCode.includes("vectoraygen")) return vectoraygenLogo;
    if (productCode.includes("suite")) return suiteLogo;

    throw new Error(`Could not find logo for software with code ${productCode}`);
};

export default LicenseCard;