import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApplicationRoles from "../data/ApplicationRoles";
import { UserInfo, userInfoSelector } from "../features/user/userSlice";
import { Navigate, useNavigate } from "react-router-dom";
import React from "react";

interface ProtectedViewProps {
    userInfo?: UserInfo;
    children: JSX.Element;
    groups: ApplicationRoles[];
}

const ProtectedView = ({ userInfo, children, groups }: ProtectedViewProps) => {
    if (groups.length === 0) {
        throw new Error(`'groups' property cannot be empty in ${ProtectedView.name}`);
    }

    let userHasAccess = false;
    for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        userHasAccess = userInfo?.groups.includes(group) ?? false;
        if (userHasAccess) break;
    }

    if (!userHasAccess || !userInfo) {
        return (
            <React.Suspense fallback={<></>}>
                <Navigate to={"/"} replace />;
            </React.Suspense>
        )
    }

    return (
        <React.Suspense fallback={<></>}>
            {children}
        </React.Suspense>
    )
};

export default ProtectedView;