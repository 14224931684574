import { Auth } from "@aws-amplify/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
import { DefaultProfilePictureUrl } from "../appConstants";
import { UserInfo } from "../features/user/userSlice";

export const mapUserToUserInfo = async (user: CognitoUser) => {
    const session = await Auth.userSession(user);
    const idToken = session.getIdToken();

    const attrs = await Auth.userAttributes(user);
    const username = user.getUsername();

    const preferredMfa = await Auth.getPreferredMFA(user);

    const info: UserInfo = {
        username: username,
        id: attrs.filter(a => a.Name === "sub")?.[0]?.Value ?? "Unknown User ID",
        profilePictureUrl: attrs.filter(a => a.Name === "picture")?.[0]?.Value ?? DefaultProfilePictureUrl,
        email: attrs.filter(a => a.Name === "email")?.[0]?.Value ?? "Unknown Email",
        emailVerified: attrs.filter(a => a.Name === "email_verified")?.[0]?.Value.toLowerCase() === "true" ? true : false,
        name: attrs.filter(a => a.Name === "name")?.[0]?.Value ?? "Unknown Name",
        groups: idToken.payload?.["cognito:groups"] as string[] ?? [],
        company: attrs.filter(a => a.Name === "custom:company")?.[0]?.Value,
        mfaEnabled: preferredMfa !== "NOMFA",

        // Non-cognito fields
        customData: {
            lastReloadedSubscriptionsAt: null,
            canReloadSubscriptions: false,
        },
        accountLinks: { discord: false },
    };

    return info;
}