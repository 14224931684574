import { DiscordSocialLinkButton } from "../../shared/DiscordSocialLinkButton";
import "./AccountLinkingPage.scss";

// export interface AccountLinkingPageProps {

// }

export const AccountLinkingPage = () => {
    return (
        <div className="p-2">
            <h3>Account Linking</h3>
            <p className="muted">Connect your JangaFX account to other platforms and earn various benefits.</p>
            <hr/>
            <div id="socialContainer">
                <DiscordSocialLinkButton />
            </div>
        </div>
    )
};