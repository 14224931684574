import { applyMiddleware, createSerializableStateInvariantMiddleware, createStore } from "@reduxjs/toolkit";
import rootReducer from './features';
import thunkMiddleware from "redux-thunk";

const serializableMiddleware = createSerializableStateInvariantMiddleware({
    ignoredPaths: [
        "authentication.loginState._user",
    ],
    ignoredActionPaths: [
        "payload.user",
        "meta.arg.profilePicture",
        "meta.arg"
    ]
});

const composedEnhancer = applyMiddleware(thunkMiddleware, serializableMiddleware);

const store = createStore(rootReducer, composedEnhancer);

export default store;