import "./Dropdown.scss";
import React from "react";
import iconArrowDown from "../assets/media/icons/arrow_head_down.svg";
import iconArrowUp from "../assets/media/icons/arrow_head_up.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { useWindowSize } from "../hooks/uiHooks";
import { useVoidKeyCapture } from "../utils/uiHelpers";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Dropdown {
    interface ContentProps {
        children: JSX.Element[];
        isVisible: boolean;
    }

    const Content = React.forwardRef<HTMLDivElement, ContentProps>(({ isVisible, children }, ref) => {
        return (
            <div ref={ref} className={`content ${!isVisible ? "hidden" : ""}`}>
                {children}
            </div>
        );
    });

    interface HeaderProps {
        children: JSX.Element | string;
        isExpanded: boolean;
        onToggleClicked: () => void;
    }

    const Header = React.forwardRef<HTMLDivElement, HeaderProps>(({ isExpanded, children, onToggleClicked }, ref) => {
        return (
            <div ref={ref} className="header" onClick={onToggleClicked}>
                {children}

                {/**
                  * If you're moving this button to another level in the hierarchy, you must handle the change in `onOutsideClickCaptured` of the parent Dropdown.Container.
                  */}
                <button className="toggle">
                    <img src={isExpanded ? iconArrowUp : iconArrowDown} style={{ pointerEvents: "none" }} alt="Expand/Collapse Dropdown" />
                </button>
            </div>
        );
    });

    export interface DropdownItem {
        disabled?: boolean;
        value: string | JSX.Element;
        onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    }

    export interface ContainerProps {
        header: string | JSX.Element;
        elements: DropdownItem[];
    }

    export const Container = ({ header, elements }: ContainerProps) => {
        const [isExpanded, setExpanded] = useState(false);
        const headerRef = useRef<HTMLDivElement>(null);
        const windowSize = useWindowSize();

        const onVoidKeyCaptured = useCallback((key: string) => {
            if (key === "Escape") {
                setExpanded(false);
            }
        }, []);

        const onOutsideClickCaptured = useCallback((event: MouseEvent) => {
            if ((event.target as HTMLElement)?.parentElement !== headerRef.current) {
                setExpanded(false);
            }
        }, []);

        const { ref: contentRef } = useVoidKeyCapture(onVoidKeyCaptured, onOutsideClickCaptured, ["Escape"]);

        // Adjust container position relative to the header
        useEffect(() => {
            if (contentRef.current !== null && headerRef.current != null) {
                const headerRect = headerRef.current.getBoundingClientRect();
                const contentRect = contentRef.current.getBoundingClientRect();

                const right = window.innerWidth - headerRect.right + 5;
                contentRef.current.style.top = `${headerRect.bottom}px`;
                contentRef.current.style.right = `${right}px`;
                contentRef.current.style.minWidth = `${headerRect.width}px`;
            }
        }, [contentRef, headerRef, windowSize]);

        const onToggleClicked = useCallback(() => {
            setExpanded(!isExpanded);
        }, [isExpanded]);

        const elementOnClick = useCallback((element: DropdownItem, ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setExpanded(false);
            element.onClick(ev);
        }, []);

        return (
            <div className="dropdown">
                <Header ref={headerRef} onToggleClicked={onToggleClicked} isExpanded={isExpanded}>
                    {header}
                </Header>
                <Content ref={contentRef} isVisible={isExpanded}>
                    {elements.map(e => {
                        return (
                            <div key={e.value.toString()}
                                className={`item ${e.disabled ? "disabled" : ""}`}
                                onClick={ev => e.disabled ? null : elementOnClick(e, ev)}>
                                {e.value}
                            </div>
                        )
                    })}
                </Content>
            </div>
        )
    };
}

export default Dropdown;