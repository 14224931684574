import { Navigate } from "react-router-dom";
import useAuthenticator from "../features/user/authentication";
import AuthenticationLayout from "./AuthenticationLayout";

interface RequireAuthProps {
    children: JSX.Element;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
    const { status } = useAuthenticator();

    if (status === "providing" || status === "idle") return <AuthenticationLayout />

    return status === "authenticated" ? children : <Navigate to={"/signin"} />;
};

export default RequireAuth;