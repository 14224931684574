import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import FetchStatus from "../../data/FetchStatus";
import { ApplicationState, ValidationProblemDetails } from "..";

export interface AdminEducationState {
    fetchStatus: FetchStatus;
    currSubmissions: PaginatedFetchResponse<Submission>;
    idFilesUrls: { [submissionId: string]: string };
    idFileFetchStatus: { [submissionId: string]: FetchStatus };

    statistics: Statistics;
    statisticsFetchStatus: FetchStatus;

    denialStatus: { [submissionId: string]: FetchStatus };
    approvalStatus: { [submissionId: string]: FetchStatus };
    deleteStatus: { [submissionId: string]: FetchStatus };
}

export interface Statistics {
    generatedAt: string;

    pendingSubmissions: number;
    totalSubmissions: number;
    approvedRatio: number;
    last7DaysSubmissions: number;
    last30DaysSubmissions: number;
}

export interface PageInfo {
    perPageCount: number;
    page: number;
    totalPages: number;
    nextPage?: number;
    previousPage?: number;
}

export interface Submission {
    id: string;
    name: string;
    email: string;
    message?: string;
    universityName: string;
    universityCity: string;
    universityCountry: string;
    universityCourse: string;
    reason: string;
    identificationUrl: string;
    approvedAt?: string;
    doneAt?: string;
    submissionType: EducationSubmissionType;
    submissionStatus: EducationSubmissionStatus;
    createdAt?: string;
    couponCode?: string;
}

export enum EducationSubmissionStatus {
    Pending = 0,
    Approved = 1,
    Denied = 2
}

export enum EducationSubmissionType {
    Student = 1,
    FacultyMembers = 2,
    University = 3
}

export interface PaginatedFetchResponse<TData> {
    data: TData[];
    pageInfo: PageInfo;
}

/**
 * Thunks
 */
export const adminFetchSubmissions = createAsyncThunk<PaginatedFetchResponse<Submission>, { page: number, count: number, status: EducationSubmissionStatus, type: EducationSubmissionType }, { rejectValue: ValidationProblemDetails | undefined }>("admin/education/fetchSubmissions",
    async ({ count, page, status, type }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/education/admin/submissions?perPage=${count}&page=${page}&status=${status}&type=${type}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                const r = (await resp.json()) as PaginatedFetchResponse<Submission>;
                return r;
            } else {
                return thunkApi.rejectWithValue(undefined);
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const adminDenySubmission = createAsyncThunk<void, { submissionId: string }, { rejectValue: ValidationProblemDetails | undefined }>("admin/education/submissions/deny",
    async ({ submissionId }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/education/admin/submissions/${submissionId}/deny`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain"
                }
            });

            if (resp.ok) {
                return;
            } else {
                return thunkApi.rejectWithValue(undefined);
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const adminApproveSubmission = createAsyncThunk<void, { submissionId: string }, { rejectValue: ValidationProblemDetails | undefined }>("admin/education/submissions/approve",
    async ({ submissionId }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/education/admin/submissions/${submissionId}/approve`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain"
                }
            });

            if (resp.ok) {
                thunkApi.dispatch
                return;
            } else {
                return thunkApi.rejectWithValue(undefined);
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const adminFetchEducationIdFile = createAsyncThunk<string, { submissionId: string }, { rejectValue: ValidationProblemDetails | undefined }>("admin/education/submissions/fetchIdFile",
    async ({ submissionId: sid }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/education/admin/submissions/${sid}/idfile`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain"
                }
            });

            if (resp.ok) {
                const blob = await resp.blob();
                return URL.createObjectURL(blob);
            } else {
                return thunkApi.rejectWithValue(undefined);
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const adminDeleteSubmission = createAsyncThunk<void, { submissionId: string }, { rejectValue: ValidationProblemDetails | undefined }>("admin/education/submissions/delete",
    async ({ submissionId: sid }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/education/admin/submissions/${sid}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain"
                }
            });

            if (resp.ok) {
                return;
            } else {
                return thunkApi.rejectWithValue(undefined);
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const adminFetchStatistics = createAsyncThunk<Statistics, void, { rejectValue: ValidationProblemDetails | undefined }>("admin/education/fetchStatistics",
    async (_, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/education/admin/stats`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                const r = (await resp.json()) as Statistics;
                return r;
            } else {
                return thunkApi.rejectWithValue(undefined);
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

/**
 * Setup
 */
const initialState: AdminEducationState = {
    currSubmissions: {
        data: [],
        pageInfo: {
            page: 0,
            perPageCount: 0,
            totalPages: 0,
        }
    },
    fetchStatus: { value: "idle" },

    idFileFetchStatus: {},
    idFilesUrls: {},

    statistics: {
        approvedRatio: 0,
        generatedAt: new Date().toString(),
        last30DaysSubmissions: 0,
        last7DaysSubmissions: 0,
        pendingSubmissions: 0,
        totalSubmissions: 0
    },
    statisticsFetchStatus: { value: "idle" },

    denialStatus: {},
    approvalStatus: {},
    deleteStatus: {},
}

/**
 * Selectors
 */
export const submissionsSelector = (state: ApplicationState) => state.adminEducationState.currSubmissions;
export const submissionsFetchStatusSelector = (state: ApplicationState) => state.adminEducationState.fetchStatus;
export const eduIdFileFetchStatusSelector = (submissionId: string) => (state: ApplicationState) => state.adminEducationState.idFileFetchStatus?.[submissionId];
export const eduIdFileSelector = (submissionId: string) => (state: ApplicationState) => state.adminEducationState.idFilesUrls?.[submissionId];
export const denySubmissionStatusSelector = (submissionId: string) => (state: ApplicationState): FetchStatus | undefined => state.adminEducationState.denialStatus?.[submissionId];
export const approveSubmissionStatusSelector = (submissionId: string) => (state: ApplicationState): FetchStatus | undefined => state.adminEducationState.approvalStatus?.[submissionId];
export const deleteSubmissionStatusSelector = (submissionId: string) => (state: ApplicationState): FetchStatus | undefined => state.adminEducationState.deleteStatus?.[submissionId];
export const statisticsSelector = (state: ApplicationState) => state.adminEducationState.statistics;
export const statisticsFetchStatusSelector = (state: ApplicationState) => state.adminEducationState.statisticsFetchStatus;

const adminEducationSlice = createSlice({
    name: "admin/adminUsers",
    reducers: {
        setIdFileFetchStatus: (state, action: PayloadAction<string>) => {
            state.idFileFetchStatus[action.payload] = { value: "idle" };
        }
    },
    initialState: initialState,
    extraReducers: builder => builder
        .addCase(adminFetchSubmissions.fulfilled, (state, { payload }) => {
            state.currSubmissions = payload;
            state.fetchStatus = { value: "success" };
        })
        .addCase(adminFetchSubmissions.pending, (state) => {
            state.fetchStatus = { value: "pending" };
        })
        .addCase(adminFetchSubmissions.rejected, (state, { payload }) => {
            state.fetchStatus = { value: "failure", error: payload };
        })

        .addCase(adminFetchStatistics.fulfilled, (state, { payload }) => {
            state.statistics = payload;
            state.statisticsFetchStatus = { value: "success" };
        })
        .addCase(adminFetchStatistics.pending, (state) => {
            state.statisticsFetchStatus = { value: "pending" };
        })
        .addCase(adminFetchStatistics.rejected, (state, { payload }) => {
            state.statisticsFetchStatus = { value: "failure", error: payload };
        })

        .addCase(adminFetchEducationIdFile.fulfilled, (state, { payload, meta }) => {
            state.idFilesUrls[meta.arg.submissionId] = payload;
            state.idFileFetchStatus[meta.arg.submissionId] = { value: "success" };
        })
        .addCase(adminFetchEducationIdFile.pending, (state, { meta }) => {
            state.idFileFetchStatus[meta.arg.submissionId] = { value: "pending" };
        })
        .addCase(adminFetchEducationIdFile.rejected, (state, { payload, meta }) => {
            state.idFileFetchStatus[meta.arg.submissionId] = { value: "failure", error: payload };
        })

        .addCase(adminDenySubmission.fulfilled, (state, { meta }) => {
            state.denialStatus[meta.arg.submissionId] = { value: "success" };
        })
        .addCase(adminDenySubmission.pending, (state, { meta }) => {
            state.denialStatus[meta.arg.submissionId] = { value: "pending" };
        })
        .addCase(adminDenySubmission.rejected, (state, { meta }) => {
            state.denialStatus[meta.arg.submissionId] = { value: "failure" };
        })

        .addCase(adminApproveSubmission.fulfilled, (state, { meta }) => {
            state.approvalStatus[meta.arg.submissionId] = { value: "success" };
        })
        .addCase(adminApproveSubmission.pending, (state, { meta }) => {
            state.approvalStatus[meta.arg.submissionId] = { value: "pending" };
        })
        .addCase(adminApproveSubmission.rejected, (state, { meta }) => {
            state.approvalStatus[meta.arg.submissionId] = { value: "failure" };
        })

        .addCase(adminDeleteSubmission.fulfilled, (state, { meta }) => {
            state.deleteStatus[meta.arg.submissionId] = { value: "success" };
        })
        .addCase(adminDeleteSubmission.pending, (state, { meta }) => {
            state.deleteStatus[meta.arg.submissionId] = { value: "pending" };
        })
        .addCase(adminDeleteSubmission.rejected, (state, { meta }) => {
            state.deleteStatus[meta.arg.submissionId] = { value: "failure" };
        })
});

export const { setIdFileFetchStatus } = adminEducationSlice.actions;
export default adminEducationSlice.reducer;