import { useDispatch, useSelector } from "react-redux";
import { Subscription, switchMonthlyToAnnual, switchMonthlyToAnnualStateSelector } from "../../features/user/subscriptionsSlice";
import "./SwitchMonthlyToAnnualCard.scss";

interface SwitchMonthlyToAnnualCardProps {
    sub: Subscription;
}

export const SwitchMonthlyToAnnualCard = ({ sub }: SwitchMonthlyToAnnualCardProps) => {
    const dispatch = useDispatch();
    const switchStatus = useSelector(switchMonthlyToAnnualStateSelector(sub.id));
    return (
        <div className="card">
            <div className="header">
                {/* <img src={iconBillPaper} /> */}
                <h4 className="muted">Switch to Annual Maintenance</h4>
            </div>
            <div id="switchMonthlyToAnnualCardContent">
                <p>You have reached the permanent milestone. You can continue paying maintenance monthly like currently, or you can save up to 35% by switching to an annual maintenance plan.</p>
                <button className="btn primary" disabled={switchStatus?.value === "pending"}
                    onClick={() => dispatch(switchMonthlyToAnnual({ subId: sub.id }))}>
                    {switchStatus?.value === "pending" && "Switching payment plan..."}
                    {switchStatus?.value !== "pending" && "Switch to Annual Maintnance"}
                </button>
            </div>
        </div>
    )
}

export default SwitchMonthlyToAnnualCard;