import { useEffect, useState } from "react";

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const onWindowsResized = (event: UIEvent) => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener("resize", onWindowsResized);

        return () => window.removeEventListener("resize", onWindowsResized);
    }, []);

    return windowSize;
};