import { useEffect, useMemo, useRef, useState } from "react";
import { SoftwareCode, SoftwareRelease, fetchSoftwareReleases, releasesFetchStatusSelector, softwareReleasesSelector } from "../../features/softwareReleasesSlice";
import "./HomePage.scss";
import { useDispatch, useSelector } from "react-redux";
import { getErrorsCompiled, getSoftwareLogoSrc } from "../../utils/uiHelpers";
import Selector, { SelectorItem } from "../Selector";
import Markdown from "react-markdown";
import iconWindowsSrc from "../../assets/media/icons/windows-white.svg";
import iconLinuxsSrc from "../../assets/media/icons/linux-white.svg";
import iconMacosSrc from "../../assets/media/icons/macos-gray.svg";
import Dropdown from "../Dropdown";
import { getProductData } from "../../utils/productHelper";

export const HomePage = () => {
    const dispatch = useDispatch();
    const releases = useSelector(softwareReleasesSelector);
    const releasesFetchStatus = useSelector(releasesFetchStatusSelector);

    useEffect(() => {
        // if (releasesFetchStatus.value !== "success") {
        dispatch(fetchSoftwareReleases());
        // }
    }, []);

    return (
        <div id="homePage">
            <div className="header">
                <h2>Downloads</h2>
                <p className="muted">Download any available version of our software right below.</p>
            </div>
            <hr className="big" />
            <div id="downloadCardsContainer">
                {releasesFetchStatus.value === "success"
                    && Object.keys(releases).map(k =>
                        releases[k as SoftwareCode] ? <DownloadCard softwareCode={k as SoftwareCode} releases={releases[k as SoftwareCode] ?? []} /> : <TryOutCard softwareCode={k as SoftwareCode} />)}
                {releasesFetchStatus.value === "failure" &&
                    getErrorsCompiled(releasesFetchStatus.error).map(err => <p className="danger">{err}</p>)}
                {releasesFetchStatus.value === "pending" && <p className="warning">We're browsing available downloads, hold on tight!</p>}
            </div>
        </div>
    );
};

const binariesCodeDisplayMap = {
    "amd64": "x86-64 bit"
}

export default HomePage;

interface TryOutCardProps {
    softwareCode: SoftwareCode;
}

const TryOutCard = ({ softwareCode }: TryOutCardProps) => {
    return (
        <div className="card-download">
            <img className="logo" src={getSoftwareLogoSrc(softwareCode)} />
            <a className="btn primary w-100" href="https://jangafx.com/" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 19 18" fill="none">
                    <path d="M2.5 12V13C2.5 14.1046 3.39543 15 4.5 15H14.5C15.6046 15 16.5 14.1046 16.5 13V12" stroke="white" stroke-width="2" stroke-linecap="round" />
                    <path d="M9.5 2V10" stroke="white" stroke-width="2" stroke-linecap="round" />
                    <path d="M5.5 7L9.42929 10.9293C9.46834 10.9683 9.53166 10.9683 9.57071 10.9293L13.5 7" stroke="white" stroke-width="2" stroke-linecap="round" />
                </svg>
                Try out
            </a>
        </div>
    );
};

interface DownloadCardProps {
    softwareCode: SoftwareCode;
    releases: SoftwareRelease[];
}

const DownloadCard = ({ releases, softwareCode }: DownloadCardProps) => {
    const rnDialogRef = useRef<HTMLDialogElement>(null);
    const [currRelease, setCurrRelease] = useState<SoftwareRelease>(releases[0]);
    const [activeOS, setActiveOS] = useState<OperatingSystem>(getDeviceOS());
    const [activeBin, setActiveBin] = useState<string>("");

    const osItems = useMemo<SelectorItem<OperatingSystem>[]>(() => {
        const items: SelectorItem<OperatingSystem>[] = [];
        if (!currRelease) return [];

        if (Object.keys(currRelease?.binariesUrls).find(v => v.includes("windows"))) {
            items.push({ content: <img alt="Windows" src={iconWindowsSrc} height={24} />, label: "windows" });
        }
        if (Object.keys(currRelease?.binariesUrls).find(v => v.includes("linux"))) {
            items.push({ content: <img alt="Linux" src={iconLinuxsSrc} height={24} />, label: "linux" });
        }
        if (Object.keys(currRelease?.binariesUrls).find(v => v.includes("macos"))) {
            items.push({ content: <img alt="MacOS" src={iconMacosSrc} height={24} />, label: "macos" });
        }

        return items;
    }, [currRelease]);

    useEffect(() => {
        if (!osItems.find(i => i.label === getDeviceOS()) && osItems.length > 0) {
            setActiveOS(osItems[0].label);
        }
    }, [osItems]);

    const platformBins = useMemo(() => {
        if (!currRelease || !activeOS) return [];
        const binsCodes: string[] = [];
        const platformBins = Object.keys(currRelease.binariesUrls).filter(k => k.includes(activeOS.toString()));
        for (let i = 0; i < platformBins.length; i++) {
            binsCodes.push(platformBins[i].split("-").slice(1, undefined).join("-"))
        }
        return binsCodes;
    }, [activeOS, currRelease]);

    useEffect(() => {
        if (platformBins.length > 0) {
            setActiveBin(platformBins[0]);
        }
    }, [platformBins, currRelease]);

    return (
        <div className="card-download">
            <dialog className="releasenotes" ref={rnDialogRef}>
                <div>
                    <div className="header">
                        <img src={getProductData(softwareCode).logoSrc} />
                        {getProductData(softwareCode).header}
                        <p>
                            {currRelease.version}
                            {currRelease?.releasedAt ? <span style={{ opacity: 0.5, marginLeft: 8, fontSize: 14 }}>{new Date(currRelease.releasedAt).toDateString()}</span> : <></>}
                        </p>
                    </div>
                    <div className="content">
                        <Markdown>{currRelease.localizedReleaseNotes?.["en"] ?? "No release notes available"}</Markdown>
                    </div>
                    <button className="btn tertiary" onClick={() => rnDialogRef.current?.close()}>Close</button>
                </div>
            </dialog>
            <div className="d-flex flex-direction-row align-items-center header">
                <img className="logo mr-1" src={getProductData(softwareCode).logoSrc} />
                {getProductData(softwareCode).header}
            </div>
            <div className="w-100 dropdown">
                <Dropdown.Container
                    elements={releases.map((r, ri) => {
                        return {
                            value: `${r.version} (${new Date(r.releasedAt).toDateString()})`,
                            onClick: () => { setCurrRelease(releases[ri]) },
                            disabled: false,
                        };
                    })}
                    header={
                        <div>
                            <label>
                                <span>v{currRelease?.version}</span>
                                {currRelease?.releasedAt ? <span style={{ opacity: 0.5, marginLeft: 8, fontSize: 14 }}>{new Date(currRelease.releasedAt).toDateString()}</span> : <></>}
                            </label>
                        </div>
                    } />
            </div>
            <Selector<OperatingSystem>
                activeElement={activeOS}
                onItemSelected={(i, l) => { setActiveOS(l) }}
                items={osItems} />
            <Selector<string>
                activeElement={activeBin}
                onItemSelected={(i, bin) => setActiveBin(bin)}
                items={platformBins.map(binCode => {
                    return {
                        content: <label>{binariesCodeDisplayMap[binCode] ?? binCode}</label>,
                        label: binCode,
                        disabled: false,
                    }
                })} />
            <button className="btn tertiary w-100" onClick={() => rnDialogRef.current?.showModal()}>
                Release Notes
            </button>
            <button className="btn primary w-100" disabled={releases.length === 0 || platformBins.length === 0}
                onClick={_ => activeOS && window.open(currRelease.binariesUrls[`${activeOS}-${activeBin}`])}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 19 18" fill="none">
                    <path d="M2.5 12V13C2.5 14.1046 3.39543 15 4.5 15H14.5C15.6046 15 16.5 14.1046 16.5 13V12" stroke="white" stroke-width="2" stroke-linecap="round" />
                    <path d="M9.5 2V10" stroke="white" stroke-width="2" stroke-linecap="round" />
                    <path d="M5.5 7L9.42929 10.9293C9.46834 10.9683 9.53166 10.9683 9.57071 10.9293L13.5 7" stroke="white" stroke-width="2" stroke-linecap="round" />
                </svg>
                {!currRelease && <label>Download ...</label>}
                {currRelease && platformBins.length > 0 && <label>Download {currRelease.version}</label>}
                {currRelease && platformBins.length === 0 && <label>No available binaries for this version</label>}
            </button>
        </div>
    );
};

type OperatingSystem = "windows" | "macos" | "linux";

const operatingSystems: OperatingSystem[] = [
    "windows",
    "linux",
    "macos",
];

const getDeviceOS = () => {
    const ua = window.navigator.userAgent;
    if (ua.includes("Windows")) return operatingSystems[0];
    if (ua.includes("Linux")) return operatingSystems[1];
    if (ua.includes("Macintosh")) return operatingSystems[2];

    return operatingSystems[0]; // set windows by default
}