import { combineReducers } from "@reduxjs/toolkit";
import uiSlice, { UserInterfaceState } from "./ui";
import authenticationSlice, { AuthenticationState } from "./user/authenticationSlice";
import licensesSlice, { LicensesState } from "./user/licensesSlice";
import subscriptionsSlice, { SubscriptionsState } from "./user/subscriptionsSlice";
import userSlice, { UserState } from "./user/userSlice";
import adminUsersSlice, { AdminUsersState } from "./admin/adminUsersSlice";
import marketplaceSlice, { MarketplaceState } from "./user/marketplaceSlice";
import softwareReleasesSlice, { SoftwareReleasesState } from "./softwareReleasesSlice";
import adminEducationSlice, { AdminEducationState } from "./admin/adminEducationSlice";
import educationSlice, { EducationState } from "./user/educationSlice";

export interface ApplicationState {
    ui: UserInterfaceState;
    authentication: AuthenticationState;
    user: UserState;
    licenses: LicensesState;
    subscriptions: SubscriptionsState,
    education: EducationState,
    marketplace: MarketplaceState,
    softwareReleases: SoftwareReleasesState,
    adminUsersState: AdminUsersState,
    adminEducationState: AdminEducationState,
}

export interface NormalizedEntities<TEntity> {
    keys: string[],
    entities: { [key: string]: TEntity },
}

export interface ValidationProblemDetails {
    errors: { [errorKey: string]: string };
}

export default combineReducers({
    ui: uiSlice,
    authentication: authenticationSlice,
    user: userSlice,
    licenses: licensesSlice,
    subscriptions: subscriptionsSlice,
    marketplace: marketplaceSlice,
    education: educationSlice,
    softwareReleases: softwareReleasesSlice,
    adminUsersState: adminUsersSlice,
    adminEducationState: adminEducationSlice,
})