import "./Input.scss";
import iconUpload from "../assets/media/icons/upload.svg";
import iconAlertNegative from "../assets/media/icons/alert_negative.svg";
import { useCallback, useRef, useState } from "react";
import React from "react";

interface InputProps {
    error?: boolean;
}

interface FileInputProps extends InputProps {
    files?: FileList | null;
    onFilesDropped?: (files: FileList | null) => void;
}

export const FileInput = React.forwardRef<any, FileInputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>(
    ({ error, files, onFilesDropped: onFileDropped, ...props }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const [hoveringWithFile, setHoveringWithFile] = useState<boolean>(false);

        const onFileUploadClicked = useCallback(() => {
            inputRef.current?.click();
        }, [inputRef]);

        const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();

            // setHoveringWithFile(false);
        }, []);

        const onDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
            setHoveringWithFile(true);
        }, []);

        const onDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
            setHoveringWithFile(false);
        }, []);

        const onDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            setHoveringWithFile(false);

            if (e.dataTransfer.items.length > 0) {
                const fi = e.dataTransfer.items[0];
                if (fi.kind === "file") {
                    onFileDropped?.(e.dataTransfer.files);
                }
            }
        }, []);

        return (
            <div ref={ref}
                className={`file-upload ${error ? "error" : ""} ${hoveringWithFile ? "fileHovering" : ""} ${props.disabled ? "disabled" : ""}`}
                onClick={onFileUploadClicked} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop} onDragOver={onDragOver}>

                <img style={{ marginBottom: "10px" }} src={error && !hoveringWithFile ? iconAlertNegative : iconUpload} alt="Upload file" />
                <p><span className="highlight">Click to upload</span> or drag and drop file</p>

                {props.placeholder && <p>{props.placeholder}</p>}
                {files?.item?.(0) && <p>{files.item?.(0)?.name}</p>}

                <input ref={inputRef} type={"file"} {...props} />
            </div>
        );
    });

const Input = React.forwardRef<any, InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>(({ error, ...props }, ref) => {
    if (props.type === "file") {
        throw new Error("Use FileInput component for file inputs.");
    }

    return (
        <input {...props} ref={ref} className={`${error ? "error" : ""}`} />
    )
});

export default Input;