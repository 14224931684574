import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FetchStatus from "../data/FetchStatus";
import { Auth } from "aws-amplify";
import { ApplicationState, ValidationProblemDetails } from ".";

export type SoftwareCode = "embergen" | "liquigen" | "geogen";
export type Releases = { [F in SoftwareCode]?: SoftwareRelease[] };

export interface SoftwareReleasesState {
    releases: Releases;

    fetchAllStatus: FetchStatus;
}

export class SoftwareRelease {
    constructor(
        public id: string,
        public version: string,
        public softwareCode: SoftwareCode,
        public releasedAt: Date,
        public localizedReleaseNotes: { [languageCode: string]: string },

        /**
         * Platform code should use the .NET RIDs. Read more here: https://learn.microsoft.com/en-us/dotnet/core/rid-catalog#known-rids
         */
        public binariesUrls: { [platformCode: string]: string },
    ) { }
}

export const fetchSoftwareReleases = createAsyncThunk<Releases, void, { rejectValue: ValidationProblemDetails }>("softwareReleases/fetchSoftwareReleases",
    async (_, thunkApi) => {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken();

        const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/releases`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken.getJwtToken()}`,
            }
        });

        if (resp.ok) {
            const releases = (await resp.json()) as Releases;

            // Add the keys back to the object. Needs a better solution than this borky workaround.
            if (!releases.embergen) releases.embergen = undefined;
            if (!releases.liquigen) releases.liquigen = undefined;
            if (!releases.geogen) releases.geogen = undefined;

            return releases;
        } else {
            if (resp.status === 400) {
                return thunkApi.rejectWithValue(await resp.json() as ValidationProblemDetails);
            } else {
                return thunkApi.rejectWithValue({ errors: { "Unknown": "An internal error occured, please contact the support." } });
            }
        }
    }
);

export const softwareReleasesSelector = (s: ApplicationState) => s.softwareReleases.releases;
export const releasesFetchStatusSelector = (s: ApplicationState) => s.softwareReleases.fetchAllStatus;

const initialState: SoftwareReleasesState = {
    releases: {
        embergen: [],
        liquigen: [],
        geogen: [],
    },

    fetchAllStatus: { value: "idle" },
};

const softwareReleasesSlice = createSlice({
    name: "softwareReleases",
    reducers: {},
    initialState: initialState,
    extraReducers: b => b
        .addCase(fetchSoftwareReleases.rejected, (state, action) => { state.fetchAllStatus = { value: "failure", error: action.payload } })
        .addCase(fetchSoftwareReleases.pending, (state) => { state.fetchAllStatus = { value: "pending" } })
        .addCase(fetchSoftwareReleases.fulfilled, (state, action) => {
            state.fetchAllStatus = { value: "success" };
            state.releases = action.payload;
        })
});

export default softwareReleasesSlice.reducer;