import "./LoginTab.scss";
import { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Button from "../components/Button";
import Input from "../components/Input";
import useAuthenticator from "../features/user/authentication";
import { loginStateSelector } from "../features/user/authenticationSlice";
import { Link, NavLink } from "react-router-dom";

interface LoginFormInput {
    username: string;
    password: string;

    mfaCode?: string;

    newPassword?: string;
    newPasswordConfirmation?: string;
}

export const LoginTab = () => {
    const { control, handleSubmit, getValues, formState: { errors } } = useForm<LoginFormInput>({
        defaultValues: {
            username: "",
            password: "",
            mfaCode: "",
            newPassword: "",
            newPasswordConfirmation: "",
        }
    });

    const { status: loginStatus, error: loginError, challengeState, challenge } = useSelector(loginStateSelector);
    const { logIn, verifyTotp, verifyNewPassword } = useAuthenticator();

    const onSubmit = useCallback((inputs: LoginFormInput) => {
        if (challenge === "TOTP") {
            verifyTotp({ totpCode: inputs.mfaCode ?? "" })
        }
        else if (challenge === "NEW_PASSWORD") {
            verifyNewPassword({
                newPassword: inputs.newPassword ?? ""
            });
        } else {
            logIn({
                username: inputs.username,
                password: inputs.password,
            });
        }
    }, [challenge, logIn, verifyNewPassword, verifyTotp]);

    return (
        <div>
            <form // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group">
                    <label>Email</label>
                    <Controller
                        name="username"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <Input {...field} type={"text"} placeholder="Type your email or username..." error={errors.username !== undefined} disabled={loginStatus === "pending" || loginStatus === "challenged"} />} />
                    {errors.username?.type === "required" &&
                        <p className="danger">The email or username is required.</p>}
                </div>
                <div className="input-group">
                    <label>Password</label>
                    <Controller
                        name="password"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <Input {...field} type={"password"} placeholder="Type your password..." error={errors.password !== undefined} disabled={loginStatus === "pending" || loginStatus === "challenged"} />} />
                    {errors.password?.type === "required" &&
                        <p className="danger">The password is required.</p>}
                </div>
                {challenge === "NEW_PASSWORD" &&
                    <>
                        <div className="input-group">
                            <label>New password</label>
                            <Controller
                                name="newPassword"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <Input {...field} type={"password"} placeholder="Type your new password..."
                                    error={errors.newPassword !== undefined || challengeState.error?.code === "InvalidPasswordException"}
                                    disabled={loginStatus === "pending"} />} />
                            {errors.newPassword?.type === "required" &&
                                <p className="danger">The new password is required.</p>}
                        </div>
                        <div className="input-group">
                            <label>New password confirmation</label>
                            <Controller
                                name="newPasswordConfirmation"
                                rules={{
                                    required: true,
                                    validate: (confirmedPassword) => {
                                        if (getValues().newPassword !== confirmedPassword) {
                                            return "Password confirmation does not match the new password."
                                        }

                                        return true;
                                    }
                                }}
                                control={control}
                                render={({ field }) => <Input {...field} type={"password"} placeholder="Type your new password again..."
                                    error={errors.newPasswordConfirmation !== undefined || challengeState.error?.code === "InvalidPasswordException"}
                                    disabled={loginStatus === "pending"} />} />
                            {errors.newPasswordConfirmation?.type === "required" &&
                                <p className="danger">The new password confirmation is required.</p>}
                            {errors.newPasswordConfirmation?.type === "validate" &&
                                <p className="danger">{errors.newPasswordConfirmation.message}</p>}
                        </div>
                        {challengeState.error &&
                            <p className="danger">{challengeState.error.message}</p>}
                    </>}
                {challenge === "TOTP" &&
                    <div className="input-group">
                        <label>2FA Code</label>
                        <Controller
                            name="mfaCode"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) =>
                                <Input {...field}
                                    type={"text"}
                                    placeholder="Type your 2FA code..."
                                    error={errors.mfaCode !== undefined || challengeState.error !== undefined}
                                    disabled={loginStatus === "pending" || challengeState.status === "pending"} />}
                        />
                        {errors.mfaCode?.type === "required" &&
                            <p className="danger">The 2FA code is required.</p>}
                        {challengeState.error?.code === "Unknown" &&
                            <p className="danger">{challengeState.error.message}</p>}
                    </div>}

                {challenge !== "NEW_PASSWORD" &&
                    <NavLink to={"/forgot-password"} className="mignon">Forgot your password?</NavLink>}
                <div className="input-group">
                    {loginStatus === "error" && loginError?.code && loginError?.code === "UserNotFoundException" &&
                        <p className="danger">
                            No account is associated with this email, if this is the email you purchased with, please <Link style={{ color: "#e26077" }} to={"/signup"}>create an account here</Link> as this is our new account platform.
                        </p>}
                    {loginStatus === "error" && loginError?.code !== "UserNotFoundException" &&
                        <p className="danger">{loginError?.message}</p>}
                    <Button type="submit" color={"primary"} disabled={loginStatus === "pending" || challengeState.status === "pending"}>
                        {loginStatus === "pending" && <span>Signing in...</span>}
                        {loginStatus !== "pending" && <span>Sign In</span>}
                    </Button>
                </div>
            </form>
        </div>
    )
}