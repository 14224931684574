import "./Tooltip.scss";

export interface TooltipProps {
    content: JSX.Element | string;
    children?: JSX.Element;
}

const Tooltip = ({ content, children }: TooltipProps) => {
    return (
        <div className="tooltip">
            {children}
            <div className="content">{content}</div>
        </div>
    )
}

export default Tooltip;