import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FetchStatus from "../../data/FetchStatus";
import { ApplicationState, ValidationProblemDetails } from "..";
import { Auth } from "aws-amplify";

export interface MarketplaceState {
    products: { [productId: string]: MarketplaceProduct };
    purchases: { [key: string]: Purchase };

    purchasesStatus: FetchStatus;
    downloadStatus: FetchStatus;
}

export interface Purchase {
    entryId: string;
    productId: string;
    quantity: number;
}

export enum MarketplaceProductType {
    assetPack = 0,
}

export type PurchaserLegalStatus = "indie" | "studio" | "enterprise" | "educational";
export interface MarketplaceProduct {
    type: MarketplaceProductType;
    productId: string;
    descriptionHtml?: string;
    name: string;
    tags: string;
    purchaseLinks: { [S in PurchaserLegalStatus]: string };
}

export interface MarketplaceAssetPackProduct extends MarketplaceProduct {
    thumbnailUrl: string;
    downloadUrl: string;
    productPageUrl: string;
}

interface LoadPurchasesRespone {
    marketplaceProducts: { [productId: string]: MarketplaceProduct };
    ownedProducts: { [key: string]: Purchase };
}

// Thunks
export const fetchUserPurchases = createAsyncThunk<LoadPurchasesRespone, void, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("marketpalce/loadPurchases",
    async (_, thunkApi) => {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();

        const httpResp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/marketplace/purchases/me`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Accept": "application/json, text/plain",
                "Content-Type": "application/json",
            }
        });

        if (httpResp.ok) {
            const resp = (await httpResp.json()) as LoadPurchasesRespone;
            return resp;
        } else {
            return thunkApi.rejectWithValue({
                errors: {
                    "Unknown": "An error occured while fetching your purchases."
                }
            });
        }
    });

export const downloadProductResource = createAsyncThunk<void, { product: MarketplaceProduct }, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("marketplace/downloadProductResource",
    async ({ product: product }, thunkApi) => {
        const session = await Auth.currentSession();
        const authToken = session.getAccessToken().getJwtToken();

        const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/marketplace/resources/${product.productId}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Accept": "application/zip",
            },
        });
        if (!resp.ok) {
            return thunkApi.rejectWithValue({ errors: { "Download": "An error occured while downloading the resource, try again." } });
        }

        const fileBlob = await resp.blob();

        const holdAnchor = document.createElement("a");
        holdAnchor.style.display = "none";
        holdAnchor.download = product.name;
        holdAnchor.href = window.URL.createObjectURL(fileBlob);
        holdAnchor.click();
        holdAnchor.remove();
    });

// Initial state
const initialState: MarketplaceState = {
    products: {},
    purchases: {},

    purchasesStatus: { value: "idle" },
    downloadStatus: { value: "idle" },
}

// Selectors
export const marketplaceProductSelector = (productId: string) => (state: ApplicationState) => state.marketplace.products[productId];
export const marketplaceProductsSelector = (state: ApplicationState) => state.marketplace.products;
export const userPurchasesSelector = (state: ApplicationState) => Object.values(state.marketplace.purchases);
export const userPurchasesStatusSelector = (state: ApplicationState) => state.marketplace.purchasesStatus;
export const marketplaceDownloadStatusSelector = (state: ApplicationState) => state.marketplace.downloadStatus;

// Slice
const marketplaceSlice = createSlice({
    initialState: initialState,
    name: "marketplace",
    reducers: {},
    extraReducers: builder => {
        builder
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(downloadProductResource.pending, (state, action) => { state.downloadStatus = { value: "pending" } })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(downloadProductResource.rejected, (state, action) => { state.downloadStatus = { value: "failure", error: action.payload } })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(downloadProductResource.fulfilled, (state, action) => { state.downloadStatus = { value: "success" } })

            .addCase(fetchUserPurchases.pending, (state, action) => {
                state.purchasesStatus = { value: "pending" };
            })
            .addCase(fetchUserPurchases.rejected, (state, action) => {
                state.purchasesStatus = { value: "failure", error: action.payload };
            })
            .addCase(fetchUserPurchases.fulfilled, (state, action) => {
                state.purchasesStatus = { value: "success" };
                state.purchases = action.payload.ownedProducts;
                Object.keys(action.payload.marketplaceProducts).forEach(p =>
                    state.products[p] = action.payload.marketplaceProducts[p]);
            })
    }
});

export default marketplaceSlice.reducer;