import "./Modal.scss";
import iconClose from "../assets/media/icons/close.svg";

export interface ModalProps {
    content: JSX.Element;
    isOpen: boolean;
    title?: string;
    size: "tiny" | "small" | "medium" | "large";

    onCloseClicked?: () => void;
}

const Modal = ({ content, size, isOpen, title, onCloseClicked }: ModalProps) => {
    return (
        <div className={`modal ${isOpen ? "visible" : "hidden"}`}>
            <div className="bg-catcher" onClick={onCloseClicked} />
            <div className={`container ${size}`}>
                <div className="header">
                    <p>{title}</p>
                    <button onClick={onCloseClicked}>
                        <img src={iconClose} alt="Close" />
                    </button>
                </div>
                <div className="content">
                    {content}
                </div>
            </div>
        </div>
    )
};

export default Modal;