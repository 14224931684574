import { createSlice } from "@reduxjs/toolkit";
import { Subscription } from "../../user/subscriptionsSlice";

export interface SubscriptionsPageState {
    selectedSubscription?: Subscription;
}

export const initialState: SubscriptionsPageState = {

};

const subsPageSlice = createSlice({
    name: "ui/pages/subscriptions",
    initialState: initialState,
    reducers: {

    }
});

// export const { } = subsPageSlice.actionso;

export default subsPageSlice.reducer;