export function getHumanizedTimeSpan(time: number): string {
    const years = time / 1000 / 3600 / 24 / 365;
    if (years > 1) return `${years.toFixed(0)} year(s)`;

    const months = time / 1000 / 3600 / 24 / 30;
    if (months > 1) return `${months.toFixed(0)} month(s)`;

    const days = years * 365;
    if (days > 1) return `${days.toFixed(0)} day(s)`;

    const hours = days * 24;
    if (hours > 1) return `${hours.toFixed(0)} hour(s)`;

    const minutes = hours * 60;
    if (minutes > 1) return `${minutes.toFixed(0)} minute(s)`;

    const seconds = minutes * 60;
    if (seconds > 1) return `${seconds.toFixed(0)} second(s)`;

    return `${time} milisecond(s)`;
}