/* eslint-disable @typescript-eslint/restrict-template-expressions */
import iconBellSrc from "../assets/media/icons/bell.svg";
import iconExpandSidebar from "../assets/media/icons/expand_sidebar_button.svg";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../features/user/userSlice";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import "./TopBar.scss";
import useAuthenticator from "../features/user/authentication";
import { showSidebar } from "../features/ui/globalUiSlice";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TopBarProps {

}

const TopBar = (props: TopBarProps) => {
    const dispatch = useDispatch();
    const { info: user, updateProfileState } = useSelector(userSelector);
    const { logOut } = useAuthenticator();

    const timeStamp = useMemo(() => {
        return Date.now();

        // Update the timestamp whenever the profile update status is changed. Could mean a profile picture update.
        // ?eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateProfileState.status]);

    const userSummaryHeader = useMemo(() => {
        return (
            <div className="user-summary">
                <img src={`${user?.profilePictureUrl}?t=${timeStamp}`} alt="Your Avatar" style={{ objectFit: "cover" }} />
                <div>
                    <p>
                        <span>{user?.name}</span><br />
                        <span style={{ opacity: 0.4, fontSize: "0.9rem" }}>{user?.email}</span>
                    </p>
                </div>
            </div>
        );
    }, [timeStamp, user?.email, user?.name, user?.profilePictureUrl]);

    return (
        <div id="topbar">
            <div style={{ display: "flex", alignItems: "center" }}>
                <img id="expandButton"
                    src={iconExpandSidebar}
                    alt="Open Sidebar Menu"
                    onClick={() => dispatch(showSidebar())} />
            </div>
            <div>
                {/* <div style={{ padding: "7.5px 25px 7.5px 0px" }}>
                    <Icon iconSrc={iconBellSrc} bubbleContent={"1"} />
                </div> */}
                <Dropdown.Container
                    header={userSummaryHeader}
                    elements={[
                        {
                            value: "Logout",
                            onClick: (_) => logOut()
                        },
                    ]} />
            </div>
        </div >
    );
};

export default TopBar;