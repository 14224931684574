import "./MainLayout.scss";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import { useDispatch, useSelector } from "react-redux";
import { hideSidebar, uiGlobalStateSelector } from "../features/ui/globalUiSlice";
import { useEffect } from "react";
import { fetchAllUserSubscriptions, subsSelector } from "../features/user/subscriptionsSlice";
import { fetchAllUserLicenses, licensesStateSelector } from "../features/user/licensesSlice";
import { fetchUserPurchases, userPurchasesStatusSelector } from "../features/user/marketplaceSlice";
import { getAccountLinks, getUserInfo, userAccountLinksStatusSelector, userInfoStatusSelector } from "../features/user/userSlice";

const MainLayout = () => {
    const dispatch = useDispatch();
    const uiState = useSelector(uiGlobalStateSelector);
    const subsState = useSelector(subsSelector);
    const licState = useSelector(licensesStateSelector);
    const userPurchasesStatus = useSelector(userPurchasesStatusSelector);
    const userAccountLinksStatus = useSelector(userAccountLinksStatusSelector);
    const userInfoStatus = useSelector(userInfoStatusSelector);

    useEffect(() => {
        if (subsState.status.value === "idle") { dispatch(fetchAllUserSubscriptions()); }
        if (licState.licenses.getStatus.value === "idle") { dispatch(fetchAllUserLicenses()); }
        if (userPurchasesStatus.value === "idle") { dispatch(fetchUserPurchases()); }
        if (userAccountLinksStatus.value === "idle") { dispatch(getAccountLinks()); }
        if (userInfoStatus.value === "idle") { dispatch(getUserInfo()); }
    }, [dispatch, subsState.status.value, licState.licenses.getStatus.value]);

    return (
        <div id="mainLayout">
            <SideBar />
            <div id="page" className={`${uiState.sidebarOpened ? "dimmed" : ""}`}>
                <TopBar />
                <div id="content">
                    <Outlet />
                </div>

                <div id="fullPageDimmer"
                    className={`${uiState.sidebarOpened ? "show" : "hide"}`}
                    onClick={() => dispatch(hideSidebar())}></div>
            </div>
        </div >
    );
};

export default MainLayout;
