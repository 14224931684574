import "./Tab.scss";

export interface TabProps {
    title: string;
    isSelected?: boolean;
    onSelected?: () => void;
}

const Tab = ({ title, isSelected, onSelected }: TabProps) => {
    return (
        <div className={`tab ${isSelected ? "selected" : ""}`}
            onClick={() => !isSelected && onSelected?.()}>{title}</div>
    );
};

export default Tab;