import { combineReducers } from "@reduxjs/toolkit";
import globalUiSlice, { GlobalUiState } from "./globalUiSlice";
import pages from "./pages";
import { SubscriptionsPageState } from "./pages/subscriptionsPageSlice";

export interface UserInterfaceState {
    global: GlobalUiState;
    pages: {
        subscription: SubscriptionsPageState;
    }
}

export default combineReducers({
    global: globalUiSlice,
    pages: pages,
});