import "./AuthenticationLayout.scss";
import { useEffect } from "react";
import logoImg from "../assets/media/logos/jangafx.png";
import useAuthenticator from "../features/user/authentication";
import { Navigate, NavLink, Outlet, useNavigate } from "react-router-dom";
import backgroundSrc from "../assets/media/images/lines2_0100.png";

const Header = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={logoImg} alt="JangaFX" style={{ height: "50px", marginBottom: "15px" }} />
        </div>
    );
};

const Footer = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", opacity: 0.7, fontFamily: "Hando", fontWeight: 400 }}>
            <p>© 2024 JangaFX. All rights reserved.</p>
        </div >
    );
};

const AuthenticationLayout = () => {
    const navigate = useNavigate();
    const { status } = useAuthenticator();

    useEffect(() => {
        if (status === "authenticated") {
            navigate("/account/personal");
        }
    }, [navigate, status]);

    if (status === "providing") {
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
                <Header />
                <div className="authenticator-container">
                    <p style={{ textAlign: "center" }}>
                        Verifying session...
                    </p>
                </div>
                <Footer />
            </div >
        )
    }

    if (status === "authenticated") return <Navigate to={"/account/personal"} />

    return (
        <div id="authenticatorPage">
            <img src={backgroundSrc} alt="Fumes Background" style={{ position: "fixed", width: "100%", height: "100%", objectFit: "cover", zIndex: "-2" }} />
            <div style={{ position: "fixed", backdropFilter: "blur(3px) brightness(0.8)", width: "100%", height: "100%", zIndex: "-1" }}></div>
            <div className="authenticator-container">
                <Header />
                <nav className="authenticator-tabs">
                    <NavLink to={"/signin"}>Sign In</NavLink>
                    <NavLink to={"/signup"}>Sign Up</NavLink>
                </nav>
                <div className="authenticator-content">
                    <Outlet />
                </div>
                <Footer />
            </div>
        </div>
    )
};

export default AuthenticationLayout;