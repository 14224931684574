import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API, Auth } from "aws-amplify";
import FetchStatus from "../../data/FetchStatus";
import { ApplicationState, NormalizedEntities, ValidationProblemDetails } from "..";
import { pushAlert } from "../ui/globalUiSlice";
import { Subscription, SubscriptionChangeType, buyMaintenance, cancelPendingChange, initMonthlyToAnnualUpgrade, initSeatsKeysAddition, initSuiteUpgrade } from "../user/subscriptionsSlice";
import { License } from "../user/licensesSlice";

export interface AdminUsersState {
    searchedUser: {
        status: FetchStatus;
        data?: AdminUserViewModel;
    };

    discardStatus: { [subscriptionId: string]: FetchStatus };
    undiscardStatus: { [subscriptionId: string]: FetchStatus };
    scaninsertStatus: FetchStatus;
    refreshDiscordLinkStatus: FetchStatus;
}

export interface AdminUserSubscription extends Subscription {
    isDiscarded: boolean;
    fastSpringSubscriptionUrl: string;
    licenses: { [id: string]: License };
}

export interface AdminUserViewModel {
    id: string;
    email: string;
    fullName: string;
    subscriptionsCount: number;
    pairedSubscriptionsCount: number;
    licensesCount: number;
    pairedLicensesCount: number;
    profilePicUrl?: string;
    subscriptions: NormalizedEntities<AdminUserSubscription>;
    accountLinks: UserAccountLinksViewModel;
}

export interface UserAccountLinksViewModel {
    isDiscordLinked: boolean;
}

export interface ScanInsertResponse {
    insertedSubscriptionsCount: number;
    totalSubscriptionsCount: number;
}

/**
 * Thunks
 */
export const discardSubscription = createAsyncThunk<void, { subscriptionId: string }, { state: ApplicationState, rejectValue?: ValidationProblemDetails }>("admin/subscriptions/discard",
    async ({ subscriptionId }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/${subscriptionId}/discard`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                return;
            } else {
                if (resp.status === 404) {
                    return thunkApi.rejectWithValue({ errors: { "NotFound": "Subscription could not be found." } } as ValidationProblemDetails);
                } else {
                    return thunkApi.rejectWithValue(undefined);
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const undiscardSubscription = createAsyncThunk<void, { subscriptionId: string }, { state: ApplicationState, rejectValue?: ValidationProblemDetails }>("admin/subscriptions/undiscard",
    async ({ subscriptionId }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/${subscriptionId}/undiscard`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                return;
            } else {
                if (resp.status === 404) {
                    return thunkApi.rejectWithValue({ errors: { "NotFound": "Subscription could not be found." } } as ValidationProblemDetails);
                } else {
                    return thunkApi.rejectWithValue(undefined);
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const searchUserThunk = createAsyncThunk<AdminUserViewModel, { searchNeedle?: string }, { rejectValue: ValidationProblemDetails }>("admin/users/search",
    async ({ searchNeedle }, thunkApi) => {
        try {
            if (!searchNeedle) {
                return thunkApi.rejectWithValue({ errors: { "EmptySearchQuery": "Please provide the full user email or user ID." } });
            }
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/users/search?searchNeedle=${searchNeedle}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                const user = (await resp.json()) as AdminUserViewModel;
                return user;
            } else {
                if (resp.status === 400) {
                    return thunkApi.rejectWithValue(await resp.json() as ValidationProblemDetails);
                }
                else if (resp.status === 404) {
                    return thunkApi.rejectWithValue({ errors: { "NotFound": "User could not be found." } } as ValidationProblemDetails);
                } else {
                    return thunkApi.rejectWithValue({ errors: { "Unknown": "An internal server error occured, please contact support." } } as ValidationProblemDetails);
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue({ errors: { "Unknown": "An internal server error occured, please contact support." } } as ValidationProblemDetails);
        }
    });

export const scanInsertThunk = createAsyncThunk<ScanInsertResponse, { email: string }>("admin/subscriptions/scaninsert",
    async ({ email }, thunkApi) => {
        try {
            const resp = await API.post("LicenseService", `/api/v1/subscriptions/scaninsert`, {
                queryStringParameters: {
                    email: email
                }
            }) as ScanInsertResponse;

            await thunkApi.dispatch(pushAlert({
                title: `Scan/Insert finished for ${email}`,
                type: "positive",
                body: <p>Scan/Insert completed for user {email}. Inserted {resp.insertedSubscriptionsCount} subscriptions of a total of {resp.totalSubscriptionsCount} subscriptions.</p>,
                cooldown: 10000
            }));

            await thunkApi.dispatch(searchUserThunk({
                searchNeedle: email
            }));

            return resp;
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const refreshDiscordLink = createAsyncThunk<void, { userId: string }, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("admin/users/links/refreshDiscord",
    async ({ userId }, thunkApi) => {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken();

        const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/users/links/discord/${userId}/refresh`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken.getJwtToken()}`,
            }
        });

        if (resp.ok) {
            await thunkApi.dispatch(pushAlert({
                cooldown: 2500,
                title: "User roles have been refreshed",
                type: "positive"
            }));
            return;
        } else {
            if (resp.status === 400) {
                return thunkApi.rejectWithValue(await resp.json() as ValidationProblemDetails);
            } else if (resp.status === 404) {
                return thunkApi.rejectWithValue({ errors: { "NoLinkFound": "User does not have a Discord link active." } });
            } else {
                return thunkApi.rejectWithValue({ errors: { "Unknown": "An internal error occured, please contact the support." } });
            }
        }
    });

export const adminFetchSubscription = createAsyncThunk<AdminUserSubscription, { subscriptionId: string }, { rejectValue?: ValidationProblemDetails }>("admin/subscriptions/fetchSubscription",
    async ({ subscriptionId }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/admin/subscriptions/${subscriptionId}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                const sub = (await resp.json()) as AdminUserSubscription;
                return sub;
            } else {
                if (resp.status === 404) {
                    return thunkApi.rejectWithValue({ errors: { "NotFound": "Subscription could not be found." } } as ValidationProblemDetails);
                } else {
                    return thunkApi.rejectWithValue(undefined);
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

/**
 * Setup
 */
const initialState: AdminUsersState = {
    searchedUser: { status: { value: "idle" } },
    scaninsertStatus: { value: "idle" },
    refreshDiscordLinkStatus: { value: "idle" },
    discardStatus: {},
    undiscardStatus: {},
}

/**
 * Selectors
 */
export const searchedUserSelector = (state: ApplicationState) => state.adminUsersState.searchedUser;
export const scaninsertStatusSelector = (state: ApplicationState) => state.adminUsersState.scaninsertStatus;
export const refreshDiscordLinkStatusSelector = (state: ApplicationState) => state.adminUsersState.refreshDiscordLinkStatus;
export const discardStatusSelector = (subscriptionId: string) => (state: ApplicationState) => state.adminUsersState.discardStatus?.[subscriptionId];
export const undiscardStatusSelector = (subscriptionId: string) => (state: ApplicationState) => state.adminUsersState.undiscardStatus?.[subscriptionId];

const adminUsersSlice = createSlice({
    name: "admin/adminUsers",
    reducers: {},
    initialState: initialState,
    extraReducers: builder => builder
        .addCase(searchUserThunk.rejected, (state, action) => { state.searchedUser.status = { value: "failure", error: action.payload } })
        .addCase(searchUserThunk.pending, (state, action) => {
            state.searchedUser = {
                status: { value: "pending" }
            }
        })
        .addCase(searchUserThunk.fulfilled, (state, action) => {
            state.searchedUser = {
                status: { value: "success" },
                data: action.payload
            }
        })

        .addCase(discardSubscription.rejected, (state, action) => { state.discardStatus[action.meta.arg.subscriptionId] = { value: "failure" } })
        .addCase(discardSubscription.pending, (state, action) => { state.discardStatus[action.meta.arg.subscriptionId] = { value: "pending" } })
        .addCase(discardSubscription.fulfilled, (state, { meta: { arg: { subscriptionId: subId } } }) => {
            if (state.searchedUser.data) {
                state.searchedUser.data.subscriptions.entities[subId].isDiscarded = true;
            }

            state.discardStatus[subId] = { value: "success" }
        })

        .addCase(undiscardSubscription.rejected, (state, action) => { state.undiscardStatus[action.meta.arg.subscriptionId] = { value: "failure" } })
        .addCase(undiscardSubscription.pending, (state, action) => { state.undiscardStatus[action.meta.arg.subscriptionId] = { value: "pending" } })
        .addCase(undiscardSubscription.fulfilled, (state, { meta: { arg: { subscriptionId: subId } } }) => {
            if (state.searchedUser.data) {
                state.searchedUser.data.subscriptions.entities[subId].isDiscarded = false;
            }
            state.undiscardStatus[subId] = { value: "success" };
        })

        .addCase(scanInsertThunk.rejected, (state, action) => { state.scaninsertStatus = { value: "failure" } })
        .addCase(scanInsertThunk.pending, (state, action) => { state.scaninsertStatus = { value: "pending" } })
        .addCase(scanInsertThunk.fulfilled, (state, action) => { state.scaninsertStatus = { value: "success" } })

        .addCase(refreshDiscordLink.rejected, (state, action) => { state.refreshDiscordLinkStatus = { value: "failure", error: action.payload } })
        .addCase(refreshDiscordLink.pending, (state, action) => { state.refreshDiscordLinkStatus = { value: "pending" } })
        .addCase(refreshDiscordLink.fulfilled, (state, action) => { state.refreshDiscordLinkStatus = { value: "success" } })

        .addCase(cancelPendingChange.fulfilled, (state, { meta: { arg: { subId } } }) => {
            if (state.searchedUser.data) {
                state.searchedUser.data.subscriptions.entities[subId].pendingChange = undefined;
            }
        })
        .addCase(initMonthlyToAnnualUpgrade.fulfilled, (state, { payload, meta: { arg: { subId } } }) => {
            if (state.searchedUser.data) {
                state.searchedUser.data.subscriptions.entities[subId].pendingChange = {
                    changeType: SubscriptionChangeType.MonthlyToAnnualUpgrade,
                    quoteUrl: payload.quoteUrl,
                };
            }
        })
        .addCase(initSeatsKeysAddition.fulfilled, (state, { payload, meta: { arg: { subId, quantity } } }) => {
            if (state.searchedUser.data) {
                state.searchedUser.data.subscriptions.entities[subId].pendingChange = {
                    changeType: SubscriptionChangeType.SeatsAddition,
                    quoteUrl: payload.quoteUrl,
                    seatsAddition: quantity.toString(),
                };
            }
        })
        .addCase(initSuiteUpgrade.fulfilled, (state, { payload, meta: { arg: { subId } } }) => {
            if (state.searchedUser.data) {
                state.searchedUser.data.subscriptions.entities[subId].pendingChange = {
                    changeType: SubscriptionChangeType.SuiteUpgrade,
                    quoteUrl: payload.quoteUrl,
                };
            }
        })
        .addCase(buyMaintenance.fulfilled, (state, { payload, meta: { arg: { subId } } }) => {
            if (state.searchedUser.data) {
                state.searchedUser.data.subscriptions.entities[subId].pendingChange = {
                    changeType: SubscriptionChangeType.MaintenancePurchase,
                    quoteUrl: payload.quoteUrl,
                };
            }
        })
});

export default adminUsersSlice.reducer;