import { CognitoUser } from "@aws-amplify/auth";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, Auth, Storage } from "aws-amplify";
import { ApplicationState, ValidationProblemDetails } from "..";
import { DefaultProfilePictureUrl } from "../../appConstants";
import FetchStatus from "../../data/FetchStatus";
import { mapUserToUserInfo } from "../../utils/userHelpers";
import { pushAlert, PushAlertArguments } from "../ui/globalUiSlice";
import { AuthenticationError } from "./authenticationSlice";
import { ScanInsertResponse } from "../admin/adminUsersSlice";
import { fetchAllUserLicenses } from "./licensesSlice";
import { fetchAllUserSubscriptions } from "./subscriptionsSlice";

type StatusType = "idle" | "pending" | "error" | "success";

export interface UserInfo {
    id: string;
    profilePictureUrl: string;
    emailVerified: boolean;
    email: string;
    name: string;
    username: string;
    groups: string[];
    company?: string;
    customData: {
        lastReloadedSubscriptionsAt: string | null,
        canReloadSubscriptions: boolean,
    };

    mfaEnabled: boolean;

    accountLinks: {
        discord: boolean;
    }
}

interface UpdateState {
    status: StatusType;
    error?: string;
}

interface PasswordChangeState {
    status: StatusType;
    error?: AuthenticationError;
}

type MfaStepType = "NONE" | "AWAITING_TOTP_VERIFICATION";
interface MfaUpdateState {
    step: MfaStepType;
    totpUri?: string;

    status: StatusType;
    error?: AuthenticationError;
}

export interface UserState {
    info?: UserInfo;
    fetchStatus: FetchStatus;
    openFastSpringPlatformStatus: FetchStatus;
    updateProfileState: UpdateState;
    passwordChangeState: PasswordChangeState;
    mfaUpdateState: MfaUpdateState;
    inviteUserState: FetchStatus;
    getAccountLinksStatus: FetchStatus;
    getUserInfoStatus: FetchStatus;
    accountLinkingkStatus: FetchStatus;
    selfScanInsertStatus: FetchStatus;
    accountUnlinkingkStatus: FetchStatus;
}

export interface ProfileUpdateArguments {
    fullName?: string;
    company?: string;
    profilePicture?: File;
}

export interface PasswordChangeArguments {
    oldPassword: string;
    newPassword: string;
}

type ApplicationMfaTypes = "NOMFA" | "SOFTWARE_TOKEN_MFA";
export interface SetMfaArguments {
    preference: ApplicationMfaTypes;
}

const initialState: UserState = {
    info: {
        id: "unknown",
        emailVerified: false,
        profilePictureUrl: DefaultProfilePictureUrl,
        name: "Unknown Name",
        username: "Unknown Username",
        email: "Unknown Email",
        groups: [],
        mfaEnabled: false,
        customData: {
            lastReloadedSubscriptionsAt: null,
            canReloadSubscriptions: false,
        },
        accountLinks: {
            discord: false,
        }
    },
    openFastSpringPlatformStatus: { value: "idle", },
    fetchStatus: { value: "idle", },
    updateProfileState: { status: "idle", },
    passwordChangeState: { status: "idle" },
    inviteUserState: { value: "idle" },
    selfScanInsertStatus: { value: "idle" },
    accountLinkingkStatus: { value: "idle" },
    getUserInfoStatus: { value: "idle" },
    accountUnlinkingkStatus: { value: "idle" },
    getAccountLinksStatus: { value: "idle" },
    mfaUpdateState: {
        status: "idle",
        step: "NONE"
    },
};

//#region Thunks
/**
 * Opens the FastSpring account platform in a new tab with the already authenticated user session.
 * @param {string} intent - Can be `payment-details`, `subscriptions`, `paydown-subscription-{subscription id}`
 */
export const openFastSpringAccPlatform = createAsyncThunk<void, { intent: string }, { rejectValue: ValidationProblemDetails }>("user/openFastSpringAccPlatform",
    async ({ intent }, thunkApi) => {
        try {
            const url = await API.get("LicenseService", "/api/v1/users/fastspring/account-session", {
                queryStringParameters: {
                    intent: intent
                }
            }) as string;

            window.open(url, "_blank", "noopener");
        } catch (error: unknown) {
            return thunkApi.rejectWithValue({
                errors: { "Unknown": "Could not complete action, please try again later or contact support." }
            })
        }
    });

interface GetAccountLinksResult {
    isDiscordLinked: boolean;
}

export const getAccountLinks = createAsyncThunk<GetAccountLinksResult, void, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("user/getAccountLinks",
    async (_, thunkApi) => {
        try {
            const result = await API.get("LicenseService", "/api/v1/users/links/me", {}) as GetAccountLinksResult;
            return result;
        } catch (error: unknown) {
            return thunkApi.rejectWithValue({
                errors: { "Unknown": "An error occured while trying to send an invite, try again later or contact support." }
            });
        }
    });

export const getUserInfo = createAsyncThunk<UserInfo, void, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("user/getUserInfo",
    async (_, thunkApi) => {
        try {
            const result = await API.get("LicenseService", "/api/v1/users/me", {}) as UserInfo;
            return result;
        } catch (error: unknown) {
            return thunkApi.rejectWithValue({
                errors: { "Unknown": "An error occured while trying to get the user information, try again later or contact support." }
            });
        }
    });

export const linkDiscordAccount = createAsyncThunk<void, { code: string }, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("user/linkDiscordAccount",
    async ({ code }, thunkApi) => {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken();

        const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/users/links/discord/me?code=${code}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken.getJwtToken()}`,
            }
        });

        if (resp.ok) {
            await thunkApi.dispatch(pushAlert({
                cooldown: 5000,
                title: "Discord successfully linked!",
                type: "positive",
                body: (
                    <div>Join our Discord server in order to get your roles assigned automatically.</div>
                )
            }));
            return;
        } else {
            if (resp.status === 400) {
                return thunkApi.rejectWithValue(await resp.json() as ValidationProblemDetails);
            } else {
                return thunkApi.rejectWithValue({ errors: { "Unknown": "An internal error occured, please contact the support." } });
            }
        }
    });

export const unlinkDiscordAccount = createAsyncThunk<void, void, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("user/unlinkDiscordAccount",
    async (_, thunkApi) => {
        try {
            await API.del("LicenseService", "/api/v1/users/links/discord/me", {});
        } catch (error: unknown) {
            return thunkApi.rejectWithValue({
                errors: {
                    "Unknown": "An error occured while trying to send an invite, try again later or contact support."
                }
            });
        }
    });

export const selfScanInsertThunk = createAsyncThunk<ScanInsertResponse, void, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("admin/subscriptions/scaninsert/me",
    async (_, thunkApi) => {
        try {
            const resp = await API.post("LicenseService", `/api/v1/subscriptions/scaninsert/me`, {}) as ScanInsertResponse;

            await thunkApi.dispatch(getUserInfo());
            await thunkApi.dispatch(fetchAllUserSubscriptions());
            await thunkApi.dispatch(fetchAllUserLicenses());

            return resp;
        } catch (error) {
            return thunkApi.rejectWithValue({ errors: { "Unknown": "An error occured while reloading your subscriptions." } });
        }
    });

export const inviteUser = createAsyncThunk<void, { email: string }, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("user/inviteUser",
    async ({ email }, thunkApi) => {
        try {
            await API.post("LicenseService", "/api/v1/users/invite", {
                queryStringParameters: {
                    email: email
                }
            });
        } catch (error: unknown) {
            return thunkApi.rejectWithValue({
                errors: {
                    "Unknown": "An error occured while trying to send an invite, try again later or contact support."
                }
            });
        }
    });

type UserAttributesType = { [attrName: string]: string };
export const updateProfile = createAsyncThunk<UserAttributesType, ProfileUpdateArguments, { state: ApplicationState, rejectValue: string | undefined }>("user/updateProfile",
    async ({ company, fullName, profilePicture }, thunkApi) => {
        const user = await Auth.currentAuthenticatedUser() as CognitoUser;
        const { user: { info } } = thunkApi.getState();

        const attrsToUpdate: UserAttributesType = {};

        if (company !== undefined && info?.company !== company) {
            attrsToUpdate["custom:company"] = company;
        }

        if (fullName !== undefined && info?.name !== fullName) {
            attrsToUpdate["name"] = fullName;
        }

        if (profilePicture !== undefined && info?.id !== undefined) {
            const extension = profilePicture.type.split("/")[1];
            const uploadRes = await Storage.put(`${info.id}.${extension}`, profilePicture, {
                bucket: "jangafx-accountplatform-userfiles",
                contentType: profilePicture.type,
                level: "public",
            });

            const userFilesBucketName = "jangafx-accountplatform-userfiles";
            const picUrl = `https://${userFilesBucketName}.s3.amazonaws.com/public/${uploadRes.key}`;

            attrsToUpdate["picture"] = picUrl;
        }

        try {
            await Auth.updateUserAttributes(user, attrsToUpdate);

            const info = await mapUserToUserInfo(user);
            thunkApi.dispatch(setUserInfo(info));
            await thunkApi.dispatch(pushAlert(new PushAlertArguments(
                "Profile information updated",
                "positive",
            )));

            return attrsToUpdate;
        } catch (error: unknown) {
            const message = (error as { message?: string })?.message;

            return thunkApi.rejectWithValue(message ?? "An unknown error occured.");
        }
    });

export const changePasword = createAsyncThunk<void, PasswordChangeArguments, { state: ApplicationState, rejectValue: AuthenticationError }>("user/changePassword", async ({ newPassword, oldPassword }, thunkApi) => {
    const user = await Auth.currentAuthenticatedUser() as CognitoUser;

    try {
        const resp = await Auth.changePassword(user, oldPassword, newPassword);

        if (resp === "SUCCESS") {
            await thunkApi.dispatch(pushAlert(new PushAlertArguments(
                "Your password has been changed",
                "positive"
            )));
        } else {
            throw new AuthenticationError("An unknown error occured", "Unknown");
        }
    } catch (err: unknown) {
        const authErr = (err as AuthenticationError);

        if (authErr.code !== undefined) {
            return thunkApi.rejectWithValue(authErr);
        } else {
            return thunkApi.rejectWithValue(
                new AuthenticationError("An unknown error occured", "Unknown"));
        }
    }
});

interface SetMfaPreferenceReturn {
    newMfaPreference: ApplicationMfaTypes;
    totpSecurityCode?: string;
}

export const setMfaPreference = createAsyncThunk<SetMfaPreferenceReturn, SetMfaArguments, { state: ApplicationState, rejectValue: AuthenticationError }>("user/setMfaPreference",
    async ({ preference }, thunkApi) => {
        const user = await Auth.currentAuthenticatedUser() as CognitoUser;

        try {
            if (preference === "NOMFA") {
                const resp = await Auth.setPreferredMFA(user, preference);

                return {
                    newMfaPreference: preference
                }
            }
            else if (preference === "SOFTWARE_TOKEN_MFA") {
                const securityCode = await Auth.setupTOTP(user);

                return {
                    newMfaPreference: preference,
                    totpSecurityCode: securityCode
                };
            }

            throw new Error(`Unhandled MFA preference.`);
        } catch (err: unknown) {
            const authErr = (err as AuthenticationError);

            if (authErr.code !== undefined) {
                return thunkApi.rejectWithValue(authErr);
            } else {
                return thunkApi.rejectWithValue(
                    new AuthenticationError("An unknown error occured", "Unknown"));
            }
        }
    });

interface VerifyTotpTokenArguments {
    totpToken: string;
}

export const verifyTotpToken = createAsyncThunk<void, VerifyTotpTokenArguments, { state: ApplicationState, rejectValue: AuthenticationError }>("user/verifyTotpToken",
    async ({ totpToken }, thunkApi) => {
        try {
            const user = await Auth.currentAuthenticatedUser() as CognitoUser;

            const r1 = await Auth.verifyTotpToken(user, totpToken);
            const r2 = await Auth.setPreferredMFA(user, "SOFTWARE_TOKEN_MFA");
        } catch (err: unknown) {
            const authErr = (err as AuthenticationError);

            if (authErr.code !== undefined) {
                return thunkApi.rejectWithValue(authErr);
            } else {
                return thunkApi.rejectWithValue(
                    new AuthenticationError("An unknown error occured", "Unknown"));
            }
        }
    });
//#endregion

//#region Selectors
export const userInfoSelector = (state: ApplicationState) => state.user.info;
export const userSelector = (state: ApplicationState) => state.user;
export const userAccountLinksSelector = (state: ApplicationState) => state.user.info?.accountLinks;
export const userAccountLinksStatusSelector = (state: ApplicationState) => state.user.getAccountLinksStatus;
export const userInfoStatusSelector = (state: ApplicationState) => state.user.getUserInfoStatus;
export const userLinkingStatusSelector = (state: ApplicationState) => state.user.accountLinkingkStatus;
export const userUnlinkingStatusSelector = (state: ApplicationState) => state.user.accountUnlinkingkStatus;
export const userUpdateStateSelector = (state: ApplicationState) => state.user.updateProfileState;
export const passwordChangeStateSelector = (state: ApplicationState) => state.user.passwordChangeState;
export const mfaUpdateStateSelector = (state: ApplicationState) => state.user.mfaUpdateState;
export const inviteUserStatusSelector = (state: ApplicationState) => state.user.inviteUserState ?? undefined;
export const selfScanInsertStatusSelector = (state: ApplicationState) => state.user.selfScanInsertStatus ?? undefined;
export const openFastSpringPlatformStateSelector = (state: ApplicationState) => state.user.openFastSpringPlatformStatus;
//#endregion

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setUserInfo(state, action: PayloadAction<UserInfo | undefined>) {
            if (action.payload) {
                state.info = action.payload;
            } else {
                state.info = undefined;
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(updateProfile.pending, (state, action) => {
                state.updateProfileState.status = "pending";
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.updateProfileState = {
                    status: "error",
                    error: action.payload
                }
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.updateProfileState.status = "success";
            })

            .addCase(changePasword.pending, (state, action) => {
                state.passwordChangeState.status = "pending";
            })
            .addCase(changePasword.rejected, (state, action) => {
                state.passwordChangeState = {
                    status: "error",
                    error: action.payload
                }
            })
            .addCase(changePasword.fulfilled, (state, action) => {
                state.passwordChangeState.status = "success";
            })

            .addCase(setMfaPreference.pending, (state, action) => {
                state.mfaUpdateState.status = "pending";
            })
            .addCase(setMfaPreference.rejected, (state, action) => {
                state.mfaUpdateState = {
                    ...state.mfaUpdateState,
                    status: "error",
                    error: action.payload
                }
            })
            .addCase(setMfaPreference.fulfilled, (state, action) => {
                state.mfaUpdateState.status = "success";

                if (state.info && action.payload.newMfaPreference === "NOMFA") state.info.mfaEnabled = false;

                if (action.payload.newMfaPreference === "SOFTWARE_TOKEN_MFA") {
                    if (action.payload.totpSecurityCode === undefined || state.info?.email === undefined) {
                        throw new Error("Cannot have the TOTP security code or the email undefined.");
                    }

                    state.mfaUpdateState.step = "AWAITING_TOTP_VERIFICATION";
                    state.mfaUpdateState.totpUri = `otpauth://totp/JangaFX:${state.info.email}?secret=${action.payload.totpSecurityCode}&issuer=JangaFX`;
                }
            })

            .addCase(inviteUser.pending, (state, action) => {
                state.inviteUserState = { value: "pending" };
            })
            .addCase(inviteUser.fulfilled, (state, action) => {
                state.inviteUserState = { value: "success" };

                setTimeout(() => {
                    state.inviteUserState = { value: "idle" };
                }, 5000);
            })
            .addCase(inviteUser.rejected, (state, action) => {
                state.inviteUserState = { value: "failure", error: action.payload };
            })

            .addCase(openFastSpringAccPlatform.pending, (state, action) => { state.openFastSpringPlatformStatus = { value: "pending" } })
            .addCase(openFastSpringAccPlatform.rejected, (state, action) => { state.openFastSpringPlatformStatus = { value: "failure" } })
            .addCase(openFastSpringAccPlatform.fulfilled, (state, action) => { state.openFastSpringPlatformStatus = { value: "success" }; })

            .addCase(selfScanInsertThunk.rejected, (state, action) => { state.selfScanInsertStatus = { value: "failure" } })
            .addCase(selfScanInsertThunk.pending, (state, action) => { state.selfScanInsertStatus = { value: "pending" } })
            .addCase(selfScanInsertThunk.fulfilled, (state, action) => { state.selfScanInsertStatus = { value: "success" } })

            .addCase(linkDiscordAccount.pending, (state, action) => { state.accountLinkingkStatus = { value: "pending" } })
            .addCase(linkDiscordAccount.rejected, (state, action) => { state.accountLinkingkStatus = { value: "failure", error: action.payload } })
            .addCase(linkDiscordAccount.fulfilled, (state, action) => {
                state.accountLinkingkStatus = { value: "success" };
                if (state.info) {
                    state.info.accountLinks.discord = true;
                }
            })

            .addCase(unlinkDiscordAccount.pending, (state, action) => { state.accountUnlinkingkStatus = { value: "pending" } })
            .addCase(unlinkDiscordAccount.rejected, (state, action) => { state.accountUnlinkingkStatus = { value: "failure", error: action.payload } })
            .addCase(unlinkDiscordAccount.fulfilled, (state, action) => {
                state.accountUnlinkingkStatus = { value: "success" };
                if (state.info) {
                    state.info.accountLinks.discord = false;
                }
            })

            .addCase(getAccountLinks.pending, (state, action) => { state.getAccountLinksStatus = { value: "pending" } })
            .addCase(getAccountLinks.rejected, (state, action) => { state.getAccountLinksStatus = { value: "failure" } })
            .addCase(getAccountLinks.fulfilled, (state, action) => {
                state.getAccountLinksStatus = { value: "success" };
                if (state.info) {
                    state.info.accountLinks.discord = action.payload.isDiscordLinked;
                }
            })

            .addCase(getUserInfo.pending, (state, action) => { state.getUserInfoStatus = { value: "pending" } })
            .addCase(getUserInfo.rejected, (state, action) => { state.getUserInfoStatus = { value: "failure" } })
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.getUserInfoStatus = { value: "success" };
                if (state.info) {
                    state.info.customData = action.payload.customData;
                }
            })

            .addCase(verifyTotpToken.pending, (state, action) => {
                state.mfaUpdateState.status = "pending";
            })
            .addCase(verifyTotpToken.rejected, (state, action) => {
                state.mfaUpdateState = {
                    ...state.mfaUpdateState,
                    status: "error",
                    error: action.payload
                }
            })
            .addCase(verifyTotpToken.fulfilled, (state, action) => {
                state.mfaUpdateState.status = "success";
                state.mfaUpdateState.step = "NONE";

                if (state.info) state.info.mfaEnabled = true;
            })
    }
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;