import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { cancelPendingChange, demandKeysSeatsAdditionEstimate, initSeatsKeysAddition, resetEstimate, seatsAdditionEstimateStateSelector, seatsKeysAdditionStateSelector, SubscriptionChangeType, subSelector } from "../../../features/user/subscriptionsSlice";
import { getProductData } from "../../../utils/productHelper";
import iconPlus from "../../../assets/media/icons/plus.svg";
import iconMinus from "../../../assets/media/icons/minus.svg";
import iconComputer from "../../../assets/media/icons/computer.svg";
import iconKey from "../../../assets/media/icons/key.svg";
import Button from "../../Button";
import "./ModifySubscriptionSeatingDetails.scss";
import Input from "../../Input";
import { clamp } from "../../../utils/mathHelpers";

const MIN_SEATS = 0;
const MAX_SEATS = 50;

const ModifySubscriptionSeatingDetails = () => {
    const { subId } = useParams();
    const sub = useSelector(subSelector(subId));

    if (sub?.pendingChange?.changeType === SubscriptionChangeType.SeatsAddition) {
        return <ExistingSeatsAdditionDetails />
    } else {
        return <AddKeysSeatsDetails />
    }
};

const ExistingSeatsAdditionDetails = () => {
    const dispatch = useDispatch();
    const { subId } = useParams();
    const sub = useSelector(subSelector(subId));

    if (sub === undefined) {
        return <div>Subscription is undefined.</div>;
    }

    const seatCount = useMemo(() => parseInt(sub.pendingChange?.seatsAddition ?? "0"), [sub.pendingChange]);

    const currFormatter = useMemo(() => new Intl.NumberFormat("en-US", { style: "currency", currency: sub.currency }), [sub.currency]);
    const productData = useMemo(() => getProductData(sub.productCode), [sub.productCode]);
    const perUnitPriceStr = useMemo(() => {
        const priceFted = currFormatter.format(sub.price);
        return `+ ${priceFted} / ${sub.isFloating ? "seat" : "key"}`;
    }, [sub.price, sub.currency, sub.isFloating]);

    return (
        <div id="modifySeatsDetails">
            <p>You have a pending change to add {sub.pendingChange?.seatsAddition} {sub.isFloating ? "seats" : "keys"}.</p>
            <h3>Summary</h3>

            <div className="d-flex flex-direction-row align-items-center border-top p-top-large" style={{ gap: 16 }}>
                <img className="icon" src={productData.logoSrc} />
                <div style={{ flexGrow: 1 }}>
                    {productData.header}
                    <small className="muted">{perUnitPriceStr}</small>
                    <img src={sub.isFloating ? iconComputer : iconKey} style={{ height: "1rem", verticalAlign: "middle", marginLeft: "3px" }} />
                </div>
                <div>
                    <Button color="danger" onClick={() => dispatch(cancelPendingChange({ subId: sub.id }))} style={{ marginRight: 5 }}>Cancel Quote</Button>
                    <Button color="success" onClick={() => window.open(sub.pendingChange?.quoteUrl)}>View Quote</Button>
                </div>
            </div>

            <div id="summaryContent" className="border-top p-top-large">
                <label className="muted">Current commitment:</label>
                <label>{currFormatter.format(sub.subtotal)} / {productData.periodicity}</label>

                <label className="muted">{sub.isFloating ? "Seats" : "Keys"} subtotal:</label>
                {seatCount > 0 && <label>+ {currFormatter.format(sub.price * seatCount)} / {productData.periodicity}</label>}
                {(seatCount === 0 || isNaN(seatCount)) && <label className="muted">-</label>}

                <label className="muted">Total:</label>
                <label>{currFormatter.format(sub.subtotal + sub.price * (isNaN(seatCount) ? 0 : seatCount))} / {productData.periodicity}</label>
            </div>
        </div>
    );
};

const AddKeysSeatsDetails = () => {
    const dispatch = useDispatch();
    const { subId } = useParams();
    const sub = useSelector(subSelector(subId));
    const [seatCount, setSeatCount] = useState<number>(0);
    const additionState = useSelector(seatsKeysAdditionStateSelector(subId));
    const estimateState = useSelector(seatsAdditionEstimateStateSelector(subId));

    if (sub === undefined) {
        return <div>Subscription is undefined.</div>;
    }

    const canInteract = useMemo(() => additionState?.status.value === "pending" || additionState?.status.value === "success" || !sub.canAddSeats, [additionState?.status.value, sub.canAddSeats])

    const currFormatter = useMemo(() => new Intl.NumberFormat("en-US", { style: "currency", currency: sub.currency }), [sub.currency]);
    const productData = useMemo(() => getProductData(sub.productCode), [sub.productCode]);
    const perUnitPriceStr = useMemo(() => {
        const estFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: estimateState?.data?.currency ?? "USD" });
        const priceFted = estFormatter.format(estimateState?.data?.price ?? 0);
        return `+ ${priceFted} / ${sub.isFloating ? "seat" : "key"}`;
    }, [estimateState?.data]);

    useEffect(() => {
        if (additionState?.status.value === "success") {
            window.open(additionState.data?.quoteUrl);
        }
    }, [additionState?.status.value]);

    return (
        <div id="modifySeatsDetails">
            <p>How many {sub.isFloating ? "seats" : "keys"} would you like to purchase?</p>
            <h3>Summary</h3>

            <div className="d-flex flex-direction-row align-items-center border-top p-top-large" style={{ gap: 16 }}>
                <img className="icon" src={productData.logoSrc} />
                <div style={{ flexGrow: 1 }}>
                    {productData.header}
                    {estimateState?.status.value === "success" &&
                        <>
                            <small className="muted">{perUnitPriceStr}</small>
                            <img src={sub.isFloating ? iconComputer : iconKey} style={{ height: "1rem", verticalAlign: "middle", marginLeft: "3px" }} />
                        </>}
                </div>
                <div className="d-flex flex-direction-row">
                    <Button color="tertiary" disabled={canInteract}
                        onClick={() => {
                            setSeatCount(clamp((isNaN(seatCount) ? 0 : seatCount) - 1, MIN_SEATS, MAX_SEATS));
                            dispatch(resetEstimate(subId ?? ""))
                        }}>
                        <img src={iconMinus} />
                    </Button>

                    <Input style={{ margin: "0px 16px", textAlign: "center" }}
                        type={"number"} min={MIN_SEATS} max={MAX_SEATS} value={seatCount}
                        onChange={(ev) => setSeatCount(clamp(parseInt(ev.currentTarget.value), MIN_SEATS, MAX_SEATS))} />

                    <Button color="tertiary" disabled={canInteract}
                        onClick={() => {
                            setSeatCount(clamp((isNaN(seatCount) ? 0 : seatCount) + 1, MIN_SEATS, MAX_SEATS));
                            dispatch(resetEstimate(subId ?? ""));
                        }}>
                        <img src={iconPlus} />
                    </Button>
                </div>
            </div>

            <div id="summaryContent" className="border-top p-top-large">
                <label className="muted">Current commitment:</label>
                <label>{currFormatter.format(sub.subtotal)} / {productData.periodicity}</label>

                <label className="muted">{sub.isFloating ? "Seats" : "Keys"} subtotal:</label>
                {seatCount > 0 && estimateState?.status.value === "success" && <label>+ {currFormatter.format(sub.price * seatCount)} / {productData.periodicity}</label>}
                {(seatCount === 0 || isNaN(seatCount) || estimateState?.status.value !== "success") && <label className="muted">-</label>}

                <label className="muted">Total:</label>
                {estimateState?.status.value === "success" && <label>{currFormatter.format(sub.subtotal + sub.price * (isNaN(seatCount) ? 0 : seatCount))} / {productData.periodicity}</label>}
                {estimateState?.status.value !== "success" && <label>-</label>}
            </div>

            <small className="muted border-top p-top-med">{sub.isFloating ? "Seats" : "Keys"} will be added only after paying the quote.</small>
            <div className="border-top p-top-med">
                {estimateState?.status.value !== "success" &&
                    <Button color="primary" style={{ width: "100%" }}
                        onClick={() => dispatch(demandKeysSeatsAdditionEstimate({ subId: sub.id, quantity: seatCount }))}
                        disabled={seatCount === 0 || isNaN(seatCount) || canInteract || estimateState?.status.value === "pending"}>
                        Estimate cost
                    </Button>}
                {additionState?.status.value !== "success" && estimateState?.status.value === "success" &&
                    <Button color="primary" style={{ width: "100%" }}
                        onClick={() => dispatch(initSeatsKeysAddition({ subId: sub.id, quantity: seatCount }))}
                        disabled={seatCount === 0 || isNaN(seatCount) || canInteract}>
                        Checkout
                    </Button>}
                {additionState?.status.value === "success" &&
                    <Button color="success" style={{ width: "100%" }}
                        onClick={() => window.open(additionState.data?.quoteUrl)}>
                        View Quote
                    </Button>}
            </div>
        </div>
    );
};

export default ModifySubscriptionSeatingDetails;