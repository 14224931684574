import React, { useRef } from "react";
import iconCheckmark from "../assets/media/icons/checked.svg";
import "./Checkbox.scss";

export interface CheckboxProps {
    error?: boolean;
}

const Checkbox = React.forwardRef<any, CheckboxProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>(({ error, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <div className={`checkbox-container ${props.checked ? "checked" : ""} ${error ? "error" : ""}`} onClick={_ => inputRef.current?.click()}>
            {props.checked && <img src={iconCheckmark} />}
            <input {...props} ref={inputRef} type="checkbox" />
        </div>
    )
});

export default Checkbox;