import "./SideBarAccordion.scss";

import iconArrowDown from "../assets/media/icons/arrow_head_down.svg";
import iconArrowRight from "../assets/media/icons/arrow_head_right.svg";
import { useCallback, useState } from "react";

interface SideBarAccordionProps {
    icon: JSX.Element | Element;
    title: JSX.Element | Element | string;

    isExpanded?: boolean;
    defaultIsExpanded?: boolean;

    children: any;
}

const SideBarAccordion = ({ icon, title, children, isExpanded: exp, defaultIsExpanded }: SideBarAccordionProps) => {
    const [isExpanded, setExpanded] = useState(defaultIsExpanded ? true : exp);

    const onToggleCallback = useCallback(() => {
        setExpanded(!isExpanded);
    }, [isExpanded]);

    return (
        <div className={`accordion ${isExpanded ? "expanded" : ""}`}>
            <div className="header" onClick={onToggleCallback}>
                <div className="icon">
                    {icon}
                </div>
                <div className="title">{title}</div>
                <div className="toggle-icon">
                    {isExpanded && <img src={iconArrowDown} alt="Collapse" />}
                    {!isExpanded && <img src={iconArrowRight} alt="Expand" />}
                </div>
            </div>
            <nav className={`content ${isExpanded ? "" : "hidden"}`}>
                {children}
            </nav>
        </div>
    );
};

export default SideBarAccordion;