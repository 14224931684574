/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMemo, useCallback, useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { EducationSubmissionType } from "../../features/admin/adminEducationSlice";
import { educationPostStatusSelector, postSubmission } from "../../features/user/educationSlice";
import { getErrorsCompiled } from "../../utils/uiHelpers";
import Button from "../Button";
import Input, { FileInput } from "../Input";

interface EducationalApplicationFormInputs {
    fullName: string;
    email: string;
    universityName: string;
    universityMajor: string;
    universityCity: string;
    universityCountry: string;
    reason: string;
    type: EducationSubmissionType;
    message?: string;

    idFile: FileList;
}

export default function () {
    const dispatch = useDispatch();
    const postStatus = useSelector(educationPostStatusSelector);
    const { resetField, register, trigger, getValues, control, handleSubmit, formState: { errors }, setValue } = useForm<EducationalApplicationFormInputs>({
        defaultValues: {
            email: "",
            fullName: "",
            message: "",
            reason: "",
            type: EducationSubmissionType.Student,
            universityCity: "",
            universityCountry: "",
            universityMajor: "",
            universityName: ""
        }
    });

    const areFieldsDisabled = useMemo(() => postStatus.value === "pending" || postStatus.value === "success", [postStatus.value]);

    const onValid = useCallback<SubmitHandler<EducationalApplicationFormInputs>>((data) => {
        dispatch(postSubmission({
            fullName: data.fullName,
            email: data.email,
            idFile: data.idFile,
            message: data.message,
            reason: data.reason,
            submissionType: data.type,
            universityName: data.universityName,
            universityCourse: data.universityMajor,
            universityCity: data.universityCity,
            universityCountry: data.universityCountry,
        }));
    }, []);

    if (postStatus.value === "success") {
        return <p>Your educational application has been submitted, you will receive an answer by email once responded.</p>
    }

    return (
        <form id="accountInformationForm" onSubmit={handleSubmit(onValid)}>
            <div className="input-group inline">
                <label>Full name <span className="danger">*</span> <br /><small>Your full family name</small></label>
                <Controller
                    name="fullName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Your full name..." disabled={areFieldsDisabled} />} />
            </div>
            <div className="input-group inline">
                <label>E-mail <span className="danger">*</span></label>
                <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Your e-mail..." disabled={areFieldsDisabled} />} />
            </div>
            <div className="input-group inline">
                <label>University Name <span className="danger">*</span></label>
                <Controller
                    name="universityName"
                    control={control}
                    rules={{ required: true, maxLength: 75 }}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Your university name..." disabled={areFieldsDisabled} />} />
            </div>
            <div className="input-group inline">
                <label>University Major <span className="danger">*</span></label>
                <Controller
                    name="universityMajor"
                    control={control}
                    rules={{ required: true, maxLength: 75 }}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Your university major..." disabled={areFieldsDisabled} />} />
            </div>
            <div className="input-group inline">
                <label>University City <span className="danger">*</span></label>
                <Controller
                    name="universityCity"
                    control={control}
                    rules={{ required: true, maxLength: 75 }}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Your university city..." disabled={areFieldsDisabled} />} />
            </div>
            <div className="input-group inline">
                <label>University Country <span className="danger">*</span></label>
                <Controller
                    name="universityCountry"
                    control={control}
                    rules={{ required: true, maxLength: 75 }}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Your university country..." disabled={areFieldsDisabled} />} />
            </div>
            <hr />
            <div className="input-group inline">
                <div>
                    <label>University Identification <span className="danger">*</span></label><br />
                    <small className="muted">Make sure text is crisp and clear.</small>
                </div>
                <div className="input">
                    <FileInput
                        {...register("idFile", {
                            validate: (files) => {
                                const file = files?.item(0);
                                if (file === undefined || file === null) return true;

                                if (file.type !== "image/png" && file.type !== "image/jpeg") {
                                    return "File must be a .jpg, .jpeg or .png";
                                }

                                return file.size <= 5 * 1000 * 1000 ? true : "File is too large! (Must be less than 5MB)";
                            },
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onChange: async () => {
                                await trigger("idFile")
                            }
                        })}
                        files={getValues().idFile}
                        onFilesDropped={files => {
                            if (files) {
                                setValue("idFile", files, {
                                    shouldValidate: true
                                });
                            }
                        }}
                        disabled={areFieldsDisabled}
                        error={errors.idFile?.type === "validate"} />
                    {errors.idFile?.message &&
                        <p className="danger">{errors.idFile.message}</p>}
                </div>
            </div>
            <hr />
            <div className="input-group inline">
                <label>Reason for application <span className="danger">*</span></label>
                <Controller
                    name="reason"
                    control={control}
                    rules={{ required: true, maxLength: 1000 }}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Reason for application" disabled={areFieldsDisabled} />} />
            </div>
            <div className="input-group inline">
                <label>Extra message</label>
                <Controller
                    name="message"
                    control={control}
                    rules={{ maxLength: 1000 }}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Anything else to mention?" disabled={areFieldsDisabled} />} />
            </div>
            <div className="input-group inline">
                <label>Your status <span className="danger">*</span></label>
                <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <select {...field} disabled={areFieldsDisabled} defaultValue={"Student"}>
                        <option value={"Student"}>Student</option>
                        <option value={"FacultyMembers"}>Faculty Member</option>
                        <option value={"University"}>University (applying for multiple licenses)</option>
                    </select>} />
            </div>
            <Button type="submit" color={"primary"} disabled={areFieldsDisabled}>
                {postStatus.value === "pending"
                    && "Submitting application..."}
                {(postStatus.value === "idle" || postStatus.value === "failure")
                    && "Submit application"}
            </Button>
            {postStatus.value === "failure" && getErrorsCompiled(postStatus.error).map(e => <p className="danger">{e}</p>)}
        </form>
    )
}