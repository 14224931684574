import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API, Auth } from "aws-amplify";
import { ApplicationState, ValidationProblemDetails } from "..";
import FetchStatus from "../../data/FetchStatus";
import { pushAlert } from "../ui/globalUiSlice";
import { getErrorsCompiled } from "../../utils/uiHelpers";
import { adminFetchSubscription } from "../admin/adminUsersSlice";

interface NormalizedEntitiesResponse<TEntity> {
    keys: string[],
    entities: { [key: string]: TEntity },
}

export interface SubscriptionsState {
    status: FetchStatus,
    keys: string[],
    entities: { [key: string]: Subscription },

    seatsAdditionEstimate: {
        [subscriptionId: string]: {
            status: FetchStatus,
            data?: KeysAdditionEstimateResponse
        }
    }

    seatsAddition: {
        [subscriptionId: string]: {
            status: FetchStatus,
            data?: SeatsKeysAdditionInitiationResponse,
        }
    };

    maintenancePurchase: {
        [subscriptionId: string]: {
            status: FetchStatus;
            data?: MaintenancePurchaseInitiationResponse;
        }
    },

    suiteUpgrade: {
        [subscriptionId: string]: {
            status: FetchStatus;
            data?: SuiteUpgradeInitResponse;
        }
    }

    monthlyToAnnualUpgrade: {
        [subscriptionId: string]: {
            status: FetchStatus;
            data?: MonthlyToAnnualUpgradeInitResponse;
        }
    }

    cancelPendingChange: { [subscriptionId: string]: FetchStatus }

    cancelStatus: { [subscriptionId: string]: FetchStatus },
    resumeStatus: { [subscriptionId: string]: FetchStatus },
    switchMonthlyToAnnualStatus: { [subscriptionId: string]: FetchStatus },
}

export enum SubscriptionPermissions {
    None = 0,

    ReadBasic = 0b0000_0001,
    ReadAllKeys = 0b0000_0010,

    WritePayment = 0b0000_0100,
    WriteAllKeys = 0b0000_1000,

    All = 0b1111_1111,
}

export enum SubscriptionChangeType {
    None,
    SeatsAddition,
    MaintenancePurchase,
    SuiteUpgrade,
    MonthlyToAnnualUpgrade,
}

export interface KeysAdditionEstimateResponse {
    total: number;
    rebillPushbackAt: string; // ISO DateTime
    quoteExpiresAt: string; // ISO DateTime
    quoteExpirationTimeSpan: string;
    price: number;
    currency: string;
}

export interface SeatsKeysAdditionInitiationResponse {
    total: number;
    currency: string;
    quoteUrl: string;
    quoteExpiresAt: string; // datetime
    subscriptionExtendedBy: string; // timespan
}

export interface SuiteUpgradeInitResponse {
    quoteUrl: string;
}

export interface MonthlyToAnnualUpgradeInitResponse {
    quoteUrl: string;
}

export interface SubscriptionReceiverInfo {
    userId: string;
    permissions: SubscriptionPermissions;
    name: string;
}

export enum SubscriptionState {
    Active,
    Overdue,
    Canceled,
    Deactivated,
    Trial
}

export interface Subscription {
    id: string;
    ownerId: string;
    isActive: boolean;
    state: SubscriptionState;
    nextChargeInfo: {
        chargeDate: string; // date-time;
        total: number;
        currency: string;
    };
    pendingChange?: {
        changeType: SubscriptionChangeType;

        // common fields
        quoteUrl?: string;

        // SeatsAddition fields
        seatsAddition?: string;
    };
    canAddSeats: boolean;
    canUpgradeMonthlyToAnnual: boolean;
    upgradeMonthlyToAnnualPrice?: number;
    canUpgradeToSuite: boolean;
    upgradeToSuitePrice?: number;
    consecutivePayments: number;
    consecutivePaymentsNeededForPermanent: number;
    canSwitchMonthlyToAnnual: boolean;
    beginAt: string;
    endAt?: string;
    deactivatedAt?: string;
    canceledAt: string;
    permissions: SubscriptionPermissions;
    isAutoRenewEnabled: boolean;
    price: number;
    subtotal: number;
    currency: string;
    productCode: string;
    isFloating: boolean;
    quantity: number;
    licensesIds: string[];
    sharedTo: SubscriptionReceiverInfo[];
}

const initialState: SubscriptionsState = {
    status: {
        value: "idle"
    },
    keys: [],
    entities: {},

    seatsAdditionEstimate: {},
    cancelStatus: {},
    resumeStatus: {},
    seatsAddition: {},
    suiteUpgrade: {},
    monthlyToAnnualUpgrade: {},
    switchMonthlyToAnnualStatus: {},
    maintenancePurchase: {},
    cancelPendingChange: {},
};

export const fetchSubscription = createAsyncThunk<Subscription, { subId: string }>("user/subscriptions/fetchSubscription",
    async ({ subId }, api) => {
        try {
            const sub = await API.get("LicenseService", `/api/v1/subscriptions/${subId}`, {}) as Subscription;
            return sub;
        } catch (error) {
            return api.rejectWithValue(undefined);
        }
    });

export const fetchAllUserSubscriptions = createAsyncThunk<NormalizedEntitiesResponse<Subscription>, void, { state: ApplicationState, rejectValue: ValidationProblemDetails }>("user/subscriptions/fetchAllUserSubscriptions",
    async (_, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/me`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                const entities = (await resp.json()) as NormalizedEntitiesResponse<Subscription>;
                return entities;
            } else {
                if (resp.status === 404) {
                    const err: ValidationProblemDetails = {
                        errors: {
                            "NoSubsFound": "You have no subscriptions."
                        }
                    };

                    return thunkApi.rejectWithValue(err);
                } else {
                    return thunkApi.rejectWithValue({
                        errors: {
                            "UnknownError": "An internal error occured, please contact our support."
                        }
                    });
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue({
                errors: {
                    "UnknownError": "An internal error occured, please contact our support."
                }
            });
        }
    });

export const initSeatsKeysAddition = createAsyncThunk<SeatsKeysAdditionInitiationResponse, { subId: string, quantity: number }, { rejectValue: ValidationProblemDetails }>("user/subscriptions/initSeatsKeysAddition",
    async ({ quantity, subId }, thunkApi) => {
        if (quantity < 1) {
            return thunkApi.rejectWithValue({
                errors: {
                    "InvalidQuantity": "You must request at least one new seat/key."
                }
            })
        }

        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const queryParams = new URLSearchParams();
            queryParams.set("quantity", quantity.toString());

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/${subId}/initiateSeatsKeysAddition?${queryParams.toString()}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                const result = (await resp.json()) as SeatsKeysAdditionInitiationResponse;
                return result;
            } else {
                if (resp.status === 400) {
                    const valProblems = await resp.json() as ValidationProblemDetails;
                    return thunkApi.rejectWithValue(valProblems);
                } else {
                    return thunkApi.rejectWithValue({
                        errors: {
                            "Unknown": "An unknown error occured, please contact support."
                        }
                    });
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue({
                errors: {
                    "Unknown": "An unknown error occured, please contact support."
                }
            });
        }
    });

export const switchMonthlyToAnnual = createAsyncThunk<Subscription, { subId: string }, { rejectValue: ValidationProblemDetails | undefined }>("user/subscriptions/switchMonthlyToAnnual",
    async ({ subId }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/${subId}/switchToAnnualMaintenance`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                const sub = (await resp.json()) as Subscription;
                await thunkApi.dispatch(pushAlert({
                    title: "Payment plan changed",
                    body: "Your subscription has been switched to an annual maintenance payment plan.",
                    type: "positive",
                    cooldown: 5000,
                }));

                return sub;
            } else {
                if (resp.status === 400) {
                    const valProblems = await resp.json() as ValidationProblemDetails;

                    await thunkApi.dispatch(pushAlert({
                        title: "Could not switch to maintenance plan, read more...",
                        type: "negative",
                        cooldown: 8500,
                        body: getErrorsCompiled(valProblems).join(" ")
                    }));

                    return thunkApi.rejectWithValue(valProblems);
                } else {
                    return thunkApi.rejectWithValue(undefined);
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const initSuiteUpgrade = createAsyncThunk<SuiteUpgradeInitResponse, { subId: string, couponCode?: string }, { rejectValue: ValidationProblemDetails | undefined }>("user/subscriptions/initSuiteUpgrade",
    async ({ subId, couponCode }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const queryParams = new URLSearchParams();
            if (couponCode) queryParams.append("couponCode", couponCode);

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/${subId}/initiateSuiteUpgrade?${queryParams.toString()}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                void thunkApi.dispatch(adminFetchSubscription({ subscriptionId: subId }));

                const user = (await resp.json()) as SuiteUpgradeInitResponse;
                return user;
            } else {
                if (resp.status === 400) {
                    const valProblems = await resp.json() as ValidationProblemDetails;

                    await thunkApi.dispatch(pushAlert({
                        title: "Could not upgrade to suite, read more...",
                        type: "negative",
                        cooldown: 5000,
                        body: getErrorsCompiled(valProblems).join(" ")
                    }));

                    return thunkApi.rejectWithValue(valProblems);
                } else {
                    return thunkApi.rejectWithValue(undefined);
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const initMonthlyToAnnualUpgrade = createAsyncThunk<MonthlyToAnnualUpgradeInitResponse, { subId: string, couponCode?: string }, { rejectValue: ValidationProblemDetails | undefined }>("user/subscriptions/initMonthlyToSuiteUpgrade",
    async ({ subId, couponCode }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const queryParams = new URLSearchParams();
            if (couponCode) queryParams.append("couponCode", couponCode);

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/${subId}/initiateMonthlyToAnnualUpgrade?${queryParams.toString()}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                const user = (await resp.json()) as MonthlyToAnnualUpgradeInitResponse;
                return user;
            } else {
                if (resp.status === 400) {
                    const valProblems = await resp.json() as ValidationProblemDetails;
                    await thunkApi.dispatch(pushAlert({
                        cooldown: 6000,
                        title: "Could not upgrade to annual, read more...",
                        type: "negative",
                        body: getErrorsCompiled(valProblems).join(" ")
                    }));

                    return thunkApi.rejectWithValue(valProblems);
                } else {
                    await thunkApi.dispatch(pushAlert({
                        cooldown: 6000,
                        title: "Could not upgrade to annual, read more...",
                        type: "negative",
                        body: "An unknown error has occured, please check your network or contact support."
                    }));

                    return thunkApi.rejectWithValue(undefined);
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const cancelPendingChange = createAsyncThunk<void, { subId: string }>("user/subscriptions/cancelPendingChange",
    async ({ subId }, thunkApi) => {
        try {
            await API.del("LicenseService", `/api/v1/subscriptions/${subId}/cancelPendingChange`, {});
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const cancelSubscription = createAsyncThunk<void, { subId: string }>("user/subscriptions/cancelSubscription",
    async ({ subId }, thunkApi) => {
        try {
            await API.post("LicenseService", `/api/v1/subscriptions/${subId}/cancel`, {});
            await thunkApi.dispatch(pushAlert({
                title: "Subscription cancelled!",
                body: "Your subscription will update in a couple of minutes.",
                cooldown: 5000,
                type: "warning",
            }));
            await thunkApi.dispatch(fetchSubscription({ subId: subId }));
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const demandKeysSeatsAdditionEstimate = createAsyncThunk<KeysAdditionEstimateResponse, { subId: string, quantity: number }>("user/subscriptions/demandSeatsAdditionEstimate",
    async ({ quantity, subId }, thunkApi) => {
        try {
            const resp = await API.get("LicenseService", `/api/v1/subscriptions/${subId}/estimateSeatsKeysAdditionCost`, {
                queryStringParameters: {
                    quantity: quantity
                }
            }) as KeysAdditionEstimateResponse;
            return resp;
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

export const resumeSubscription = createAsyncThunk<void, { subId: string }>("user/subscriptions/resumeSubscription",
    async ({ subId }, thunkApi) => {
        try {
            await API.post("LicenseService", `/api/v1/subscriptions/${subId}/resume`, {});
            await thunkApi.dispatch(pushAlert({
                title: "Subscription resumed!",
                body: "Your subscription will update in a couple of minutes.",
                cooldown: 5000,
                type: "positive",
            }));
            await thunkApi.dispatch(fetchSubscription({ subId: subId }));
        } catch (error) {
            return thunkApi.rejectWithValue(undefined);
        }
    });

interface MaintenancePurchaseInitiationResponse {
    quoteUrl: string;
    quoteExpiresAt: string;
    total: number;
    currency: string;
}

export const buyMaintenance = createAsyncThunk<MaintenancePurchaseInitiationResponse, { subId: string }, { rejectValue: ValidationProblemDetails }>("user/subscriptions/buyMaintenance",
    async ({ subId }, thunkApi) => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();

            const resp = await fetch(`${process.env.REACT_APP_LICENSE_SERVICE_WEB_API_URL ?? ""}/api/v1/subscriptions/${subId}/initiateMaintenancePurchase`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Accept": "application/json, text/plain",
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                await thunkApi.dispatch(fetchSubscription({ subId: subId }));

                const result = (await resp.json()) as MaintenancePurchaseInitiationResponse;
                return result;
            } else {
                if (resp.status === 400) {
                    const valProblems = await resp.json() as ValidationProblemDetails;
                    return thunkApi.rejectWithValue(valProblems);
                } else {
                    return thunkApi.rejectWithValue({
                        errors: {
                            "Unknown": "An unknown error occured, please contact support."
                        }
                    });
                }
            }
        } catch (error) {
            return thunkApi.rejectWithValue({
                errors: {
                    "Unknown": "An unknown error occured, please contact support."
                }
            });
        }
    });

/**
 * Selectors
 */
export const subsSelector = (state: ApplicationState) => state.subscriptions;
export const subSelector = (id?: string) => (state: ApplicationState) => id ? state.subscriptions?.entities?.[id] : undefined;
export const seatsKeysAdditionStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.seatsAddition?.[subId] : undefined;
export const suiteUpgradeStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.suiteUpgrade?.[subId] : undefined;
export const monthlyToAnnualUpgradeStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.monthlyToAnnualUpgrade?.[subId] : undefined;
export const maintenancePurchaseStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.maintenancePurchase?.[subId] : undefined;
export const cancelStatusStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.cancelStatus?.[subId] : undefined;
export const cancelPendingChangeStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.cancelPendingChange?.[subId] : undefined;
export const switchMonthlyToAnnualStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.switchMonthlyToAnnualStatus?.[subId] : undefined;
export const resumeStatusStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.resumeStatus?.[subId] : undefined;
export const seatsAdditionEstimateStateSelector = (subId?: string) => (state: ApplicationState) => subId ? state.subscriptions.seatsAdditionEstimate?.[subId] : undefined;

const subscriptionsSlice = createSlice({
    name: "user/subscriptions",
    initialState: initialState,
    reducers: {
        resetEstimate(state, action: PayloadAction<string>) {
            state.seatsAdditionEstimate[action.payload] = { status: { value: "idle" } };
        }
    },
    extraReducers: builder => builder
        .addCase(fetchSubscription.fulfilled, (state, { payload: sub }) => {
            if (!state.keys.includes(sub.id)) {
                state.keys.push(sub.id);
            }

            state.status.value = "success";
            state.entities[sub.id] = sub;
        })

        .addCase(fetchAllUserSubscriptions.pending, (state) => { state.status.value = "pending" })
        .addCase(fetchAllUserSubscriptions.rejected, (state, action) => {
            state.status = { value: "failure", error: action.payload };
        })
        .addCase(fetchAllUserSubscriptions.fulfilled, (state, action) => {
            state.keys = action.payload.keys;
            for (const subId in action.payload.entities) {
                state.entities[subId] = action.payload.entities[subId];
            }
            state.status.value = "success";
        })

        .addCase(initSeatsKeysAddition.pending, (state, { meta: { arg: { subId } } }) => { state.seatsAddition[subId] = { status: { value: "pending" } } })
        .addCase(initSeatsKeysAddition.rejected, (state, { payload, meta: { arg: { subId } } }) => {
            state.maintenancePurchase[subId].status = {
                value: "failure",
                error: payload,
            };
        })
        .addCase(initSeatsKeysAddition.fulfilled, (state, { payload, meta: { arg: { subId } } }) => {
            state.seatsAddition[subId] = {
                data: payload,
                status: { value: "success" },
            };
        })

        .addCase(initSuiteUpgrade.pending, (state, { meta: { arg: { subId } } }) => { state.suiteUpgrade[subId] = { status: { value: "pending" } } })
        .addCase(initSuiteUpgrade.rejected, (state, { payload, meta: { arg: { subId } } }) => {
            state.maintenancePurchase[subId].status = {
                value: "failure",
                error: payload,
            };
        })
        .addCase(initSuiteUpgrade.fulfilled, (state, { payload, meta: { arg: { subId } } }) => {
            state.suiteUpgrade[subId] = {
                data: payload,
                status: { value: "success" },
            };

            state.entities[subId].pendingChange = {
                changeType: SubscriptionChangeType.SuiteUpgrade,
                quoteUrl: payload.quoteUrl,
            };
        })

        .addCase(initMonthlyToAnnualUpgrade.pending, (state, { meta: { arg: { subId } } }) => { state.monthlyToAnnualUpgrade[subId] = { status: { value: "pending" } } })
        .addCase(initMonthlyToAnnualUpgrade.rejected, (state, { meta: { arg: { subId } }, payload }) => {
            state.monthlyToAnnualUpgrade[subId].status = {
                value: "failure",
                error: payload
            };
        })
        .addCase(initMonthlyToAnnualUpgrade.fulfilled, (state, { payload, meta: { arg: { subId } } }) => {
            state.monthlyToAnnualUpgrade[subId] = {
                data: payload,
                status: { value: "success" },
            };

            state.entities[subId].pendingChange = {
                changeType: SubscriptionChangeType.MonthlyToAnnualUpgrade,
                quoteUrl: payload.quoteUrl,
            };
        })

        .addCase(buyMaintenance.pending, (state, { meta: { arg: { subId } } }) => { state.maintenancePurchase[subId] = { status: { value: "pending" } } })
        .addCase(buyMaintenance.rejected, (state, { payload, meta: { arg: { subId } } }) => {
            state.maintenancePurchase[subId].status = {
                value: "failure",
                error: payload,
            };
        })
        .addCase(buyMaintenance.fulfilled, (state, { payload, meta: { arg: { subId } } }) => {
            state.maintenancePurchase[subId] = {
                data: payload,
                status: { value: "success" },
            };
        })

        .addCase(cancelPendingChange.pending, (state, { meta: { arg: { subId } } }) => { state.cancelPendingChange[subId] = { value: "pending" } })
        .addCase(cancelPendingChange.rejected, (state, { meta: { arg: { subId } } }) => { state.cancelPendingChange[subId] = { value: "failure" } })
        .addCase(cancelPendingChange.fulfilled, (state, { meta: { arg: { subId } } }) => {
            state.cancelPendingChange[subId] = { value: "success" };
            if (state.entities[subId]) { state.entities[subId].pendingChange = undefined; }
        })

        .addCase(cancelSubscription.pending, (state, { meta: { arg: { subId } } }) => { state.cancelStatus[subId] = { value: "pending" } })
        .addCase(cancelSubscription.rejected, (state, { meta: { arg: { subId } } }) => { state.cancelStatus[subId].value = "failure" })
        .addCase(cancelSubscription.fulfilled, (state, { meta: { arg: { subId } } }) => { state.cancelStatus[subId].value = "success" })

        .addCase(resumeSubscription.pending, (state, { meta: { arg: { subId } } }) => { state.resumeStatus[subId] = { value: "pending" } })
        .addCase(resumeSubscription.rejected, (state, { meta: { arg: { subId } } }) => { state.resumeStatus[subId].value = "failure" })
        .addCase(resumeSubscription.fulfilled, (state, { meta: { arg: { subId } } }) => { state.resumeStatus[subId].value = "success" })

        .addCase(switchMonthlyToAnnual.pending, (state, { meta: { arg: { subId } } }) => { state.switchMonthlyToAnnualStatus[subId] = { value: "pending" } })
        .addCase(switchMonthlyToAnnual.rejected, (state, { meta: { arg: { subId } } }) => { state.switchMonthlyToAnnualStatus[subId].value = "failure" })
        .addCase(switchMonthlyToAnnual.fulfilled, (state, { meta: { arg: { subId } }, payload: sub }) => {
            state.entities[subId] = sub;
            state.switchMonthlyToAnnualStatus[subId].value = "success";
        })

        .addCase(demandKeysSeatsAdditionEstimate.pending, (state, { meta: { arg: { subId } } }) => { state.seatsAdditionEstimate[subId] = { status: { value: "pending" } } })
        .addCase(demandKeysSeatsAdditionEstimate.rejected, (state, { meta: { arg: { subId } } }) => { state.seatsAdditionEstimate[subId] = { status: { value: "failure" } } })
        .addCase(demandKeysSeatsAdditionEstimate.fulfilled, (state, { payload, meta: { arg: { subId } } }) => {
            state.seatsAdditionEstimate[subId] = {
                status: { value: "success" },
                data: payload
            };
        })
});

export const { resetEstimate } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;